import React, { Component } from 'react';
import { History } from 'history';
import { match as IMatch } from 'react-router-dom';
import { observer } from 'mobx-react';

import { withStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import TrainService from '../../bi/services/trains';
import { ITrainStore } from '../../bi/services/trains/store/train';
import AppService from '../../bi/services/app';

import { CarItem } from './components/CarItem';

import MainAnalytic from '../../bi/utils/analytics/main';

import { WorkspaceService } from '../../bi/types/workspace';
import { UserSessionService } from '../../bi/types/userSession';

import styles from './index.module.css';

interface ITrainPageProps {
  trainsService: TrainService,
  userSessionService: UserSessionService,
  workspaceService: WorkspaceService,
  match: IMatch<{ trainId: string }>,
  history: History,
  appService: AppService,
  stores: {
    trainStore: ITrainStore,
  },
}
@withStores([MOBX_STORES.TRAIN])
@observer
class TrainPage extends Component<ITrainPageProps> {
  componentDidMount() {
    this.props.trainsService.updateCarsDescription();
  }

  handleSelectCar = (label: string) => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_TRAIN_PLACE_BUTTON_CHOSEN);
    MainAnalytic.send(MainAnalytic.CATEGORY.TRAINS, MainAnalytic.ACTIONS.TRAINS.SELECTTRAINCAR, {
      label,
    });
  };

  render() {
    const {
      trainsService,
      userSessionService,
      workspaceService,
      match,
      history,
      appService,
      stores: { trainStore: { train } },
    } = this.props;

    if (!train) {
      return null;
    }

    return (
      <div className={ styles.cars }>
        { train.Cars.map((car, index) => (
          // @ts-ignore
          <CarItem
            key={ car.Number }
            index={ index }
            item={ car }
            trainId={ match.params.trainId }
            trainService={ trainsService }
            userSessionService={ userSessionService }
            workspaceService={ workspaceService }
            appService={ appService }
            history={ history }
            onSelectCar={ this.handleSelectCar }
          />
        )) }
      </div>
    );
  }
}

export { TrainPage };
