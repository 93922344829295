import React, { createRef } from 'react';
import { Prompt } from 'react-router-dom';
import * as queryString from 'query-string';
import { observer } from 'mobx-react';

import {
  Button,
  Dialog,
  IconButton,
  Input,
  LinkButton,
  Select,
  StyledWrapper,
  Text,
  Tooltip,
  PageLoader,
  BackLink,
} from 'new-ui';

import { Moment } from 'moment';
import { SuggestItem } from 'new-ui/src/components/Suggest/types';
import { getText, getTextArray } from '../../../i18n';

import { MOBX_STORES } from '../../bi/context/stores';
import { withStores } from '../../bi/context';

import { TripTags } from '../../components/TripTags';
import ProjectsInEmployeePage from '../../components/ProjectsInEmployeePage';
import { Step, Stepper } from './components/Step';
import { FieldLabel } from '../../components/FieldLabel';
import { GeneralStep } from './components/GeneralStep';
import { AccessStep } from './components/AccessStep';
import { SimpleSignatureStep } from './components/Signature';
import { DocumentForm } from './components/Documents';
import { Bonuses } from './components/Bonuses';
import { TgMailDialog } from './components/TgMailDialog';
import { ClearButton } from '../../components/ClearButton';
import { ActiveBookingDialog } from './components/dialogs/ActiveBooking';
import { CommonBlock } from './components/rights/CommonBlock';
import { SignatureDialog } from './components/Signature/components/SignatureDialog';
import { CodeDialog } from './components/Signature/components/CodeDialog';
import { ConfirmationDialog } from './components/Signature/components/ConfirmationDialog';
import { TgOptions } from './components/TgOptions';

import TYPES from '../../bi/constants/tripTags';
import COUNTRIES from '../../bi/constants/countries';
import PASSPORTS from '../../bi/constants/passport';
import TEXTS from '../../bi/constants/texts';
import DOCUMENTSFIELDS from '../../bi/constants/documentsFields';
import STATUS, { EMPLOYEEFIELD } from '../../bi/constants/employee';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import { EDITEMPLOYEERIGHT } from '../../bi/constants/rights';
import ROUTES from '../../bi/constants/routes';

import toDecline from '../../bi/utils/toDecline';
import clipboard from '../../bi/utils/clipboard';
import { checkUaWithDomesticPassport } from '../../bi/utils/documents';
import { momentObject } from '../../bi/utils/formatDate';
import { sanitizeText } from '../../bi/utils/text';
import { isSmartAgent } from '../../bi/utils/env';
import {
  checkTwoForeignPassports,
  employeeMayHavePassport,
  getEmployeeFullName,
} from '../../bi/utils/employees';
import { MainAnalytic } from '../../bi/utils/analytics';

import TravelPolicyService from '../../bi/services/travelPolicy';
import NotificationService from '../../bi/services/notification';
import WorkspaceService from '../../bi/services/workspace';
import ChatService from '../../bi/services/chat';
import IAccountSettingsService from '../../bi/services/accountSettings';
import Departments from '../../bi/services/departments';
import FeatureFlagsService from '../../bi/services/featureFlags';
import { ProductAnalytics } from '../../bi/protocols/productAnalytics';
import App from '../../bi/services/app';
import { IUiSettingsStore } from '../../bi/services/uiSettings/types';
import { IDepartmentsStore } from '../../bi/services/departments/store';
import { ISimpleSignatureStore } from '../../bi/services/employee/store/simpleSignature';
import { ITravelPolicyStore } from '../../bi/services/travelPolicy/travelPolicyStore';
import Notepad from '../../bi/services/notepad';
import { IMatch } from '../../bi/types/airlineAdditionalServices';
import RightsStore from '../../bi/services/employee/store/rights';
import { UserSessionService } from '../../bi/types/userSession';
import ApprovalSchemesService from '../../bi/services/approvalSchemes';
import ApprovalERSchemesService from '../../bi/services/approvalERSchemes';
import { UiSettingsProtocol } from '../../bi/protocols/uiSettings';
import { PersonalPaymentProtocol } from '../../bi/protocols/personalPayment';
import {
  ICompany,
  ICompanyEasy,
  IDocumentUser,
  IEmployees,
  IErrorListEmployee,
  IPassportView,
  MultiSelectList,
  TAirlines,
  TCitizenship,
  TErrors,
} from '../../bi/services/employee/types';
import { ITravelPolicyListItem } from '../../bi/types/travelPolicy';
import { CitizenshipSuggestions, EmployeesObj, IProjects } from '../../bi/types/employees';
import { ITripTagsService } from '../../bi/types/tripTags';

import styles from './index.module.css';

type TFieldNames = {
  [key: string]: string,
};

const FIELD_NAMES: TFieldNames = {
  Name: getText('settings:employees.employee.fieldNames.name'),
  Surname: getText('settings:employees.employee.fieldNames.surname'),
  Email: getText('settings:employees.employee.fieldNames.email'),
  MobilePhone: getText('settings:employees.employee.fieldNames.mobilePhone'),
  Birthday: getText('settings:employees.employee.fieldNames.birthday'),
  Login: getText('settings:employees.employee.fieldNames.login'),
  Sex: getText('settings:employees.employee.fieldNames.sex'),
  TpRights: getText('settings:employees.employee.fieldNames.travelPolicy'),
  TpRightsByAdmin: getText('settings:employees.employee.fieldNames.travelPolicyByAdmin'),
  EmployeeNumber: getText('settings:employees.employee.fieldNames.employeeNumber'),
  EmployeeNumberSixDig: getText('settings:employees.employee.fieldNames.employeeNumber'),
};

const ANCHOR = {
  ACCESS: 'access',
  SIGNATURE: 'signature',
  BONUSES: 'bonuses',
  DOCUMENTS: 'documents',
  GENERAL: 'general',
  TP: 'tp',
  ADDITIONALLY: 'additionally',
};

const LABELS = {
  NEW_EMPLOYEE: getText('settings:employees.employee.newEmployee'),
  NEW_TRAVELER: getText('settings:employees.employee.newTraveler'),
  NEW_USER: getText('settings:employees.employee.newUser'),
  BACK: getText('common:back'),
  EXIT_MESSAGE: getText('settings:employees.employee.exitMessage'),
  GENERAL: getText('settings:employees.employee.general'),
  DOCUMENTS: getText('settings:employees.employee.documents'),
  ACCESS: getText('settings:employees.employee.access'),
  ELECTRONIC_SIGNATURE: getText('settings:employees.employee.electronicSignature'),
  TP: getText('settings:employees.employee.tp'),
  BONUSES: getText('settings:employees.employee.bonuses'),
  BONUSES_ATTENTION: getText('settings:employees.employee.bonusesAttention'),
  BONUSES_AND_ANALYTICS: getText('settings:employees.employee.bonusesAndAnalytics'),
  ONLY_ANALYTICS: getText('settings:employees.employee.onlyAnalytics'),
  ADDITIONALLY: getText('settings:employees.employee.additionally'),
  CANCEL: getText('common:undo'),
  SAVE: getText('common:save'),
  TO_ARCHIVE: getText('settings:employees.employee.toArchive'),
  FROM_ARCHIVE: getText('settings:employees.employee.fromArchive'),
  NOT_AVAILABLE_EDIT: getText('settings:employees.employee.notAvailableEdit'),
  DATA_FOR_REPORTS: getText('settings:employees.employee.dataForReports'),
  EMPLOYEE_NUMBER: getText('settings:employees.employee.employeeNumber'),
  TRAVEL_POLICY: getText('settings:employees.employee.tp'),
  CITIZENSHIP: getText('settings:employees.employee.citizenship'),
  ADD_FOREIGN_PASSPORT: getText('settings:employees.employee.addForeignPassport'),
  ADD_DOMESTIC_PASSPORT: getText('settings:employees.employee.addDomesticPassport'),
  NOT_APPLY: getText('settings:employees.employee.notApply'),
  SETTINGS_OF_ACCESS_AND_NOTIFICATIONS: getText('settings:employees.employee.settingsOfAccessAndNotifications'),
  ARCHIVATION: getText('settings:employees.employee.archivation'),
  RESPONSIBLE_BY: getText('settings:employees.employee.responsibleBy'),
  MANAGER: getText('settings:employees.employee.manager'),
  REMINDER: getText('settings:employees.employee.reminder'),
  CUSTOM_MESSAGE_BUR_SERVICE: getText('settings:employees.employee.customMessageBurService'),
  CUSTOM_MESSAGE_BUR_MAIL: getText('settings:employees.employee.customMessageBurMail'),
  ERROR_DOMESTIC_MESSAGE: {
    ONE: getText('settings:employees.employee.errorMessageDomestic.one'),
    TWO: getText('settings:employees.employee.errorMessageDomestic.two'),
  },
  NOTIFICATIONS: {
    COPY: getText('settings:employees.employee.notifications.copy'),
    EMPLOYEE_SAVE: getText('settings:employees.employee.notifications.employeeSave'),
    USER_SAVE: getText('settings:employees.employee.notifications.userSave'),
    EMPLOYEE_NEW: getText('settings:employees.employee.notifications.employeeNew'),
    USER_NEW: getText('settings:employees.employee.notifications.userNew'),
    TRAVELER_SAVE: getText('settings:employees.employee.notifications.travelerSave'),
    TRAVELER_NEW: getText('settings:employees.employee.notifications.travelerNew'),
    EMAIL_SENT: (email: string) => getText('settings:employees.employee.notifications.emailSent', { email }),
    EMPLOYEE_ARCHIVE: getText('settings:employees.employee.notifications.employeeArchive'),
    TRAVELER_ARCHIVE: getText('settings:employees.employee.notifications.travelerArchive'),
    USER_ARCHIVE: getText('settings:employees.employee.notifications.userArchive'),
    EMPLOYEE_UNARCHIVED: getText('settings:employees.employee.notifications.employeeUnarchived'),
    TRAVELER_UNARCHIVED: getText('settings:employees.employee.notifications.travelerUnarchived'),
    USER_UNARCHIVED: getText('settings:employees.employee.notifications.userUnarchived'),
  },
  REGISTRATION_BLOCK: {
    ONE: getText('settings:employees.employee.registrationBlock.one'),
    TWO: getText('settings:employees.employee.registrationBlock.two'),
    THREE: getText('settings:employees.employee.registrationBlock.three'),
  },
  DEMO_TOOLTIP: {
    ONE: getText('settings:employees.employee.demoTooltip.one'),
    ONE_SMARTAGENT: getText('settings:employees.employee.demoTooltip.oneSmartagent'),
    TWO: getText('settings:employees.employee.demoTooltip.two'),
    THREE: getText('settings:employees.employee.demoTooltip.three'),
  },
  SIGNATURE: {
    DELETE_SIGNATURE: getText('settings:employees.employee.steps.signature.deleteSignature'),
    ARE_YOU_SURE: getText('settings:employees.employee.steps.signature.sureDeleteSignature'),
    SUBMIT: getText('settings:employees.employee.steps.signature.yes'),
    CANCEL: getText('settings:employees.employee.steps.signature.cancel'),
  },
  TELEGRAM: {
    LINK_TO: getText('settings:employees.employee.telegram.linkTo'),
    COPY_LINK: getText('settings:employees.employee.telegram.linkCopy'),
    LINK_COPIED: getText('settings:employees.employee.telegram.linkCopied'),
    SEND_TO_MAIL: getText('trip:documents.sendToEmail'),
  },
};

const ERROR_LABELS = {
  REQUIRED_FIELDS: getText('settings:employees.employee.errorLabels.required'),
  REQUIRED_FIELDS_INCORRECT: getText('settings:employees.employee.errorLabels.requiredIncorrect'),
  PATRONYMIC_INCORRECT: getText('settings:employees.employee.errorLabels.patronymicIncorrect'),
  DOCUMENTS_FIELDS_INCORRECT: getText('settings:employees.employee.errorLabels.documentsIncorrect'),
  EMPLOYEE_NUMBER_SIX_DIGITS: getText('services:employee.store.employee.employeeNumberSixDigits'),
};

const NAME_LIST = ['Surname', 'Name', 'Email'];

const SAVE_RIGHTS_ERROR_CODES = {
  0: {
    title: getText('settings:employees.employee.saveRightsError.0.title'),
    text: getText('settings:employees.employee.saveRightsError.0.text'),
  },
  DEFAULT: {
    title: getText('settings:employees.employee.saveRightsError.default.title'),
    text: TEXTS.SOMETHING_WENT_WRONG,
  },
};

const ARCHIVE_ERROR_CODES = {
  0: {
    title: getText('settings:employees.employee.archiveError.0.title'),
    text: getText('settings:employees.employee.archiveError.0.text'),
  },
  1: {
    title: getText('settings:employees.employee.archiveError.1.title'),
    text: getText('settings:employees.employee.archiveError.1.text'),
  },
  2: {
    title: getText('settings:employees.employee.archiveError.2.title'),
    text: getText('settings:employees.employee.archiveError.2.text'),
  },
  DEFAULT: {
    title: getText('settings:employees.employee.archiveError.default.title'),
    text: TEXTS.SOMETHING_WENT_WRONG,
  },
};

const ARCHIVE_LABELS = {
  PROJECT_DECLINES: getTextArray('settings:employees.employee.archiveLabels.projectDeclines'),
  DEPARTMENT_DECLINES: getTextArray('settings:employees.employee.archiveLabels.departmentDeclines'),
  SUBMIT: getText('settings:employees.employee.archiveLabels.submit'),
  TITLE: {
    FIRST_PART: getText('settings:employees.employee.archiveLabels.title.1'),
    SECOND_PART: getText('settings:employees.employee.archiveLabels.title.2'),
  },
  DECLINE: getText('common:undo'),
};

const STATUS_FOR_SMART_AGENT_USER = '2';
const TRAVEL_POLICY_BY_ADMIN = 'TpRightsByAdmin';

const mappedPolicies = (travelPolicies: ITravelPolicyListItem[]) =>
  travelPolicies.map(({ Name, Id }) => ({ label: Name, value: Id }));

const {
  employee: {
    notifications,
    submit,
    submitDisabled,
    toArchive,
    fromArchive,
    documents: {
      access,
      additionally,
    },
    general,
    navigation,
  },
} = QA_ATTRIBUTES;

export interface ILocationInfo {
  hash: string;
  key: string;
  pathname: string;
  search: string;
}

export interface IHistoryInfo {
  action: string;
  block: (prompt: string) => void;
  createHref: (location: ILocationInfo) => string;
  go: (number: number) => void;
  goBack: () => void;
  goForward: () => void;
  length: number;
  listen: (listener: string) => () => void;
  location: ILocationInfo;
  push: (path: string) => void;
  replace: (path: string) => void;
}

interface IEmplpoyeePageProps {
  rightsStore: RightsStore | any,
  employeeService: any,
  cartService: any,
  travelPolicyService: TravelPolicyService,
  notificationService: NotificationService,
  userSessionService: UserSessionService | any,
  workspaceService: WorkspaceService,
  chatService: ChatService,
  tripTagsService: ITripTagsService,
  travelPoliciesStore: ITravelPolicyStore,
  accountSettingsService: IAccountSettingsService,
  notepadService: Notepad,
  departmentsService: Departments,
  approvalSchemesService: ApprovalSchemesService | any,
  approvalERSchemesService: ApprovalERSchemesService | any,
  featureFlagsService: FeatureFlagsService,
  productAnalyticsProtocol: ProductAnalytics,
  uiSettingsProtocol: UiSettingsProtocol,
  personalPaymentProtocol: PersonalPaymentProtocol,
  appService: App,
  history: IHistoryInfo,
  location: ILocationInfo,
  match: IMatch,
  stores: {
    uiSettingsStore: IUiSettingsStore,
    departmentsStore: IDepartmentsStore,
    simpleSignatureStore: ISimpleSignatureStore,
  },
}

interface IEmplpoyeePageState {
  loading: boolean,
  companies: ICompany[],
  documentType: IPassportView,
  airlines: TAirlines[],
  citizenship: TCitizenship[] | any,
  disabledUser: boolean,
  citizenshipName: string,
  projects: IProjects[],
  showActiveBookingDialog: boolean,
  showRights: boolean,
  themeButton: string,
  saveEmployeeError: {
    show: boolean,
    title?: string,
    text?: string,
  },
  archiveSubmitDialog: boolean,
  showSignatureDialog: boolean,
  showSignatureCodeDialog: boolean,
  showSignatureConfirmationDialog: boolean,
  showSignatureDeleteDialog: boolean,
  selectedTripTags: number[],
  currentStep: string,
  showTgMail: boolean,
  showTooltip: boolean,
  chosenTP: string,
  errors: TErrors | any,
  selectCompaniesItems: MultiSelectList[] | any,
  citizenshipSuggestions: CitizenshipSuggestions,
  canEditRights: boolean,
  canEditEmployee: boolean,
  canEditSelf: boolean,
  canEditSelfRights: boolean,
  currentDate: Moment | null,
  employeePrevious: IEmployees[] | any,
  employee: IEmployees | any,
  isValid: boolean,
  requiredFieldsErrors: {
    empty: string[],
    emptyDocuments: any
  }
}

// @ts-ignore
@withStores([
  MOBX_STORES.UI_SETTINGS,
  MOBX_STORES.SETTINGS_STORE,
  MOBX_STORES.DEPARTMENTS,
  MOBX_STORES.SIMPLE_SIGNATURE_STORE,
])
@observer
class EmployeePage extends React.Component<IEmplpoyeePageProps, IEmplpoyeePageState> {
  getPersonalBonusCards = this.props.accountSettingsService.getPersonalBonusCards();
  showExpenseReportApprove = this.props.featureFlagsService.getShowExpenseReportApprove();
  showVSKWarnText = this.props.featureFlagsService.getShowVSKWarnText();
  showBurCustomMessage = this.props.featureFlagsService.getBurCustomMessage();
  forceTravelPolicy = this.props.accountSettingsService.getForceTravelPolicy();
  forceEmployeeNumber = this.props.accountSettingsService.getForceEmployeeNumber() || this.props.featureFlagsService.getEmployeeNumSixDigits();
  forceEmployeeNumSixDigits = this.props.featureFlagsService.getEmployeeNumSixDigits();

  cartItemId: any;
  noteItemId: any;
  isTraveler: boolean;
  isNewPage: any;
  unsubscribeFn: any;
  unsubscribe: any;

  constructor(props: any) {
    super(props);

    const { workspaceService, userSessionService, employeeService } = props;

    const { Companies, UserName } = workspaceService.get();

    const enumsServiceGet = userSessionService.get().enums;
    const projects = userSessionService.get().projects;
    const documentType = enumsServiceGet.documents;
    const airlines = enumsServiceGet.bonus.Air;
    const citizenship = enumsServiceGet.citizenship;

    const disabledUser = employeeService.hasDisabledPaxUser(UserName);

    this.state = {
      loading: true,
      companies: Companies,
      documentType,
      airlines,
      citizenship,
      disabledUser,
      citizenshipName: '',
      projects,
      showActiveBookingDialog: false,
      showRights: false,
      themeButton: 'secondary',
      saveEmployeeError: {
        show: false,
        title: '',
        text: '',
      },
      archiveSubmitDialog: false,
      showSignatureDialog: false,
      showSignatureCodeDialog: false,
      showSignatureConfirmationDialog: false,
      showSignatureDeleteDialog: false,
      selectedTripTags: [],
      currentStep: '',
      showTgMail: false,
      showTooltip: false,
      selectCompaniesItems: null,
      chosenTP: '',
      errors: {
        birthday: '',
        code: '',
        documents: [{
          dueDate: '',
          employeeNumSixDig: '',
          employeeNumber: '',
          firstName: '',
          lastName: '',
          middleName: '',
          name: '',
          number: '',
          patronymic: '',
          surname: '',
          travelPolicy: '',
        }],
        email: '',
        employeeNumSixDig: '',
        employeeNumber: '',
        login: '',
        mobilePhone: '',
        name: '',
        patronymic: '',
        sex: '',
        surname: '',
        travelPolicy: '',
      },
      citizenshipSuggestions: {
        suggestions: '',
        label: '',
        selected: '',
      },
      canEditRights: false,
      canEditEmployee: false,
      canEditSelf: false,
      canEditSelfRights: false,
      currentDate: null,
      employeePrevious: null,
      employee: null,
      isValid: false,
      requiredFieldsErrors: {
        empty: [],
        emptyDocuments: null,
      },
    };

    const parsedQuery = queryString.parse(props.location.search);
    this.cartItemId = parsedQuery.cartItem;
    this.noteItemId = parsedQuery.noteItemId;
    this.isTraveler = !!parsedQuery.travelers;
    this.isNewPage = parsedQuery.newPage;
  }

  accessRef = createRef();
  signatureRef = createRef();
  generalRef = createRef();
  additionallyRef = createRef();
  documentRef = createRef();

  async componentDidMount() {
    const {
      match: {
        params: {
          id,
        },
      },
      employeeService,
      travelPolicyService,
      approvalSchemesService,
      approvalERSchemesService,
      userSessionService,
      tripTagsService,
      departmentsService,
    } = this.props;

    this.unsubscribeFn = employeeService.subscribe(this.updateState);

    const { enums: { documents } } = userSessionService.get();

    approvalSchemesService.loadEmployeesWithRights()
      .then((employees: EmployeesObj[]) => {
        employeeService.setEmployees(employees);
        employeeService.getAccountLng(id, employees);
      });

    departmentsService.getDepartmentForSelectList();
    approvalSchemesService.loadList(null);
    travelPolicyService.loadList();
    tripTagsService.loadAccountTripTags();
    await employeeService.getSimpleSignature(id);

    await approvalERSchemesService.loadExpenseReportsSchemeList();

    if (window.innerWidth < 1400) {
      this.handleChangeTooltip();
    }

    window.addEventListener('resize', this.handleResize);

    await employeeService.loadEmployee(
      id,
      documents,
      this.forceTravelPolicy,
      this.forceEmployeeNumber,
      this.forceEmployeeNumSixDigits,
    );
    this.updateStateAfterEmployeeLoaded();
    employeeService.validateRequiredFields(this.forceTravelPolicy, this.forceEmployeeNumber, this.forceEmployeeNumSixDigits);
  }

  componentDidUpdate() {
    // @ts-ignore
    window.onbeforeunload = this.state.themeButton === 'primary'
      ? (e) => {
        e.preventDefault();

        return '';
      }
      : undefined;
  }

  componentWillUnmount() {
    const { employeeService } = this.props;

    window.removeEventListener('resize', this.handleResize);

    this.unsubscribeFn();
    employeeService.clear();
    employeeService.resetSignatureStore();

    if (this.unsubscribe) this.unsubscribe();

    window.onbeforeunload = null;
  }

  updateStateAfterEmployeeLoaded = () => {
    const { employeeService, workspaceService } = this.props;
    const { citizenship } = this.state;

    const {
      employee,
      errors,
      citizenshipSuggestions,
      chosenTP,
      currentDate,
      employeePrevious,
    } = employeeService.get();

    if (employee && !employee.Id) {
      employeeService.setViewRights(null);
    }

    const canEditRights = workspaceService.canEditRights;
    const canEditEmployee = workspaceService.canEditEmployee;
    const canEditSelf = workspaceService.canEditSelf;

    const citizenshipName = employee.Code.length && citizenship.length
      ? citizenship.find(({ Code }: { Code: string }) => Code === employee.Code).Name
      : '';

    const { rights: { EmployeeId } } = workspaceService;
    const canEditSelfRights = canEditRights && !!EmployeeId && EmployeeId === employee.Id;

    const selectedTripTags = employee.Tags ? employee.Tags.map(({ Id }: { Id: number }) => Id) : [];

    this.setState({
      errors,
      citizenshipSuggestions,
      canEditRights,
      citizenshipName,
      canEditEmployee,
      canEditSelf,
      canEditSelfRights,
      chosenTP,
      currentDate,
      selectedTripTags,
      employeePrevious,
    });
  };

  updateState = (state: any) => {
    const { userSessionService } = this.props;
    const { saveEmployeeError } = this.state;
    const enumsServiceGet = userSessionService.get().enums;
    const projects = userSessionService.get().projects;
    const documentType = enumsServiceGet.documents;
    const airlines = enumsServiceGet.bonus.Air;
    const citizenship = enumsServiceGet.citizenship;

    this.setState({
      loading: state.loading,
      employee: {
        ...state.employee,
        Birthday: state.employee.Birthday ? momentObject(state.employee.Birthday) : '',
        Companies: state.employee.Companies.length ? state.employee.Companies : [{ main: this.state.companies[0].CompanyId }],
      },
      isValid: state.isValid,
      errors: state.errors,
      requiredFieldsErrors: state.requiredFieldsErrors,
      chosenTP: state.chosenTP,
      citizenshipSuggestions: state.citizenshipSuggestions,
      documentType,
      citizenship,
      airlines,
      saveEmployeeError,
      projects,
    });
  };

  getCopyUserRights = (id: number | string) => this.props.employeeService.getCopyUserRights(id);

  handleOpenSignatureDialog = (value: boolean) => this.setState({ showSignatureDialog: value });

  handleCloseSignatureDialog = (value: boolean) => {
    this.handleOpenSignatureDialog(value);
    this.props.employeeService.resetSignatureStore();
  };

  handleOpenSignatureCodeDialog = (value: boolean) => this.setState({ showSignatureCodeDialog: value });

  handleSignatureConfirmationDialog = (value: boolean) => this.setState({ showSignatureConfirmationDialog: value });

  handleSignatureDeleteDialog = (value: boolean) => this.setState({ showSignatureDeleteDialog: value });

  handleChangeSignatureCode = (value: string, key: number) => this.props.employeeService.setSignatureCode(value, key);

  handleCloseDialog = async () => {
    const { employee: { Id } } = this.props.employeeService.get();

    await this.props.employeeService.getSimpleSignature(Id);
    this.handleSignatureConfirmationDialog(false);
  };

  handleRepeatGetCode = () => {
    const { match: { params: { id } }, employeeService } = this.props;

    employeeService.setRepeatGetCode(id);
  };

  handleSendCode = () => {
    const { employeeService: { sendSignatureCode, resetSignatureStore } } = this.props;

    sendSignatureCode()
      .then(() => {
        this.handleSignatureConfirmationDialog(true);
        resetSignatureStore();
      })
      .finally(() => this.handleOpenSignatureCodeDialog(false));
  };

  handleCloseSignatureCodeDialog = () => {
    const { employeeService } = this.props;

    this.handleOpenSignatureCodeDialog(false);
    employeeService.resetSignatureStore();
  };

  handleChangeNumberSignatureCode = () => {
    const { employeeService } = this.props;

    employeeService.resetSignatureCode();
    this.handleOpenSignatureCodeDialog(false);
    this.handleSignatureConfirmationDialog(false);
    this.handleOpenSignatureDialog(true);
  };

  createSimpleSignature = async (value: boolean) => {
    const {
      employeeService: { resetSignatureCode, createSimpleSignature, setActiveTimer },
      match: { params: { id } },
    } = this.props;

    resetSignatureCode();
    this.handleSignatureConfirmationDialog(false);
    this.handleOpenSignatureDialog(value);
    await createSimpleSignature(id);
    this.handleOpenSignatureCodeDialog(true);
    setActiveTimer(true);
  };

  handleGetImagesSignature = (value: boolean) => {
    const { match: { params: { id } }, employeeService } = this.props;

    this.handleOpenSignatureDialog(value);
    employeeService.getImagesSignature(id);
  };

  handleDeleteSignature = () => {
    const {
      employeeService: { deleteSignature, getSimpleSignature },
      match: { params: { id } },
    } = this.props;

    this.handleSignatureDeleteDialog(false);

    deleteSignature(id).then(() => getSimpleSignature(id));

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SIMPLE_SIGNATURE.USER_CLICKED_DELETE_SS_BUTTON, {
      removeSignature: 'yes',
    });
  };

  handleCloseSignatureDeleteDialog = () => {
    this.handleSignatureDeleteDialog(false);

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SIMPLE_SIGNATURE.USER_CLICKED_DELETE_SS_BUTTON, {
      removeSignature: 'cancel',
    });
  };

  openRights = () => {
    const {
      employeeService,
      approvalSchemesService: { store: { list: { value } } },
      approvalERSchemesService: { store: { list: { value: approvalERValue } } },
      rightsStore: { chosenApprovalScheme, chosenApprovalERScheme },
    } = this.props;
    const { chosenTP } = this.state;

    const idApprovalScheme = !chosenApprovalScheme && value.length ? value[0].Id : chosenApprovalScheme;
    const idApprovalERScheme = !chosenApprovalERScheme && approvalERValue.length ? approvalERValue[0].Id : chosenApprovalERScheme;

    employeeService.updateChosenTP(chosenTP);
    employeeService.updateApprovalScheme(idApprovalScheme);
    employeeService.updateApprovalERScheme(idApprovalERScheme);
    employeeService.setRights(value, approvalERValue);

    this.setState({
      showRights: true,
    });
  };

  handleResize = (e: any) => {
    if (e.target.innerWidth < 1400) {
      this.setState({ showTooltip: true });
    } else {
      this.setState({ showTooltip: false });
    }
  };

  handleChangeTooltip = () => this.setState({ showTooltip: true });

  handleSetActiveBookingDialog = (value: boolean) => this.setState({ showActiveBookingDialog: value });

  handleSetArchiveSubmitDialog = (value: boolean) => this.setState({ archiveSubmitDialog: value });

  handleOpenTravelHelper = () => {
    const { chatService, productAnalyticsProtocol } = this.props;

    this.handleSetActiveBookingDialog(false);
    chatService.show();
    productAnalyticsProtocol.clickIntercom();
  };

  handleCloseEmployeeErrDialog = () => this.setState({ saveEmployeeError: { show: false } });

  handleInputChange = (value: string, field: string) => {
    const { documentType } = this.state;
    const { employeeService: { changeInput, validateRequiredFields } } = this.props;

    changeInput(
      field,
      value,
      documentType,
      this.forceTravelPolicy,
      this.forceEmployeeNumber,
      this.forceEmployeeNumSixDigits,
    );
    validateRequiredFields(this.forceTravelPolicy, this.forceEmployeeNumber, this.forceEmployeeNumSixDigits);
  };

  handleCopyClick = (url: string) => {
    const { notificationService } = this.props;

    clipboard(url);
    notificationService.send({
      message: LABELS.NOTIFICATIONS.COPY,
      type: 'success',
    });
  };

  handleDocumentInputChange = (value: string, field: string, index: number) => {
    this.props.employeeService.changeDocumentInput(
      value,
      field,
      index,
      this.state.documentType,
      this.forceTravelPolicy,
      this.forceEmployeeNumber,
      this.forceEmployeeNumSixDigits,
    );
  };

  handleCompaniesChange = (value: ICompanyEasy[]) => {
    this.props.employeeService.changeCompanies(value);
  };

  handleDateChange = (field: string, value: string | Moment) => {
    const { documentType } = this.state;
    const { employeeService: { changeInput } } = this.props;

    changeInput(
      field,
      value || '',
      documentType,
      this.forceTravelPolicy,
      this.forceEmployeeNumber,
      this.forceEmployeeNumSixDigits,
    );
  };

  handleDocumentDateChange = (field: string, index: number, value: string) => {
    const { documentType } = this.state;
    const { employeeService: { changeDocumentInput } } = this.props;

    changeDocumentInput(
      value,
      field,
      index,
      documentType,
      this.forceTravelPolicy,
      this.forceEmployeeNumber,
      this.forceEmployeeNumSixDigits,
    );
  };

  handleSexChange = (field: string, value: string) => {
    const { employeeService: { changeInput, validateRequiredFields } } = this.props;
    const { documentType } = this.state;

    changeInput(
      field,
      value,
      documentType,
      this.forceTravelPolicy,
      this.forceEmployeeNumber,
      this.forceEmployeeNumSixDigits,
    );
    validateRequiredFields(
      this.forceTravelPolicy,
      this.forceEmployeeNumber,
      this.forceEmployeeNumSixDigits,
    );
  };

  handleBonusChange = (value: string, index: number) => this.props.employeeService.changeBonus(value, index);

  handleAddBonus = () => this.props.employeeService.addBonus();

  handleAddDocument = () =>
    this.props.employeeService.addDocument(
      this.state.documentType,
      this.forceTravelPolicy,
      this.forceEmployeeNumber,
      this.forceEmployeeNumSixDigits,
    );

  handleDeleteBonus = (index: number) => this.props.employeeService.deleteBonus(index);

  handleDeleteDocument = (index: number) => {
    const { documentType } = this.state;
    const { employeeService: { deleteDocument } } = this.props;

    deleteDocument(
      index,
      documentType,
      this.forceTravelPolicy,
      this.forceEmployeeNumber,
      this.forceEmployeeNumSixDigits,
    );
  };

  handleCitizenshipSuggestSelected = (suggest: TCitizenship) => {
    if (suggest !== null) {
      this.setState({ citizenshipName: suggest.Name },
        () => this.props.employeeService.setCitizenshipValue(
          suggest,
          this.state.documentType,
          this.forceTravelPolicy,
          this.forceEmployeeNumber,
          this.forceEmployeeNumSixDigits,
        ),
      );
    }
  };

  handleGetCitizenshipSuggests = (query: string) => {
    this.setState({ citizenshipName: query },
      () => this.props.employeeService.citizenshipAutocomplete(query));
  };

  handleAirlineSuggestSelected = (suggest: SuggestItem, index: number) =>
    this.props.employeeService.setAirlineValue(suggest, index);

  handleGetAirlineSuggests = (query: string, index: number) =>
    this.props.employeeService.employeeAirline(query, index, this.state.airlines);

  handleRuNumberChange = (value: string, index: number) => {
    const { documentType, employeePrevious: { Documents } } = this.state;
    const { employeeService: { changeRuNumberPassport } } = this.props;

    changeRuNumberPassport(
      value,
      index,
      documentType,
      Documents,
      this.forceTravelPolicy,
      this.forceEmployeeNumber,
      this.forceEmployeeNumSixDigits,
    );
  };

  handleCertificateNumberChange = (value: string, index: number) =>
    this.props.employeeService.changeCertificateNumber(
      value,
      index,
      this.state.documentType,
      this.forceTravelPolicy,
      this.forceEmployeeNumber,
      this.forceEmployeeNumSixDigits,
    );

  handleIntNumberChange = (value: string, index: number) => {
    const { employeeService: { changeIntNumberPassport } } = this.props;
    const { documentType, employeePrevious: { Documents } } = this.state;

    changeIntNumberPassport(
      value,
      index,
      documentType,
      Documents,
      this.forceTravelPolicy,
      this.forceEmployeeNumber,
      this.forceEmployeeNumSixDigits,
    );
  };

  handleDomesticNumberChange = (value: string, index: number) => {
    const { employeePrevious: { Documents } } = this.state;
    const { employeeService: { checkDomesticPassportNumber } } = this.props;

    checkDomesticPassportNumber(value, index, Documents);
  };

  handleProjectSelect = (id: number) => this.props.employeeService.setProjectValue(id);

  handleSetTp = (id: string) => {
    const { documentType } = this.state;
    const { employeeService: { changeChosenTp, validateRequiredFields } } = this.props;

    changeChosenTp(
      id,
      documentType,
      this.forceTravelPolicy,
      this.forceEmployeeNumber,
      this.forceEmployeeNumSixDigits,
    );
    validateRequiredFields(
      this.forceTravelPolicy,
      this.forceEmployeeNumber,
      this.forceEmployeeNumSixDigits,
    );
  };

  handleChangeTp = (id: string) => {
    this.handleSetTp(id);
    this.handleChangeThemeButton(id);
  };

  handleValidationInput = (field: string) => {
    const { employeeService } = this.props;
    employeeService.validateRequiredFields(
      this.forceTravelPolicy,
      this.forceEmployeeNumber,
      this.forceEmployeeNumSixDigits,
    );
    employeeService.validationInput(field);
  };

  handleDocumentValidationInput = (field: string, index: number) => {
    const { documentType, employeePrevious: { Documents } } = this.state;
    const { employeeService: { validationDocumentInput } } = this.props;

    validationDocumentInput(
      field,
      index,
      documentType,
      Documents,
      this.forceTravelPolicy,
      this.forceEmployeeNumber,
      this.forceEmployeeNumSixDigits,
    );
  };

  handleResetErrors = (field: string) => {
    const { employeeService, rightsStore: { errorMsg } } = this.props;

    employeeService.resetErrors(field);

    if (field === EMPLOYEEFIELD.EMAIL && errorMsg) {
      employeeService.updateErrorMsg(false);
    }
  };

  handleDocumentResetErrors = (field: string, index: number) =>
    this.props.employeeService.resetDocumentErrors(field, index);

  handleGoBack = () => {
    const { goBack, push } = this.props.history;

    if (this.isNewPage) return push(ROUTES.SETTINGS.EMPLOYEES);

    return goBack();
  };

  handleChangeThemeButton = (chosenTp: string) => {
    const { employeeService } = this.props;

    const theme = employeeService.checkDifferencesRights(chosenTp)
      ? 'primary'
      : 'secondary';

    this.setState({
      themeButton: theme,
    });
  };

  handleSaveRights = () => {
    const { employeeService, rightsStore } = this.props;
    const { chosenTP, documentType } = this.state;
    const { buyTripAccount, buyTripPersonal, buyTravelPolicy } = rightsStore.rights;

    const changedTP = rightsStore.chosenTP;
    const canBuySelfByTp = !buyTripAccount && buyTripPersonal && buyTravelPolicy && changedTP !== chosenTP;

    if (canBuySelfByTp) {
      if (rightsStore.tpRightsByCopyUser.Id.length) {
        employeeService.setTpRights(
          rightsStore.tpRightsByCopyUser.Id,
          documentType,
          this.forceTravelPolicy,
          this.forceEmployeeNumber,
          this.forceEmployeeNumSixDigits,
        );
        employeeService.updateTpRightsByCopyUser(null);
      }

      this.handleSetTp(changedTP);
    }

    employeeService.changeViewRights();
    this.handleChangeThemeButton(canBuySelfByTp ? changedTP : chosenTP);
  };

  handleSetError = (field: string) => this.props.employeeService.setErrors(
    field,
    this.state.documentType,
    this.forceTravelPolicy,
    this.forceEmployeeNumber,
    this.forceEmployeeNumSixDigits,
  );

  onPhoneInputBlur = (isValid: boolean) => {
    this.props.employeeService.validateRequiredFields(
      this.forceTravelPolicy,
      this.forceEmployeeNumber,
      this.forceEmployeeNumSixDigits,
    );

    return isValid
      ? this.handleResetErrors(EMPLOYEEFIELD.MOBILEPHONE)
      : this.handleSetError(EMPLOYEEFIELD.MOBILEPHONE);
  };

  saveApprovalSchemeBundle = (employeeId: number) => {
    const { employeeService } = this.props;
    const { rightsStore: { chosenApprovalScheme } } = this.props;

    if (!chosenApprovalScheme) {
      return;
    }

    const schemeBundle = {
      EmployeeId: employeeId,
      SchemeId: chosenApprovalScheme,
    };

    employeeService.saveApprovalSchemeBundle(schemeBundle).catch();
  };

  saveTPBundle = (employeeId: number, tpBundleId: string) => {
    const { employeeService } = this.props;

    return employeeService.saveTPBundle({
      EmployeeId: employeeId,
      PolicyId: tpBundleId,
    });
  };

  saveApprovalERSchemeBundle = (employeeId: number) => {
    const { employeeService, rightsStore: { chosenApprovalERScheme } } = this.props;

    if (!chosenApprovalERScheme) return;

    const schemeBundle = {
      EmployeeId: employeeId,
      SchemeId: chosenApprovalERScheme,
    };

    employeeService.saveApprovalExpenseReportsSchemeBundle(schemeBundle);
  };

  handleSaveError = (e: any, errorCollection: any, defaultValue: { title: string, text: string }, cb = () => {}) => {
    const { employeeService } = this.props;

    try {
      const { response: { statusCode, text } } = e;

      if (statusCode !== 400) {
        return cb();
      }

      const { Message } = JSON.parse(text);
      const { title, text: errorText } = errorCollection[Number(Message)] || defaultValue;

      employeeService.stopLoad();

      return this.setState({
        archiveSubmitDialog: false,
        saveEmployeeError: {
          show: true,
          title,
          text: errorText,
        },
      });
    } catch (s) {
      return cb();
    }
  };

  handleSave = async (item: any) => {
    const {
      employeeService,
      notificationService,
      cartService,
      notepadService,
      rightsStore: {
        sourceRights,
        login,
        sourceChosenTP,
        accountLng,
        chosenApprovalScheme,
      },
      approvalSchemesService,
    } = this.props;
    const { documentType, selectedTripTags, chosenTP } = this.state;

    const newItem = { ...item };

    newItem.Documents = newItem.Documents.filter(({ Type, Number, Surname, Name, LastName, FirstName, DueDate }: any) => {
      const currentField = Number.trim().length && Surname.trim().length && Name.trim().length;

      if (Type === documentType.DomesticPassport && newItem.Code === COUNTRIES.RU.SHORTNAME) {
        return currentField && LastName.trim().length && FirstName.trim().length;
      }

      if (Type === documentType.ForeignPassport || newItem.Code !== COUNTRIES.RU.SHORTNAME) {
        const isNotRequiredDueDate = checkUaWithDomesticPassport(newItem.Code, Type);

        return currentField && (DueDate || isNotRequiredDueDate);
      }

      if (Type === documentType.BirthCertificate) {
        return currentField;
      }

      return false;
    });
    // @ts-ignore
    newItem.Bonuses = newItem.Bonuses.filter(bonus => bonus.Code && bonus.Number);

    newItem.EmployeeNumber = newItem.EmployeeNumber.trim();
    // @ts-ignore
    newItem.Companies = newItem.Companies.map(({ main, nested = [] }) => ({ CompanyId: main, Departments: nested.map(i => ({ Id: i })) }));

    newItem.ApprovalSchemeId = chosenApprovalScheme;

    if (isSmartAgent && !this.isTraveler) {
      newItem.Status = STATUS_FOR_SMART_AGENT_USER;
    }

    // TODO: (__.__) PROMISE JOPA
    if (this.validation()) {
      employeeService.updateState(documentType);
      employeeService.load();
      const { TpRights, ...dataToBeSaved } = newItem;

      try {
        const employee = await employeeService.saveOrUpdate(dataToBeSaved);
        const savedId = employee ? employee.Id : this.state.employee.Id;
        await this.handleUpdateTripTags(savedId, selectedTripTags);

        const finallyCb = () => {
          if (this.cartItemId) {
            const data = {
              EmployeeId: employee.Id,
              CartItemIds: [this.cartItemId],
            };
            cartService.setEmployeeInCartItem(data).then(this.handleGoBack).catch(this.handleGoBack);
          } else if (this.noteItemId) {
            notepadService.addEmployee(this.noteItemId, employee.Id);
          } else {
            this.handleGoBack();
          }
        };

        const employeeId = newItem.Id ? newItem.Id : employee?.Id;

        let savedTp = false;

        try {
          await this.saveTPBundle(employeeId, newItem.TpRights.PolicyId);
          savedTp = true;
        } catch (e) {
          // nothing to do
        }

        this.saveApprovalSchemeBundle(employeeId);
        this.saveApprovalERSchemeBundle(employeeId);

        const sourceLogin = sourceRights ? sourceRights.Login : '';

        return employeeService.checkUserRelevance(employeeId, login, sourceLogin)
          .then(() => {
            const saveForEmployee = isSmartAgent ? LABELS.NOTIFICATIONS.USER_SAVE : LABELS.NOTIFICATIONS.EMPLOYEE_SAVE;
            const newForEmployee = isSmartAgent ? LABELS.NOTIFICATIONS.USER_NEW : LABELS.NOTIFICATIONS.EMPLOYEE_NEW;

            const messageForTraveler = newItem.Id
              ? LABELS.NOTIFICATIONS.TRAVELER_SAVE
              : LABELS.NOTIFICATIONS.TRAVELER_NEW;
            const messageForEmployee = newItem.Id
              ? saveForEmployee
              : newForEmployee;

            const rights = employeeService.preparedRightsToSave();

            employeeService.saveApproversId(employeeId, employeeService.preparedApproversToSaveId());
            employeeService.saveApproversExpenseReports(employeeId, employeeService.preparedApproversExpenseReportsToSave());
            employeeService.saveRights(employeeId, rights)
              .then(({ SendEmail, Rights: { UserId, AccountId } }: any) => {
                if (SendEmail) {
                  notificationService.send({
                    message: LABELS.NOTIFICATIONS.EMAIL_SENT(newItem.Email),
                    type: 'success',
                    qaAttr: notifications.createPassword,
                  });
                }

                if (UserId && (employeeService.checkDifferencesRights(chosenTP) || (savedTp && chosenTP !== sourceChosenTP))) {
                  employeeService.updateIntercomCommentForUser(UserId);
                }

                if (UserId) {
                  employeeService.setAccountLng(UserId, AccountId, accountLng);
                }

                notificationService.send({
                  message: this.isTraveler ? messageForTraveler : messageForEmployee,
                  type: 'success',
                  qaAttr: newItem.Id ? notifications.edited : notifications.success,
                });

                finallyCb();
                employeeService.clearStore();
              })
              .catch((e: any) => {
                this.handleSaveError(e, SAVE_RIGHTS_ERROR_CODES, SAVE_RIGHTS_ERROR_CODES.DEFAULT, finallyCb);

                approvalSchemesService.loadEmployeesWithRights()
                  .then((employees: EmployeesObj[]) => {
                    employeeService.setEmployees(employees);
                  });
              });
          })
          .catch((e: any) => this.handleSaveError(e, SAVE_RIGHTS_ERROR_CODES, SAVE_RIGHTS_ERROR_CODES.DEFAULT, finallyCb));
      } catch (err: any) {
        if (err.status === 400) {
          employeeService.stopLoad();

          return this.handleSetActiveBookingDialog(true);
        }

        return this.handleGoBack();
      }
    }

    return null;
  };

  handleChangeApproveERUsers = (values: string[]) => this.props.employeeService.changeApproveERUsers(values);

  handleChangeApprovalERScheme = (value: number | boolean | string) => this.props.employeeService.updateApprovalERScheme(value);

  handleChangeBuyWithPersonalFunds = (value: string | boolean) => this.props.employeeService.changeBuyWithPersonalFunds(value);

  handleToScroll = (anchor: string) => {
    const REFS = {
      [ANCHOR.GENERAL]: this.generalRef,
      [ANCHOR.ACCESS]: this.accessRef,
      [ANCHOR.SIGNATURE]: this.signatureRef,
      [ANCHOR.ADDITIONALLY]: this.additionallyRef,
      [ANCHOR.DOCUMENTS]: this.documentRef,
    };

    const scrollTo: any = REFS[anchor].current;

    if (scrollTo) {
      window.scroll({ top: scrollTo.offsetTop - 130, left: 0, behavior: 'smooth' });
    }
  };

  handleStepClick = (anchor: string) => {
    if (this.state.currentStep === anchor) {
      return;
    }

    this.setState({ currentStep: anchor });
  };

  handleArchive = () => {
    const { employee } = this.state;
    const { employeeService, notificationService } = this.props;

    const employeeArchivedText = isSmartAgent
      ? LABELS.NOTIFICATIONS.USER_ARCHIVE
      : LABELS.NOTIFICATIONS.EMPLOYEE_ARCHIVE;

    const message = !this.isTraveler
      ? employeeArchivedText
      : LABELS.NOTIFICATIONS.TRAVELER_ARCHIVE;

    employeeService.archive(employee.Id)
      .then(() => {
        notificationService.send({
          message,
          type: 'success',
          qaAttr: notifications.archive,
        });
        this.handleGoBack();
      }).catch((e: any) => {
        this.handleSaveError(e, ARCHIVE_ERROR_CODES, ARCHIVE_ERROR_CODES.DEFAULT);
      });
  };

  handleUnarchive = () => {
    const { employee } = this.state;
    const { employeeService, notificationService } = this.props;

    const employeeUnarchivedText = isSmartAgent
      ? LABELS.NOTIFICATIONS.USER_UNARCHIVED
      : LABELS.NOTIFICATIONS.EMPLOYEE_UNARCHIVED;
    const message = !this.isTraveler
      ? employeeUnarchivedText
      : LABELS.NOTIFICATIONS.TRAVELER_UNARCHIVED;

    employeeService.toRestore(employee.Id)
      .then(() => {
        notificationService.send({
          message,
          type: 'success',
          qaAttr: notifications.archiveRestored,
        });
      });
  };

  handleUpdateTripTags = (employeeId: number, tripTags: number[]) =>
    this.props.tripTagsService.updateEmployeeTripTags(employeeId, tripTags);

  handleChangeSelectedTripTags = (selectedTripTags: number[]) => this.setState({ selectedTripTags });

  handleBlurInput = (value: string, field: string) => {
    const clearValue = sanitizeText(value);

    this.handleInputChange(clearValue, field);
    this.handleValidationInput(field);
  };

  handleBlurDocumentInput = (value: string, field: string, index: number) => {
    const { employeeService } = this.props;
    const { documentType } = this.state;

    const clearValue = sanitizeText(value);

    employeeService.changeDocumentInput(
      clearValue,
      field,
      index,
      documentType,
      this.forceTravelPolicy,
      this.forceEmployeeNumber,
      this.forceEmployeeNumSixDigits,
    );
    this.handleValidationInput(field);
  };

  handleOpenRights = () => {
    const { employeeService, rightsStore: { viewRights } } = this.props;

    if (viewRights) {
      return this.openRights();
    }

    const { employee } = this.state;

    return employeeService.changeUserLogin(employee.Email, employee.Id)
      .then((res: boolean) => {
        if (res) {
          this.openRights();
        } else {
          this.handleToScroll(ANCHOR.GENERAL);
        }
      })
      .catch(() => this.handleToScroll(ANCHOR.GENERAL));
  };

  handleCloseRights = () => this.setState({
    showRights: false,
  }, () => {
    this.props.employeeService.clearRights();
  });

  handleCloseAccess = () => this.setState({
    showRights: false,
  }, () => {
    const { employeeService, rightsStore: { tpRightsByCopyUser } } = this.props;

    employeeService.closeAccess();
    this.handleChangeThemeButton(this.state.chosenTP);

    if (tpRightsByCopyUser.Id.length) employeeService.updateTpRightsByCopyUser(null);
  });

  handleChangeRights = (field: string, value: string) => this.props.employeeService.changeRights(field, value);

  handleChangeAccountLng = (lng: string) => this.props.employeeService.changeAccountLng(lng);

  handleChangeUser = (value: string) => this.props.employeeService.changeUserAndApprovalScheme(value);

  handleChangeApproveUser = (values: string[]) => this.props.employeeService.changeApproversForUser(values);

  handleChangeViewApprovers = (values: string[]) => {
    this.props.employeeService.changeViewApprovers(values);
    this.handleChangeThemeButton(this.state.chosenTP);
  };

  handleChangeTravelPolicy = (value: string) => this.props.employeeService.updateChosenTP(value);

  handleChangeApprovalScheme = (value: string) => this.props.employeeService.updateApprovalScheme(value);

  handleChangeDocuments = (value: string) => this.props.employeeService.changeDocuments(value);

  handleLogin = () => this.props.appService.showLogin();

  setTgMailShowModal = (showTgMail: boolean) => {
    this.setState({ showTgMail });
  };

  validation = () => this.props.employeeService.validationSuccess(this.state.employee);

  isCurrentStep = (anchor: string) => this.state.currentStep === anchor;

  canShowTp = () => {
    const {
      canEditRights,
      canEditSelfRights,
      chosenTP,
      employee,
    } = this.state;

    const {
      travelPoliciesStore: {
        list,
      },
      rightsStore: {
        viewRights,
      },
      workspaceService,
    } = this.props;

    const isRight =
      viewRights && viewRights.buyTripPersonal && !viewRights.buyTripAccount && viewRights.buyTravelPolicy && chosenTP;

    const { Rights, Rights: { EditEmployee } } = workspaceService.get();
    const profile = this.forceTravelPolicy
      && this.forceEmployeeNumber
      && this.forceEmployeeNumSixDigits
      && Rights.EmployeeId !== employee.Id
      && EditEmployee === EDITEMPLOYEERIGHT.All;

    if (profile) {
      return !!list.length && !isRight && !canEditSelfRights;
    }

    return !isSmartAgent && !!list.length && canEditRights && !isRight && !canEditSelfRights;
  };

  renderLoading = () => <PageLoader />;

  renderErrorsTooltipItem = (title: string, errors?: Element | string) => {
    const { showTooltip } = this.state;

    return showTooltip ? (
      <Text
        color='white'
        type='NORMAL_14_130'
      >
        <b>{ title }</b> { errors }
      </Text>
    ) : (
      <>
        <Text
          color='white'
          type='bold_16'
        >
          { title }
        </Text>
        { errors && (
          <Text
            color='white'
            type='NORMAL_14_130'
            className={ styles['error-text'] }
          >
            { errors }
          </Text>
        ) }
      </>
    );
  };

  renderDemoTooltipText = () => {
    const labelTooltipOne = isSmartAgent ? LABELS.DEMO_TOOLTIP.ONE_SMARTAGENT : LABELS.DEMO_TOOLTIP.ONE;

    return (
      <Text
        className={ styles.tooltip }
        color='white'
        type='NORMAL_14_130'
      >
        { labelTooltipOne }
        {' '}
        <a className={ styles['login-link'] } onClick={ this.handleLogin }>{ LABELS.DEMO_TOOLTIP.TWO }</a>
        {' '}
        { LABELS.DEMO_TOOLTIP.THREE }
      </Text>
    );
  };

  renderErrorTextRequiredFields = (errorsList: IErrorListEmployee[], documentsErrors: boolean) => {
    const {
      workspaceService,
      workspaceService: {
        isAdmin,
      },
    } = this.props;
    const {
      employee,
      employee: { Code },
      requiredFieldsErrors: {
        empty,
        emptyDocuments,
      },
    } = this.state;
    const { Rights } = workspaceService.get();

    const updatedEmptyDocuments = emptyDocuments.map(({ type }: { type: string }) => {
      if (type === PASSPORTS.BIRTH_CERTIFICATE.TYPE) {
        return PASSPORTS.BIRTH_CERTIFICATE.VALUE;
      }

      if (Code === COUNTRIES.RU.SHORTNAME) {
        return type === PASSPORTS.DOMESTIC_PASSPORT.TYPE ? PASSPORTS.RU_PASSPORT.VALUE : PASSPORTS.FOREIGN_PASSPORT.VALUE;
      }

      return PASSPORTS.DOMESTIC_PASSPORT.VALUE;
    });

    const updatedEmptyFields = () => {
      const emptyTp = empty.some((item: string) => item === EMPLOYEEFIELD.TRAVEL_POLICY);
      const conditionTP = this.forceTravelPolicy && (Rights.EmployeeId === employee.Id) && emptyTp && !isAdmin;

      const getFileldsNames = (feildsArray: string[]) => feildsArray.map((field: string) => FIELD_NAMES[field]);

      if (conditionTP) {
        const newEmpty = empty.filter((item: string) => item !== EMPLOYEEFIELD.TRAVEL_POLICY);

        newEmpty.push(TRAVEL_POLICY_BY_ADMIN);

        return getFileldsNames(newEmpty);
      }
      // мб тут

      return getFileldsNames(empty);
    };

    const allEmptyFields = [...updatedEmptyFields(), ...updatedEmptyDocuments];
    const textErrorEmptyFields = allEmptyFields.join(', ');
    const errorEmptyFields = textErrorEmptyFields.length > 0
      && this.renderErrorsTooltipItem(ERROR_LABELS.REQUIRED_FIELDS, textErrorEmptyFields);

    const errorsListHtml = errorsList
      .filter(({ field }: { field: string }) => field !== DOCUMENTSFIELDS.PATRONYMIC)
      .map(({ field } : { field: string }, i: number) => <span key={ i }>{ !!i && ', '} { FIELD_NAMES[field] }</span>);

    const incorrectRequiredFields = errorsListHtml.length > 0
      && this.renderErrorsTooltipItem(ERROR_LABELS.REQUIRED_FIELDS_INCORRECT, (errorsListHtml as any));

    const incorrectPatronymic = errorsList.find(({ field }:{ field: string }) => field === DOCUMENTSFIELDS.PATRONYMIC)
      && this.renderErrorsTooltipItem(ERROR_LABELS.PATRONYMIC_INCORRECT);

    const incorrectDocumentsFieldsError = documentsErrors
      && (
        <Text
          color='white'
          type='NORMAL_14_130'
        >
          { ERROR_LABELS.DOCUMENTS_FIELDS_INCORRECT }
        </Text>
      );

    return (
      <>
        { errorEmptyFields }
        { incorrectRequiredFields }
        { incorrectPatronymic }
        { incorrectDocumentsFieldsError }
      </>
    );
  };

  renderErrorsTooltip = () => {
    const { errors, requiredFieldsErrors: { empty, emptyDocuments }, showTooltip } = this.state;
    let errorsList: IErrorListEmployee[] = [];
    let documentsErrors = false;

    Object.keys(errors).forEach((key) => {
      if (errors[key] && typeof errors[key] === 'string') {
        errorsList.push({ field: key, value: errors[key] });
      } else if (errors[key] && typeof errors[key] === 'object') {
        documentsErrors = !!errors[key].filter((docError: any) =>
          Object.keys(docError).some(docKey => docError[docKey].trim().length)).length;
      }
    });

    errorsList = errorsList.filter(({ field }: { field: string }) => !empty.includes(field));
    const tooltipStyle = showTooltip ? styles.error_tooltip : styles.tooltip;

    return (!!empty.length || !!emptyDocuments.length || !!errorsList.length || documentsErrors) && (
      <Text
        type='NORMAL_12_120'
        className={ tooltipStyle }
      >
        { this.renderErrorTextRequiredFields(errorsList, documentsErrors) }
      </Text>
    );
  };

  renderNotCanEditTooltip = () => (
    <Text
      color='white'
      className={ styles.tooltip }
      type='NORMAL_14_130'
    >
      { LABELS.NOT_AVAILABLE_EDIT }
    </Text>
  );

  renderSubmitTooltip = () => {
    const {
      workspaceService: {
        rights: {
          EmployeeId,
        },
      },
    } = this.props;

    const {
      employee,
      employee: {
        Id,
      },
      isValid,
      canEditEmployee,
      canEditSelf,
      showTooltip,
    } = this.state;

    const canEdit = canEditEmployee || (canEditSelf && Id === EmployeeId);
    const positionTooltip = showTooltip ? 'right' : 'top';

    const tooltipContent = !isValid
      ? this.renderErrorsTooltip
      : this.renderNotCanEditTooltip;

    const qaAttr = (!isValid || !canEdit) ? submitDisabled : submit;

    return (
      <Tooltip
        position={ positionTooltip }
        show={ !canEdit || !isValid }
        renderContent={ tooltipContent }
      >
        <Button
          type='secondary'
          disabled={ !isValid || !canEdit }
          onClick={ () => this.handleSave(employee) }
          qaAttr={ qaAttr }
        >
          { LABELS.SAVE }
        </Button>
      </Tooltip>
    );
  };

  renderSubmitButton = () => {
    const { disabledUser } = this.state;

    const qaAttr = disabledUser ? submitDisabled : submit;

    return !disabledUser
      ? this.renderSubmitTooltip()
      : (
        <Tooltip
          renderContent={ this.renderDemoTooltipText }
        >
          <Button
            type='secondary'
            disabled={ disabledUser }
            qaAttr={ qaAttr }
          >
            { LABELS.SAVE }
          </Button>
        </Tooltip>
      );
  };

  renderButtonArchived = () => {
    const { workspaceService } = this.props;

    const {
      employee: { Status, Id, Projects },
      disabledUser,
      selectCompaniesItems,
      canEditRights,
    } = this.state;

    if (Id === 0
      || !canEditRights
      || workspaceService.rights.EmployeeId === Id
    ) {
      return null;
    }

    const isNotArchivedUser = Status && Status !== STATUS.ARCHIVE;

    const label = isNotArchivedUser ? LABELS.TO_ARCHIVE : LABELS.FROM_ARCHIVE;
    const qaAttrArchive = isNotArchivedUser ? toArchive : fromArchive;
    const activeAction = () =>
      (Projects.length || (selectCompaniesItems && selectCompaniesItems.some(({ nested }: { nested: { headId: number }[] }) => nested &&
        nested.some(({ headId }: any) => String(headId) === String(Id))))
        ? this.handleSetArchiveSubmitDialog(true)
        : this.handleArchive());

    const action = isNotArchivedUser ? activeAction : this.handleUnarchive;

    return (
      <Tooltip
        className={ styles.archive }
        show={ disabledUser }
        renderContent={ this.renderDemoTooltipText }
      >
        <LinkButton
          disabled={ disabledUser }
          onClick={ () => (disabledUser ? null : action()) }
          qaAttr={ qaAttrArchive }
        >
          { label }
        </LinkButton>
      </Tooltip>
    );
  };

  renderTpContent = () => {
    const { chosenTP } = this.state;
    const { travelPoliciesStore: { list } } = this.props;

    const fieldLabelHtml = this.forceTravelPolicy && <FieldLabel mustHave text={ '' } />;

    return (
      <div className={ styles.content }>
        <Text
          type='bold_18'
          className={ styles.subtitle }
          qaAttr={ access.titleTP }
        >
          { LABELS.TP }
          {fieldLabelHtml}
        </Text>
        <div className={ styles['tp-wrap'] }>
          <Select
            theme='default-border'
            placeholder={ LABELS.NOT_APPLY }
            items={ mappedPolicies(list) }
            value={ chosenTP }
            onChange={ this.handleSetTp }
            className={ styles.select }
            qaAttr={ access.suggestTP }
          />
          { chosenTP && <ClearButton onClick={ () => this.handleSetTp('') } /> }
        </div>
      </div>
    );
  };

  renderAccessContent = () => {
    const {
      chosenTP,
      canEditRights,
      canEditSelfRights,
      employee,
      employee: {
        Email,
        Underage,
      },
      errors,
    } = this.state;

    const {
      rightsStore: {
        viewRights,
        allApproveUsersWithId,
        viewApprovers,
        viewExpenseReportApprovers,
        approversListLoading,
        chosenApprovalScheme,
        approveERUsers,
        chosenApprovalERScheme,
      },
      approvalERSchemesService: {
        store: {
          list: {
            value: approvalERSchemesValues,
          },
        },
      },
      uiSettingsProtocol: {
        isWhiteLabel,
      },
      featureFlagsService,
      personalPaymentProtocol,
      travelPoliciesStore: { list, loading: tpLoading },
      approvalSchemesService: { store: { list: { value, loading: schemesLoading } } },
      stores: { uiSettingsStore: { settings: { showReports } } },
    } = this.props;

    const travelPolicy = list.length > 0 ? mappedPolicies(list)[0].value : '';
    const mappedAllApproveUsers = allApproveUsersWithId.map((item: string[]) => ({ ...item }));
    const mappedViewApprovers = viewApprovers.map((item: string) => item);
    const disabledLink =
      NAME_LIST.some(item => errors[item] || !!errors[item].length || !employee[item].length)
      || errors.Login
      || !!errors.Login.length
      || Underage;

    return (
      <div className={ styles.content } >
        <Text
          type='bold_18'
          className={ styles.subtitle }
          qaAttr={ access.settingsOfAccessAndNotifications }
        >
          { LABELS.SETTINGS_OF_ACCESS_AND_NOTIFICATIONS }
        </Text>
        <AccessStep
          email={ Email }
          showExpenseReportApprove={ this.showExpenseReportApprove }
          showReports={ showReports }
          rights={ viewRights }
          loading={ tpLoading || schemesLoading || approversListLoading }
          viewApprovers={ mappedViewApprovers }
          showRightsBlock={ canEditRights }
          selfRights={ canEditSelfRights }
          disabledLink={ disabledLink }
          employeeIsUnderage={ Underage }
          chosenTP={ chosenTP || travelPolicy }
          chosenApprovalScheme={ chosenApprovalScheme }
          viewExpenseReportApprovers={ viewExpenseReportApprovers }
          travelPolicies={ mappedPolicies(list) }
          approvalSchemes={ value }
          approveERUsers={ approveERUsers }
          approvalERSchemes={ approvalERSchemesValues }
          chosenApprovalERScheme={ chosenApprovalERScheme }
          allApproveUsers={ mappedAllApproveUsers }
          onChangeTravelPolicy={ this.handleChangeTp }
          onChangeViewApprovers={ this.handleChangeViewApprovers }
          onChangeApprovalScheme={ this.handleChangeApprovalScheme }
          onChangeApproveERUsers={ this.handleChangeApproveERUsers }
          onChangeApprovalERScheme={ this.handleChangeApprovalERScheme }
          onOpenRights={ this.handleOpenRights }
          isWhiteLabel={ isWhiteLabel() }
          isDisplayInsurance={ featureFlagsService.getDisplayInsurance() }
          personalPaymentProtocol={ personalPaymentProtocol }
        />
      </div>
    );
  };

  renderSignatureContent = () => {
    const { workspaceService: { isAdmin, canEditRights } } = this.props;

    return (
      <div className={ styles.content }>
        <SimpleSignatureStep
          canEditRights={ canEditRights }
          isAdmin={ isAdmin }
          onOpenSignatureDialog={ this.handleGetImagesSignature }
          onDeleteSignature={ () => this.handleSignatureDeleteDialog(true) }
        />
      </div>
    );
  };

  renderAccessStep = () => {
    const { canEditRights, employee: { Status } } = this.state;
    const showTp = this.canShowTp();

    if (this.isTraveler || (isSmartAgent && Status === STATUS.ACCESS.ACTIVE)) return null;

    return (showTp || canEditRights) && (
      <Step
        title={ LABELS.ACCESS }
        ref={ this.accessRef }
        onClick={ () => this.handleStepClick(ANCHOR.ACCESS) }
        isActive={ this.isCurrentStep(ANCHOR.ACCESS) }
        qaAttr={ access.title }
      >
        <div className={ styles.main }>
          { showTp && !isSmartAgent && this.renderTpContent() }
          { canEditRights && this.renderAccessContent() }
        </div>
      </Step>
    );
  };

  renderSimpleSignature = () => {
    const { workspaceService: { isAdmin, rights: { EmployeeId } }, featureFlagsService } = this.props;
    const { employee: { Id, Status } } = this.state;
    const { SimpleSignature } = featureFlagsService.getFeatureFlags();

    const isNotArchivedUser = Status && Status !== STATUS.ARCHIVE;

    const availablePerson = (isAdmin || Id === EmployeeId) && STATUS.ACCESS.USER === Status;

    return !this.isTraveler && isNotArchivedUser && Id && availablePerson && SimpleSignature && !isSmartAgent ? (
      <Step
        title={ LABELS.ELECTRONIC_SIGNATURE }
        ref={ this.signatureRef }
        onClick={ () => this.handleStepClick(ANCHOR.SIGNATURE) }
        isActive={ this.isCurrentStep(ANCHOR.SIGNATURE) }
      >
        <div className={ styles.main }>
          { this.renderSignatureContent() }
        </div>
      </Step>
    ) : null;
  };

  renderEmployeeNumberContent = () => {
    const { tripTagsService, workspaceService } = this.props;
    const { workspaceService: { rights: { EmployeeId } } } = this.props;
    const {
      selectedTripTags,
      projects,
      employee: {
        EmployeeNumber,
        ProjectId,
        Id,
      },
      errors: {
        EmployeeNumber: EmployeeNumberError,
      },
    } = this.state;

    const allProjectsIsArchival = projects?.every(({ IsArchival }: { IsArchival: boolean }) => IsArchival === true);
    const isUserEmployee = Id === EmployeeId;
    const employeeCanShow = EmployeeId === 0 || !isUserEmployee;
    const showProject = !!ProjectId || (!!projects.length && !allProjectsIsArchival && employeeCanShow);
    const showTitleError = EmployeeNumberError === ERROR_LABELS.EMPLOYEE_NUMBER_SIX_DIGITS;

    const titleError = showTitleError && (
      <div className={ styles.error }>
        <Text
          type='NORMAL_10'
          className={ styles.text }
        >
          { EmployeeNumberError }
        </Text>
      </div>
    );

    const title = (
      <Text
        type='bold_18'
        className={ styles.subtitle }
      >
        { LABELS.DATA_FOR_REPORTS }
      </Text>
    );

    const input = (
      <div className={ styles.row }>
        <div className={ styles.item }>
          <FieldLabel
            mustHave={ this.forceEmployeeNumber }
            text={ LABELS.EMPLOYEE_NUMBER }
          />
          <Input
            value={ EmployeeNumber }
            onChange={ (v: string) => this.handleInputChange(v, EMPLOYEEFIELD.EMPLOYEE_NUMBER) }
            qaAttr={ additionally.employeeNumber }
          />
        </div>
      </div>
    );

    const tripTags = (
      <TripTags
        tripTagsService={ tripTagsService }
        selectedTags={ selectedTripTags }
        onChangeTags={ this.handleChangeSelectedTripTags }
        type={ TYPES.EMPLOYEEPAGE }
        store={ tripTagsService.store }
        workspaceService={ workspaceService }
        qaAttrLink={ additionally.tagsLink }
        qaAttrSearch={ additionally.newLink.search }
        qaAttrAdd={ additionally.newLink.add }
        noeditable={ false }
      />
    );

    const projectsSelect = showProject && (
      <ProjectsInEmployeePage
        projects={ projects }
        projectId={ ProjectId }
        isUserEmployee={ isUserEmployee }
        onSelect={ (id: number) => this.handleProjectSelect(id) }
        qaAttr={ additionally.costCenterLink }
        qaAttrSearch={ additionally.projectSelect }
        qaAttrFirstEl={ additionally.costCenterFirstEl }
      />
    );

    const content = !this.isTraveler ? (
      <>
        { title }
        { input }
        { titleError }
        { tripTags }
        { projectsSelect }
      </>
    ) : (
      <>
        { title }
        { tripTags }
      </>
    );

    return (
      <div className={ styles.content }>
        {content}
      </div>
    );
  };

  renderBonusesContent = () => {
    if (!this.getPersonalBonusCards) {
      return null;
    }

    const { employee, airlines, errors } = this.state;

    return (
      <div className={ styles.content }>
        <Text
          type='bold_18'
          className={ styles.subtitle }
          qaAttr={ additionally.bonusCart }
        >
          { LABELS.BONUSES }
        </Text>
        <Text type='NORMAL_16_140' className={ styles.attention }>
          { LABELS.BONUSES_ATTENTION }
        </Text>
        <Bonuses
          employee={ employee }
          airlines={ airlines }
          errors={ errors }
          onAirlineSuggestSelected={ this.handleAirlineSuggestSelected }
          onGetAirlineSuggests={ this.handleGetAirlineSuggests }
          onBonusChange={ this.handleBonusChange }
          onDeleteBonus={ this.handleDeleteBonus }
          onAddBonus={ this.handleAddBonus }
        />
      </div>
    );
  };

  renderAdditionalStep = () => {
    const { employee: { Status } } = this.state;

    if (isSmartAgent && (!this.isTraveler && Status !== STATUS.ACCESS.ACTIVE)) return null;

    return (
      <Step
        title={ LABELS.ADDITIONALLY }
        ref={ this.additionallyRef }
        onClick={ () => this.handleStepClick(ANCHOR.ADDITIONALLY) }
        isActive={ this.isCurrentStep(ANCHOR.ADDITIONALLY) }
        qaAttr={ additionally.title }
      >
        <div className={ styles.main }>
          { this.renderEmployeeNumberContent() }
          { this.renderBonusesContent() }
        </div>
      </Step>
    );
  };

  renderDocuments = () => {
    const {
      employee,
      employee: {
        Documents,
        Birthday,
        employeeHasCyrillicDomesticPassport,
      },
      errors: {
        Documents: errDocuments,
      },
      documentType,
    } = this.state;
    let indexForeign = 1;
    let indexDomestic = 1;

    const mayHavePassport = !Birthday || employeeMayHavePassport(Birthday);

    const newDocuments = Documents.map((document: IDocumentUser) => {
      const newDocument = { ...document };

      if (document.Type === PASSPORTS.FOREIGN_PASSPORT.TYPE) {
        newDocument.index = indexForeign;
        indexForeign++;
      }

      if (document.Type === PASSPORTS.DOMESTIC_PASSPORT.TYPE) {
        newDocument.index = indexDomestic;
        indexDomestic++;
      }

      return newDocument;
    });

    return newDocuments.map((document: any, index: number) => (
      <DocumentForm
        key={ `${document.Type}__${index}` }
        index={ document.index }
        employee={ employee }
        document={ document }
        error={ errDocuments[index] }
        documentType={ documentType }
        onResetErrors={ (field: string) => this.handleDocumentResetErrors(field, index) }
        onValidationInput={ (field: string) => this.handleDocumentValidationInput(field, index) }
        onInputChange={ (value: string, field: string) => this.handleDocumentInputChange(value, field, index) }
        onBlurInput={ (value: string, field: string) => this.handleBlurDocumentInput(value, field, index) }
        onRuNumberChange={ (e: any) => this.handleRuNumberChange(e, index) }
        onCertificateNumberChange={ (e: any) => this.handleCertificateNumberChange(e, index) }
        onIntNumberChange={ (e: any) => this.handleIntNumberChange(e, index) }
        onDomesticNumberChange={ (e: any) => this.handleDomesticNumberChange(e, index) }
        onDateChange={ (field: string, value: string) => this.handleDocumentDateChange(field, index, value) }
        onDeleteDocument={ () => this.handleDeleteDocument(index) }
        mayHavePassport={ mayHavePassport }
        cyrillicPassport={ employeeHasCyrillicDomesticPassport ?? 0 }
      />
    ));
  };

  renderActiveBookingDialog = () => (
    <ActiveBookingDialog
      show={ this.state.showActiveBookingDialog }
      onClose={ () => this.handleSetActiveBookingDialog(false) }
      handleOpenTravelHelper={ this.handleOpenTravelHelper }
    />
  );

  renderPrompt = () => (
    <Prompt
      when={ this.state.themeButton === 'primary' }
      message={ LABELS.EXIT_MESSAGE }
    />
  );

  renderDialogRights = () => {
    const { employee, showRights } = this.state;

    const {
      rightsStore: {
        allApproveUsersWithId,
        approveERUsers,
        documents,
        rights,
        users,
        chosenTP,
        chosenApprovalScheme,
        chosenApprovalERScheme,
        viewExpenseReportApprovers,
        accountLng,
      },
      travelPoliciesStore: {
        list,
      },
      approvalSchemesService: {
        store: {
          list: {
            value,
          },
        },
      },
      approvalERSchemesService: {
        store: {
          list: {
            value: approvalERSchemesValues,
          },
        },
      },
      stores: {
        uiSettingsStore: {
          settings: {
            showReports,
          },
        },
      },
      uiSettingsProtocol: {
        isWhiteLabel,
      },
      featureFlagsService,
      personalPaymentProtocol,
    } = this.props;

    if (!showRights) {
      return null;
    }

    const approveUsers = allApproveUsersWithId.map((item: string[]) => ({ ...item }));
    const mappedDocuments = documents.map((item: string[]) => ({ ...item }));

    return (
      <CommonBlock
        accountLng={ accountLng }
        allApproveUsers={ approveUsers }
        approvalERSchemes={ approvalERSchemesValues }
        approvalSchemes={ value }
        approveERUsers={ approveERUsers }
        chosenApprovalERScheme={ chosenApprovalERScheme }
        chosenApprovalScheme={ chosenApprovalScheme }
        chosenTP={ chosenTP }
        documents={ mappedDocuments }
        employee={ employee }
        featureFlagsService={ featureFlagsService }
        getCopyUserRights={ this.getCopyUserRights }
        isWhiteLabel={ isWhiteLabel() }
        onChangeAccountLng={ this.handleChangeAccountLng }
        onChangeApprovalERScheme={ this.handleChangeApprovalERScheme }
        onChangeApprovalScheme={ this.handleChangeApprovalScheme }
        onChangeApproveERUsers={ this.handleChangeApproveERUsers }
        onChangeApproveUsers={ this.handleChangeApproveUser }
        onChangeDocuments={ this.handleChangeDocuments }
        onChangeRights={ this.handleChangeRights }
        onChangeTravelPolicy={ this.handleChangeTravelPolicy }
        onChangeUser={ this.handleChangeUser }
        onClose={ this.handleCloseRights }
        onCloseAccess={ this.handleCloseAccess }
        onSave={ this.handleSaveRights }
        rights={ rights }
        show={ showRights }
        showExpenseReportApprove={ this.showExpenseReportApprove }
        showReports={ showReports }
        travelPolicies={ mappedPolicies(list) }
        users={ users }
        viewExpenseReportApprovers={ viewExpenseReportApprovers }
        onChangeBuyWithPersonalFunds={ this.handleChangeBuyWithPersonalFunds }
        personalPaymentProtocol={ personalPaymentProtocol }
      />
    );
  };

  renderSignatureDataDialog = () => {
    const { employeeService, stores: { simpleSignatureStore } } = this.props;
    const { showSignatureDialog } = this.state;

    const { data, errorData, imagesSignature, loadings } = simpleSignatureStore;

    return showSignatureDialog && (
      <SignatureDialog
        data={ data }
        loadings={ loadings }
        images={ imagesSignature }
        errorData={ errorData }
        onCreateSimpleSignature={ this.createSimpleSignature }
        onCloseSignatureDialog={ this.handleCloseSignatureDialog }
        onSetDataSignature={ employeeService.setDataSignature }
        onSetDataSignatureErrors={ employeeService.setDataSignatureErrors }
      />
    );
  };

  renderSignatureCodeDialog = () => {
    const { showSignatureCodeDialog } = this.state;
    const {
      employeeService: {
        setActiveTimer,
      },
      stores: {
        simpleSignatureStore,
      },
    } = this.props;

    const { data: { phoneNumber } } = simpleSignatureStore;

    if (showSignatureCodeDialog) {
      return (
        <CodeDialog
          showChangeNumber
          phoneNumber={ phoneNumber }
          show={ showSignatureCodeDialog }
          onSetActiveTimer={ setActiveTimer }
          onClose={ this.handleCloseSignatureCodeDialog }
          onChange={ this.handleChangeSignatureCode }
          onRepeat={ this.handleRepeatGetCode }
          onChangeNumber={ this.handleChangeNumberSignatureCode }
          onSave={ this.handleSendCode }
          amplitudeProperty={ 'agreement' }
        />
      );
    }

    return null;
  };

  renderSignatureConfirmationDialog = () => {
    const { showSignatureConfirmationDialog } = this.state;

    return showSignatureConfirmationDialog && (
      <ConfirmationDialog
        show={ showSignatureConfirmationDialog }
        onClose={ this.handleCloseDialog }
        onSendCode={ this.createSimpleSignature }
        onChangeNumber={ this.handleChangeNumberSignatureCode }
      />
    );
  };

  renderSignatureDeleteDialog = () => {
    const { showSignatureDeleteDialog } = this.state;

    return showSignatureDeleteDialog && (
      <Dialog
        show={ showSignatureDeleteDialog }
        onChange={ () => this.handleSignatureDeleteDialog(false) }
        showClosing
      >
        <div className={ styles.dialog }>
          <Text
            type='bold_20'
            className={ styles.title }
          >
            { LABELS.SIGNATURE.DELETE_SIGNATURE }
          </Text>
          <Text>
            { LABELS.SIGNATURE.ARE_YOU_SURE }
          </Text>
        </div>
        <div className={ styles.signature_delete_dialog_actions }>
          <Button
            className={ styles.submit }
            type='secondary'
            onClick={ this.handleDeleteSignature }
          >
            { LABELS.SIGNATURE.SUBMIT }
          </Button>
          <Button
            type='textual'
            onClick={ this.handleCloseSignatureDeleteDialog }
          >
            { LABELS.SIGNATURE.CANCEL }
          </Button>
        </div>
      </Dialog>
    );
  };

  renderGeneralStep = () => {
    const {
      employee,
      errors,
      citizenshipSuggestions,
      citizenshipName,
      currentDate,
    } = this.state;
    const { featureFlagsService, workspaceService: { isAdmin } } = this.props;
    const { DepartmentOnly } = featureFlagsService.getFeatureFlags();
    const { rightsStore: { errorMsg } } = this.props;

    return (
      <Step
        title={ LABELS.GENERAL }
        ref={ this.generalRef }
        onClick={ () => this.handleStepClick(ANCHOR.GENERAL) }
        isActive={ this.isCurrentStep(ANCHOR.GENERAL) }
        qaAttr={ general.general }
      >
        <GeneralStep
          currentDate={ currentDate }
          isTraveler={ this.isTraveler }
          errors={ errors }
          isAdmin={ isAdmin }
          employee={ employee }
          errorMsg={ errorMsg }
          citizenshipName={ citizenshipName }
          DepartmentOnly={ DepartmentOnly }
          citizenshipSuggestions={ citizenshipSuggestions }
          onPhoneInputBlur={ this.onPhoneInputBlur }
          handleBlurInput={ this.handleBlurInput }
          handleSexChange={ this.handleSexChange }
          handleResetErrors={ this.handleResetErrors }
          handleInputChange={ this.handleInputChange }
          handleCompaniesChange={ this.handleCompaniesChange }
          handleValidationInput={ this.handleValidationInput }
          handleGetCitizenshipSuggests={ this.handleGetCitizenshipSuggests }
          handleCitizenshipSuggestSelected={ this.handleCitizenshipSuggestSelected }
          handleDateChange={ this.handleDateChange }
        />
      </Step>
    );
  };

  renderAddDocumentButton = (text: string, qaAttr: string) => (
    <IconButton
      tabIndex={ -1 }
      iconType='plusRound'
      onClick={ this.handleAddDocument }
      className={ styles.button }
      theme='default-red'
      qaAttr={ qaAttr }
    >
      { text }
    </IconButton>
  );

  renderDocumentsStep = () => {
    const {
      employee: { Code, Documents },
      documentType,
    } = this.state;

    const documentsHtml = this.renderDocuments();

    const domDocuments = Documents.filter(({ Type }: { Type: string }) => Type === documentType.DomesticPassport);
    const forDocuments = Documents.filter(({ Type }: { Type: string }) => Type === documentType.ForeignPassport);

    const QAForeignPassport = QA_ATTRIBUTES.employee.documents.addForeignPassport;
    const QADomesticPassport = QA_ATTRIBUTES.employee.documents.addDomesticPassport;

    const addPassport =
      Code === 'RU' && forDocuments.length === 1 && this.renderAddDocumentButton(LABELS.ADD_FOREIGN_PASSPORT, QAForeignPassport);

    const addDomesticPassport = () => {
      if (!checkTwoForeignPassports(Code) || domDocuments.length !== 1) {
        return null;
      }

      const renderTooltipContent = (
        <div
          className={ styles.tooltip_add }
        >
          <Text
            color='white'
          >
            { LABELS.ERROR_DOMESTIC_MESSAGE.ONE }
          </Text>
          <Text
            color='white'
          >
            { LABELS.ERROR_DOMESTIC_MESSAGE.TWO }
          </Text>
        </div>
      );

      return (
        <Tooltip
          show
          position='bottom'
          className={ styles.tooltip_add_pos }
          renderContent={ () => renderTooltipContent }
        >
          { this.renderAddDocumentButton(LABELS.ADD_DOMESTIC_PASSPORT, QADomesticPassport)}
        </Tooltip>
      );
    };

    return (
      <Step
        title={ LABELS.DOCUMENTS }
        ref={ this.documentRef }
        onClick={ () => this.handleStepClick(ANCHOR.DOCUMENTS) }
        isActive={ this.isCurrentStep(ANCHOR.DOCUMENTS) }
        withBackground={ false }
        qaAttr={ QA_ATTRIBUTES.employee.documents.title }
      >
        <div className={ styles.main }>
          { documentsHtml }
          { addPassport }
          <div
            className={ styles.wrapper_add_button }
          >
            { addDomesticPassport() }
          </div>
        </div>
      </Step>
    );
  };

  renderNavigationPanelItem = (title: string, anchor: string, qaAttr: string) => (
    <LinkButton
      theme='blue-without-border'
      onClick={ () => this.handleToScroll(anchor) }
      className={ styles.item }
      qaAttr={ qaAttr }
    >
      { title }
    </LinkButton>
  );

  renderNavigationPanel = () => {
    const { canEditRights, employee, employee: { Status, Id } } = this.state;
    const {
      workspaceService,
      workspaceService: { isAdmin, rights: { EmployeeId } },
      featureFlagsService,
    } = this.props;
    const { Rights, Rights: { EditEmployee } } = workspaceService.get();
    const labelBonuses = this.getPersonalBonusCards
      ? LABELS.BONUSES_AND_ANALYTICS
      : LABELS.ONLY_ANALYTICS;

    const isNotArchivedUser = Status && Status !== STATUS.ARCHIVE;
    const availablePerson = (isAdmin || Id === EmployeeId) && STATUS.ACCESS.USER === Status;
    const { SimpleSignature } = featureFlagsService.getFeatureFlags();

    const profile = !this.isTraveler
      && !(isSmartAgent && Status === STATUS.ACCESS.ACTIVE)
      && (canEditRights ||
        (this.forceTravelPolicy
          && this.forceEmployeeNumber
          && this.forceEmployeeNumSixDigits
          && Rights.EmployeeId !== employee.Id
          && EditEmployee === EDITEMPLOYEERIGHT.All
        )
      );

    const renderNavigateBonuses = (!isSmartAgent || this.isTraveler)
      ? this.renderNavigationPanelItem(labelBonuses, ANCHOR.ADDITIONALLY, navigation.bonus)
      : null;

    const renderSignature = !this.isTraveler && Id && isNotArchivedUser && availablePerson && SimpleSignature && !isSmartAgent ?
      this.renderNavigationPanelItem(LABELS.ELECTRONIC_SIGNATURE, ANCHOR.SIGNATURE, navigation.signature) : null;

    return (
      <StyledWrapper className={ styles.menu }>
        { this.renderNavigationPanelItem(LABELS.GENERAL, ANCHOR.GENERAL, navigation.general) }
        { this.renderNavigationPanelItem(LABELS.DOCUMENTS, ANCHOR.DOCUMENTS, navigation.documents) }
        { profile && this.renderNavigationPanelItem(LABELS.ACCESS, ANCHOR.ACCESS, navigation.access) }
        { renderSignature }
        { renderNavigateBonuses }
      </StyledWrapper>
    );
  };

  renderRegistrationBlock = () => {
    const { employee: { UserIdentity, Email } } = this.state;

    const registrationStatus = UserIdentity ? UserIdentity.RegistrationStatusStr : null;
    const registrationUrl = UserIdentity ? UserIdentity.RegistrationUrl : '';

    return registrationStatus && registrationStatus === 'Pending' && (
      <StyledWrapper className={ styles.registration }>
        <Text>
          { LABELS.REGISTRATION_BLOCK.ONE } <b>{ Email }</b>.
          {' '}
          <LinkButton
            theme='large-default'
            className={ styles.link }
            onClick={ () => this.handleCopyClick(registrationUrl) }
          >
            { LABELS.REGISTRATION_BLOCK.TWO }
          </LinkButton>
          {' '}
          { LABELS.REGISTRATION_BLOCK.THREE }
        </Text>
      </StyledWrapper>
    );
  };

  renderActionsPanel = () => (
    <div className={ styles['action-panel'] }>
      <div className={ styles.content }>
        <div className={ styles.actions }>
          { this.renderSubmitButton() }
          <LinkButton
            theme='blue-without-border'
            className={ styles.cancel }
            onClick={ this.handleGoBack }
            qaAttr={ QA_ATTRIBUTES.employee.cancel }
          >
            <Text
              color='accent'
              type='NORMAL_14_130'
            >
              { LABELS.CANCEL }
            </Text>
          </LinkButton>
        </div>
        { this.renderButtonArchived() }
      </div>
    </div>
  );

  renderErrorDialog = () => {
    const { saveEmployeeError: { show, title, text } } = this.state;

    return (
      <Dialog
        show={ show }
        onChange={ () => this.handleCloseEmployeeErrDialog }
        showClosing
      >
        <div className={ styles.dialog }>
          <Text
            type='bold_20'
            className={ styles.title }
          >
            { title }
          </Text>
          <Text>
            { text }
          </Text>
        </div>
      </Dialog>
    );
  };

  renderArchiveSubmitDialog = () => {
    const { archiveSubmitDialog, employee, selectCompaniesItems = [] } = this.state;
    const { Projects = [], Id } = employee;

    const projectsContent = !!Projects.length && (
      <Text
        type='NORMAL_16_140'
      >
        { LABELS.RESPONSIBLE_BY }
        {' '}
        { toDecline(Projects.length, ARCHIVE_LABELS.PROJECT_DECLINES)}
        {' '}
        <b>{ Projects.map(({ Name }: { Name: string }) => Name).join(', ')}</b>
      </Text>
    );

    const departments = !!selectCompaniesItems && selectCompaniesItems.reduce((r: any, { nested }: { nested: { headId: string | number }[] }) => {
      const filtered = nested.filter(({ headId }) => String(headId) === String(Id));

      return [...r, ...filtered];
    }, []);

    const departmentsContent = !!departments.length && (
      <Text
        type='NORMAL_16_140'
      >
        { LABELS.MANAGER }
        {' '}
        { toDecline(departments.length, ARCHIVE_LABELS.DEPARTMENT_DECLINES)}
        {' '}
        <b>{ departments.map(({ label }: { label: string }) => label).join(', ') }</b>
      </Text>
    );

    return (
      <Dialog
        show={ archiveSubmitDialog }
        onChange={ () => this.handleSetArchiveSubmitDialog(false) }
      >
        <div className={ styles.dialog }>
          <Text
            type='bold_20'
            className={ styles.title }
          >
            { LABELS.ARCHIVATION }
          </Text>
          <Text>
            { ARCHIVE_LABELS.TITLE.FIRST_PART } <b>{ getEmployeeFullName(employee)}</b> { ARCHIVE_LABELS.TITLE.SECOND_PART }
          </Text>
          <div className={ styles.content }>
            { projectsContent }
            { departmentsContent }
          </div>
          <div className={ styles.actions }>
            <Button
              type='secondary'
              onClick={ this.handleArchive }
            >
              { ARCHIVE_LABELS.SUBMIT }
            </Button>
            <LinkButton
              onClick={ () => this.handleSetArchiveSubmitDialog(false) }
              className={ styles.cancel }
            >
              { ARCHIVE_LABELS.DECLINE }
            </LinkButton>
          </div>
        </div>
      </Dialog>
    );
  };

  renderBackLink = () =>
    <BackLink
      text={ LABELS.BACK }
      alternativeDesign={ isSmartAgent }
      onClick={ this.handleGoBack }
      qaAttr={ QA_ATTRIBUTES.employee.back }
    />;

  renderDialogTelegram = () => {
    const {
      showTgMail,
      employee: { Email, Id },
    } = this.state;

    return (
      <TgMailDialog
        id={ Id }
        show={ showTgMail }
        initialMail={ Email }
        setShow={ this.setTgMailShowModal }
      />
    );
  };

  renderTitle = () => {
    const {
      workspaceService: {
        rights: { EmployeeId },
        canEditRights,
      },
      uiSettingsProtocol: {
        isWhiteLabel,
      },
    } = this.props;
    const { employee, employee: { Id } } = this.state;

    const isUser = isSmartAgent ? LABELS.NEW_USER : LABELS.NEW_EMPLOYEE;
    const isTraveler = this.isTraveler ? LABELS.NEW_TRAVELER : isUser;
    const title = Id
      ? this.props.employeeService.getEmployeeName(employee)
      : isTraveler;

    const renderTgOptions = (
      (EmployeeId === employee.Id || canEditRights) && !isWhiteLabel()
    ) ? <TgOptions id={ Id } openModal={ this.setTgMailShowModal } /> : null;

    return (
      <div className={ styles.topBlock }>
        <Text
          type='bold_32'
          qaAttr={ general.title }
        >
          { title }
        </Text>
        { renderTgOptions }
      </div>
    );
  };

  renderReminder = (flag: boolean, label: string, addLabel = '') => {
    if (!flag) return null;

    if (addLabel) {
      return (
        <Text
          className={ styles.reminder }
          type='NORMAL_16'
          color='red'
        >
          { label }
          <b>
            { addLabel }
          </b>
        </Text>
      );
    }

    return (
      <Text
        className={ styles.reminder }
        type='NORMAL_16'
        color='red'
      >
        { label }
      </Text>
    );
  };

  renderStepContent = () => (
    <div className={ styles.panel }>
      <div className={ styles.col1 }>
        <Stepper>
          {this.renderGeneralStep()}
          {this.renderDocumentsStep()}
          {this.renderAccessStep()}
          {this.renderSimpleSignature()}
          {this.renderAdditionalStep()}
        </Stepper>
      </div>
      <div className={ styles.col2 }>
        {this.renderNavigationPanel()}
      </div>
    </div>
  );

  renderPage = () => (
    <div className={ styles.wrap }>
      { this.renderPrompt() }
      { this.renderBackLink() }
      { this.renderTitle() }
      { this.renderReminder(this.showVSKWarnText, LABELS.REMINDER) }
      { this.renderReminder(this.showBurCustomMessage, LABELS.CUSTOM_MESSAGE_BUR_SERVICE, LABELS.CUSTOM_MESSAGE_BUR_MAIL) }
      { this.renderRegistrationBlock() }
      { this.renderStepContent() }
      { this.renderActionsPanel() }
      { this.renderErrorDialog() }
      { this.renderArchiveSubmitDialog() }
      { this.renderActiveBookingDialog() }
      { this.renderDialogRights() }
      { this.renderSignatureDataDialog() }
      { this.renderSignatureCodeDialog() }
      { this.renderSignatureConfirmationDialog() }
      { this.renderSignatureDeleteDialog() }
      { this.renderDialogTelegram() }
    </div>
  );

  render() {
    const { loading } = this.state;
    const { rightsStore: { viewRightsLoading } } = this.props;

    const html = loading || viewRightsLoading ? this.renderLoading() : this.renderPage();

    return (
      <div className={ styles.page }>{ html }</div>
    );
  }
}

export { EmployeePage };
