import clsx from 'clsx';
import React, { MouseEvent, useEffect, useLayoutEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Collapsible, Text } from 'new-ui';

import { useStores } from '../../../../../bi/context';
import { useServices } from '../../../../../bi/context/services';
import { MOBX_STORES } from '../../../../../bi/context/stores';

import { getText } from '../../../../../../i18n';

import { AirlineAdditionalServicesBaggageRow } from './components';

import parseJSON from '../../../../../bi/utils/parseJsonString';
import { IUnderageEmployeesItem } from '../../../../../bi/utils/employees';

import { AirRoute, AirSegment } from '../../../../../bi/types/airline';
import { ICartItemInfo } from '../../../../../bi/types/airlineAdditionalServices';

import styles from '../styles.module.css';

interface IAirlineAdditionalServicesProps {
  everyEmployeeChoosen: boolean;
  currentItem: ICartItemInfo;
  routes: AirRoute[];
}

const LABELS = {
  ADDITIONAL_BAGGAGE: getText('cart:airlineAdditionalService.baggage.additionalBaggage'),
  RESET_SELECTED_BAGGAGE: getText('cart:airlineAdditionalService.baggage.resetSelectedBaggage'),
  SELECTION_BAGGAGE: getText('cart:airlineAdditionalService.baggage.selectionBaggage'),
  WARNING_SEGMENT: getText('cart:airlineAdditionalService.baggage.warningSegments'),
};

const AirlineAdditionalServicesBaggage = observer(({
  everyEmployeeChoosen,
  currentItem,
  routes,
}: IAirlineAdditionalServicesProps) => {
  const {
    airlineBaggageService: {
      getBaggage,
      setEmployeeList,
      clearBaggages,
      addBaggage,
      saveBaggageUpdateWarning,
    },
    cartService: {
      load,
    },
  } = useServices(['AirlineBaggage', 'Cart']);

  const {
    airlineBaggageStore: {
      baggageOffers,
      employeeList,
      totalBaggageCost,
    },
  } = useStores([MOBX_STORES.AIRLINE_BAGGAGE]);

  const [isOpen, setIsOpen] = useState(false);
  const [resetBaggageSelection, setResetBaggageSelection] = useState(false);

  const isChangedEmployeeListLength = employeeList[currentItem.Id]?.length !== currentItem.Employees.length;

  const baggageRoutes = baggageOffers?.[currentItem.Id]?.BaggageRoutes;

  const getBaggageOffers = async (providerName: string, offerId: string) => {
    await getBaggage(providerName, offerId, currentItem?.Id);
  };

  /** Сброс выбранного багажа к дефолтному виду при возвращении на страницу Корзины */
  useLayoutEffect(() => {
    const updateCartService = async () => {
      await addBaggage();
      await load();
    };

    updateCartService();

    return () => {
      saveBaggageUpdateWarning();
      clearBaggages(currentItem.Id);
    };
  }, []);

  /** Получение предложений по багажу */
  useEffect(() => {
    const { ProviderName, Metadata: { FareId } } = parseJSON(currentItem.Data);
    const offerId = parseJSON(FareId).OfferId;

    getBaggageOffers(ProviderName, offerId);
  }, []);

  /** Обновление списка сотрудников */
  useEffect(() => {
    if (isChangedEmployeeListLength) {
      if (isOpen) {
        setIsOpen(true);
      }

      setEmployeeList(currentItem.Employees, currentItem.Id);
    }
  }, [currentItem]);

  /** Очищение багажа при изменении списка сотрудников */
  useEffect(() => {
    if (!everyEmployeeChoosen || isChangedEmployeeListLength) {
      clearBaggages(currentItem.Id);
      setResetBaggageSelection(true);
    }
  }, [employeeList, everyEmployeeChoosen]);

  const getRouteCode = (segments: AirSegment[]) => {
    const firstSegment = segments[0];
    const lastSegment = segments[segments.length - 1];

    return {
      departure: firstSegment.DepartureCityCode,
      arrival: lastSegment.ArrivalCityCode,
    };
  };

  /** Проверка, что все роуты маршрута имеют соответствующие предложения багажа */
  const isAllBaggageRoutesValid = routes.every((route) => {
    const { departure, arrival } = getRouteCode(route.Segments);

    const baggageRoute = baggageRoutes?.find(
      (routeBaggage) =>
        routeBaggage.Departure === departure &&
        routeBaggage.Arrival === arrival,
    );

    return Boolean(baggageRoute);
  });

  const deleteAllBaggage = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    clearBaggages(currentItem.Id);
    setResetBaggageSelection(true);
  };

  const handleResetComplete = () => {
    setResetBaggageSelection(false);
  };

  const toggleCollapse = () => setIsOpen(prev => !prev);

  const renderCollapsibleHeader = () => (
    <Text type='SEMIBOLD_16'>
      { LABELS.SELECTION_BAGGAGE }
    </Text>
  );

  const renderAirlineAdditionalServicesBaggageRows = () => {
    if (!isAllBaggageRoutesValid) return null;

    return employeeList?.[currentItem.Id]?.map(
      (employeeInfo: IUnderageEmployeesItem, index: number) => (
        <AirlineAdditionalServicesBaggageRow
          key={ employeeInfo?.Employee.Id }
          number={ index + 1 }
          ticketId={ currentItem.Id }
          routes={ routes }
          employee={ employeeInfo?.Employee }
          everyEmployeeChoosen={ everyEmployeeChoosen }
          resetBaggageSelection={ resetBaggageSelection }
          onResetComplete={ handleResetComplete }
          baggageRoutes={ baggageRoutes }
        />
      ),
    );
  };

  const renderWarningSegments = () => {
    const allSegments = routes.reduce<string[]>((acc, route: AirRoute) => {
      if (route.Segments.length < 2 || !employeeList[currentItem.Id]?.length) return acc;

      const segmentsString = route.Segments
        .map(({ DepartureCity, ArrivalCity }: AirSegment) => `${DepartureCity} - ${ArrivalCity}`)
        .join(', ');

      acc.push(`[${segmentsString}]`);

      return acc;
    }, []);

    if (allSegments.length === 0 || !isAllBaggageRoutesValid) return null;

    return (
      <div className={ styles.warning_segment }>
        <Text type='SEMIBOLD_16' color='red'>
          {LABELS.WARNING_SEGMENT} {allSegments.join(', ')}.
        </Text>
      </div>
    );
  };

  const renderTotalBaggageCost = () => {
    const totalCost = totalBaggageCost?.[currentItem.Id] || 0;

    return (
      <Text type='bold_18_130' className={ styles.additional_price }>
        { LABELS.ADDITIONAL_BAGGAGE }: + { totalCost } ₽
      </Text>
    );
  };

  return (
    <div onClick={ toggleCollapse }>
      <Collapsible
        open={ isOpen }
        key={ employeeList[currentItem.Id]?.length }
        title={ renderCollapsibleHeader() }
        headerWrapperClassname={ styles.header_collapsible_wrapper }
      >
        <div className={ clsx(styles.wrapper_places, styles.paddings) }>
          { renderAirlineAdditionalServicesBaggageRows() }
          { renderWarningSegments() }
        </div>
        <div className={ styles.amount }>
          { renderTotalBaggageCost() }
          <Button
            className={ styles.delete_places }
            type='primary-outline'
            onClick={ deleteAllBaggage }
          >
            { LABELS.RESET_SELECTED_BAGGAGE }
          </Button>
        </div>
      </Collapsible>
    </div>
  );
});

export { AirlineAdditionalServicesBaggage };
