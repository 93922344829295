// @ts-nocheck
import Store from './store';
import ACTION from './action';

import { SERVICETYPE } from '../../constants/serviceType';

const NOACCESSERROR = 'Недостаточно прав для бронирования поездки. Обратитесь к администратору аккаунта вашей компании';

class Booking {
  constructor(api) {
    this.api = api.cart;
    this.store = Store();
    this.errorRequest = null;
  }

  getState = () => this.store.getState();

  getServiceName = (service, data) => {
    let serviceName = '';
    switch (service) {
      case SERVICETYPE.AIR: {
        const places = data.Routes
          .map(route => `${route.Segments[0].DepartureCity}-${route.Segments[route.Segments.length - 1].ArrivalCity}`);
        serviceName = `${places.join('; ')}`;
        break;
      }
      case SERVICETYPE.HOTEL: {
        serviceName = `${data.hotel.Name}`;
        break;
      }
      case SERVICETYPE.TRAIN: {
        serviceName = `${data.StationDepart} ${data.StationArrive}`;
        break;
      }
      case SERVICETYPE.TRANSFER: {
        serviceName = `${data.StartPlace.Address} ${data.EndPlace.Address}`;
        break;
      }
    }

    return serviceName;
  };

  startBookingPaymentMethods = ({ Id }, paymentMethod) => this.api.startBookingPaymentMethods(Id, paymentMethod)
    .then((res) => {
      let payload;

      if (typeof res === 'string') {
        payload = {
          bookId: res,
          errorMessages: [],
        };
      } else {
        const { BookId, ErrorMessages } = res;

        payload = {
          bookId: BookId,
          errorMessages: ErrorMessages,
        };
      }

      this.store.dispatch({
        type: ACTION.STARTBOKING,
        payload,
      });

      return typeof res === 'string' ? null : res?.OrderId;
    })
    .catch((e) => {
      const Message = e.status === 403 ? NOACCESSERROR : e.message;
      const { cart: { Items } } = this.getState();

      this.store.dispatch({
        type: ACTION.UPDATEERROR,
        payload: [{
          Message,
          CartItem: Items[0],
        }],
      });
    });

  getBookingStatusPaymentMethods = async (id:string | null = null) => {
    let bookingId;

    if (id) {
      bookingId = id;
    } else {
      bookingId = this.getState().bookingId ? this.getState().bookingId : this.getState().cart.BookId;
    }

    if (!bookingId) {
      return;
    }

    const payload = await this.api.getBookingStatusPaymentMethods(bookingId);
    this.store.dispatch({
      type: ACTION.UPDATESTATUSV2,
      payload,
    });
  };

  getBookingStatusPaymentMethods = async (id:string | null = null) => {
    const { bookingId } = id ? { bookingId: id } : this.getState();

    if (!bookingId) {
      return;
    }

    const payload = await this.api.getBookingStatusPaymentMethods(bookingId);
    this.store.dispatch({
      type: ACTION.UPDATESTATUSV2,
      payload,
    });
  };

  updateCart = payload => this.store.dispatch({
    type: ACTION.UPDATECART,
    payload,
  });

  applyChanges = async (cartId, itemId, apply) => {
    await this.api.applyChanges(cartId, itemId, apply);
    this.store.dispatch({ type: ACTION.APPLYCHANGES });
  };

  applyChangesHotel = async (cartItemId, rateId) => {
    const { bookingId } = this.getState();

    if (!bookingId) {
      return;
    }

    await this.api.applyChangesHotel(cartItemId, rateId, bookingId);
  };

  continueBookingPaymentMethods = async (cartId) => {
    const res = await this.api.continueBookingPaymentMethods(cartId);

    return this.store.dispatch({
      type: ACTION.CONTINUEBOOKING,
      payload: {
        errorMessages: res?.ErrorMessages ?? [],
      },
    });
  };

  cancelBookingPaymentMethods = cartId => this.api.cancelBookingPaymentMethods(cartId);

  setCartItem = (item) => {
    this.store.dispatch({
      type: ACTION.LOADCART,
      payload: item,
    });

    return item;
  };

  resetStore = () => {
    this.store.dispatch({
      type: ACTION.RESET,
    });
  };

  loadAnalogRooms = (cartItemId, rateId) => this.api.loadAnalogRooms(rateId).then(res => this.store.dispatch({
    type: ACTION.LOADANALOGROOMS,
    payload: {
      cartItemId,
      rateId,
      analogRooms: res || [],
    },
  }));

  updateAnalogRooms = (roomId, cartItemId, value) => this.store.dispatch({
    type: ACTION.UPDATEANALOGROOMS,
    payload: {
      roomId,
      cartItemId,
      value,
    },
  });

  subscribe = cb => this.store.subscribe(cb);
}

export default Booking;
