import React, { MouseEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  List,
  Paginate,
  Text,
  PageLoader,
  EmptyPanel,
  NoResults,
  Price as PriceComponent,
  Dropdown,
} from 'new-ui';
import { useQuery } from '@tanstack/react-query';
import { Moment } from 'moment';

import { api } from '../../bi/apiV2';

import { getText } from '../../../i18n';

import FeatureFlags from '../../bi/services/featureFlags';
import App from '../../bi/services/app';
import UiSettingsService from '../../bi/services/uiSettings';
import { OrderService } from '../../bi/types/order';

import Trip from '../../components/Trip';
import { Filters } from './components/Filters';
import { DownloadDialog } from './components/DownloadDialog';
import { ISingleReportButtonProps, SingleReportButton } from './components/SingleReportButton';

import scrollToTop from '../utils/scrollToTop';
import { MainAnalytic } from '../../bi/utils/analytics';
import { formatDate } from '../../bi/utils/formatDate';
import { isSmartAgent } from '../../bi/utils/env';
import { omit } from '../../bi/utils/common';

import { DEFAULT_FILTERS, TRIPSTATUSES } from '../../bi/constants/trips';
import { PATTERN } from '../../bi/constants/dateFormats';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import { AGGREGATORS_ACCOUNT } from '../../bi/constants/accounts';

import { TripData, TripsFilters, TripsReq } from '../../bi/types/trips';

import styles from './index.module.css';

type ReportsEntity = Omit<ISingleReportButtonProps, 'openDialog'>[];

export enum ReportTypes {
  BASIC,
  REPORT_CKR,
  SLA,
}

const TRIPSONPAGE = 15;

const TRIPS_PER_PAGE = 15;

const LABELS = {
  TRIPS: getText('trips:title'),
  REPORTS: getText('trips:reports'),
  DOWNLOAD: getText('trips:download'),
  DOWNLOAD_CKR: getText('trips:downloadCKR'),
  DOWNLOAD_SLA: getText('trips:downloadSLA'),
  NO_RESULTS: getText('trips:noResults'),
  NOT_FOUND: getText('trips:notFound'),
  PAGE_LOADER: getText('trips:loader'),
  SUM_ALL_TRIPS: getText('trips:sumOfAllTrips'),
  DOWNLOAD_ALL_TRIPS: getText('trips:downloadAllTrips'),
  EMPTY: getText('trips:empty'),
};

const useTrips = (data: TripsReq) => useQuery({
  queryKey: ['trips', data],
  queryFn: () => api.trips.getTrips(data),
  keepPreviousData: true,
  staleTime: 60000,
});

interface ITripsProps {
  aggregationId: number,
  getNoMice: boolean,
  featureFlagsService: FeatureFlags,
  isWhiteLabel: boolean,
  appService: App,
  uiSettingsService: UiSettingsService,
  orderService: OrderService,
}

const DEFAULT_REPORTS = [
  { label: LABELS.DOWNLOAD, text: LABELS.DOWNLOAD_ALL_TRIPS, type: ReportTypes.BASIC },
];

const CKR_REPORTS = [
  { label: LABELS.DOWNLOAD_CKR, type: ReportTypes.REPORT_CKR },
  { label: LABELS.DOWNLOAD_SLA, type: ReportTypes.SLA },
];

const Trips = ({
  aggregationId,
  getNoMice,
  featureFlagsService,
  isWhiteLabel,
  appService,
  uiSettingsService,
  orderService,
}: ITripsProps) => {
  const [page, setPage] = useState<number>(1);
  const [showDialog, setShowDialogValue] = useState<ReportTypes | null>(null);
  const [filters, setFilters] = useState<TripsFilters>(DEFAULT_FILTERS);
  const [reports, setReports] = useState<ReportsEntity>([...DEFAULT_REPORTS]);
  const history = useHistory();

  const showEventSticker = !isSmartAgent && !aggregationId && !getNoMice;

  const { agentMode } = appService.get();

  const { data } = useTrips({
    ...filters,
    Limit: TRIPS_PER_PAGE,
    Page: page,
  });

  useEffect(() => {
    if (aggregationId === AGGREGATORS_ACCOUNT.CKR) {
      setReports((a) => [...a, ...CKR_REPORTS]);
    }
  }, [aggregationId]);

  const setShowDialog = (val: ReportTypes | null) => {
    setShowDialogValue(val);

    if (val === ReportTypes.BASIC) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.REPORTING.EXPENSE_CREATE_REPORT_PRESSED);
    }
  };

  useEffect(() => {
    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MAIN.MAIN_TRIPS_OPENED);
  }, []);

  if (!data) return <PageLoader text={ LABELS.PAGE_LOADER } />;

  const { Trips: TripsData, TotalPrice, PageCount } = data;

  const isNoTrips = !TripsData.length &&
    !filters.Cities.length &&
    !filters.Companies.length &&
    !filters.Statuses.length &&
    !filters.Employees.length &&
    !filters.Services.length &&
    !filters.Tags.length &&
    !filters.Projects.length &&
    !filters.AnalyticValues.length &&
    !filters.Departments.length &&
    !filters.UserIsOwner &&
    !filters.StartDate &&
    !filters.EndDate;

  if (isNoTrips) {
    return (
      <EmptyPanel
        title={ LABELS.EMPTY }
        iconType='tripBig'
        alternativeDesign={ isSmartAgent }
        searchMenuItems={ [] }
        qaAttr={ QA_ATTRIBUTES.trips.empty }
      />
    );
  }

  const handleDownloadReportFromDialog = (start: Moment, end: Moment, radioField: string) => {
    const startDate = formatDate(start, PATTERN.YEARMONTHDAY);
    const endDate = formatDate(end, PATTERN.YEARMONTHDAY);

    const finalFilters = { ...filters, StartDate: startDate, EndDate: endDate, ReportType: Number(radioField) };

    switch (showDialog) {
      case ReportTypes.REPORT_CKR:
        return api.trips.getTripsReportCKR(finalFilters);
      case ReportTypes.SLA:
        return api.trips.getSLAReportCKR(finalFilters);
      case ReportTypes.BASIC:
      default:
        return api.trips.getTripsReport(finalFilters);
    }
  };

  const handleChangePage = (value: number) => {
    if (page !== value) {
      scrollToTop();
      setPage(value);
    }
  };

  const handleDownloadVouchers = (e: MouseEvent<HTMLDivElement>, id: number) => {
    const currentLanguage = uiSettingsService.getCurrentLanguage();

    e.preventDefault();
    e.stopPropagation();

    orderService.downloadVouchers(id, currentLanguage);
  };

  const handleOpenItem = (statusTrip: string, id: number) => {
    history.push(`/trip/${id}`);

    MainAnalytic.send(
      MainAnalytic.CATEGORY.TRIPS,
      MainAnalytic.ACTIONS.TRIPS.VIEWTRIPS,
      {
        label: TRIPSTATUSES.get(statusTrip),
      },
    );
  };

  const renderNoResults = () => (
    <NoResults
      renderContent={ () => (
        <div className={ styles.no_results }>
          <Text type='bold_18'>{ LABELS.NOT_FOUND }</Text>
          <Text type='NORMAL_14' className={ styles.subtext }>
            { LABELS.NO_RESULTS }
          </Text>
        </div>
      ) }
    />
  );

  const renderItem = (trip: TripData) => (
    <Trip
      key={ trip.Id }
      value={ trip }
      agentMode={ agentMode }
      showEventSticker={ showEventSticker }
      isDispayInsurance={ featureFlagsService.getDisplayInsurance() }
      isWhiteLabel={ isWhiteLabel }
      onDownload={ e => handleDownloadVouchers(e, trip.Id) }
    />
  );

  const dialogHtml = showDialog !== null ? (
    <DownloadDialog
      onClose={ () => setShowDialog(null) }
      onDownload={ (start, end, radioField) => handleDownloadReportFromDialog(start, end, radioField) }
    />
  ) : null;

  const renderMultiReports = () => (
    <Dropdown
      onClick={ () => {} }
      renderLabel={ () => <Text color='accent'>{ LABELS.REPORTS }</Text> }
    >
      <div className={ styles.dropdown }>
        { reports.map((e) => (
          <SingleReportButton { ...omit(e, ['text']) } key={ e.type } openDialog={ setShowDialog } />
        )) }
      </div>
    </Dropdown>
  );

  const renderReports = reports.length === 1
    ? <SingleReportButton openDialog={ setShowDialog } { ...reports[0] } /> : renderMultiReports();

  const renderTrips = () => (
    <div className={ styles.list }>
      <div className={ styles['sum-title-wrapper'] }>
        <div className={ styles.price }>
          <Text type='bold_24'>{ LABELS.SUM_ALL_TRIPS }:&nbsp;</Text>
          <PriceComponent
            marginSmall
            typeCurrency='normal_20'
            type='bold_24'
            value={ TotalPrice }
          />
        </div>
        <div className={ styles.downloadLinks }>
          { renderReports }
        </div>
      </div>
      <List
        className={ styles.trips }
        renderItem={ (trip: TripData) => renderItem(trip) }
        items={ TripsData }
        onClickItem={ (item: TripData) => handleOpenItem(item.Status, item.Id) }
        qaAttrFirstEl={ QA_ATTRIBUTES.trips.firstItem }
      />
      <Paginate
        page={ page }
        itemsPerPage={ TRIPSONPAGE }
        total={ PageCount * TRIPS_PER_PAGE }
        onChange={ (value: number) => handleChangePage(value) }
      />
    </div>
  );
  const content =
    TripsData.length > 0
      ? renderTrips()
      : renderNoResults();

  return (
    <div className={ styles.wrapper }>
      <Text type='bold_32' className={ styles.header } qaAttr={ QA_ATTRIBUTES.trips.title }>
        { LABELS.TRIPS }
      </Text>
      <Filters
        filters={ filters }
        setFilters={ setFilters }
      />
      { content }
      { dialogHtml }
    </div>
  );
};

export { Trips };
