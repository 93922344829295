import { Moment } from 'moment';
import { MultiSelectValuesNested } from 'new-ui';

import Employee from '../employee';

import { ExpenseReportsStore } from './stores/expenseReports';

interface EmployeeType {
  Number: string,
  Surname: string,
  Name: string,
  Patronymic: string,
  Id?: number,
  Email?: string
  Companies?: any
}
interface FiltersProps {
  onChange: (employees: string, values: string[] | number[] | MultiSelectValuesNested[] | boolean) => void;
  onResetFilters: () => void;
}

interface ExpensesType {
  Type: number,
  Name: string,
  Number: string,
  Amount: number,
  Vat: number,
  VatRate: number,
  Date: string,
}
interface IApproveStore {
  approves: Approval[],
  loading: boolean,
  approveId: null | any,
}

interface Message {
  Name: string;
  Email: string;
  Date: string;
  Comment: string;
}
interface Approval {
  Id: string;
  UserId: string;
  ExpenseReportId: number;
  Messages: Message[];
  Resolution: string;
  EventDate: string;
}

interface PrepaymentsType {
  Method: number,
  Type: number,
  Amount: number,
}

interface DocumentsType {
  FileGuid: string,
  FileName: string,
  FileSize: string,
  EventDate: string,
  FileExtension?: string,
  ValidationDownloadDoc?: string,
}

interface DocumentsTypeForSave {
  FileName: string,
  FileSize: string,
}

interface FileType {
  name: string
  size: number
  type: string
}

interface DataTypes {
  Employee: EmployeeType,
  CompanyName: string,
  Expenses: ExpensesType[],
  TotalExpensesAmount: number,
  Prepayments: PrepaymentsType[],
  TotalPrepaymentsAmount: number,
  Balance: number,
}

interface ItemsType {
  Id: number,
  TripId: number,
  Name: string,
  Amount: number,
  IsRu: boolean,
  JsonData: string,
  ServiceType: string,
  Data: any,
  Voucher: any,
  TripItems?: any[];
}

interface ReportsType {
  Id: number,
  Name: string,
  CheckinDate: string | Date | Moment,
  CheckoutDate: string | Date | Moment,
  Stamp: Moment | string | Date,
  Updated: boolean,
  Status: number,
  Data: DataTypes,
  Items: ItemsType[],
  ImageLinks: DocumentsType[],
  Creator?: string,
  Approves: ApprovesType[],
  CompanyId?: number | string,
  EmployeeId?: number | string,
  selected?:any
}

interface ReportForCreateType {
  CheckinDate: Moment | string | Date,
  CheckoutDate: Moment | string | Date,
  Items: TripType[],
}

interface ExpenseReportsType {
  OnlyOwn: false,
  Reports: ReportsType[],
}
interface ExpenseReportItemProps {
  onlyOwn: boolean;
  isIntegration1S: boolean;
  item: ExpenseItem;
  onSendEmail: (value: any, Id: string | number) => void;
  onDownload: (field: string, id: number) => void;
}

interface ExpenseItem {
  Id: number,
  Name: string,
  CheckinDate: string | Moment | Date,
  CheckoutDate: string | Moment | Date,
  Stamp: string | Moment | Date,
  Updated: boolean,
  Status: number,
  Data: DataTypes,
  Items: ItemsType[],
  ImageLinks: DocumentsType[],
  Approves: ApprovesType[],
}

interface PagingType {
  total: number,
  current: number,
  count: number,
}

type FormatsType = 'pdf' | 'xlsx' | 'email';

interface TripType {
  Id: number,
  TripId:number,
  TripItemId: number,
  CompanyId: number,
  ProjectId: number | null,
  DepartmentId: number | null,
  ProviderName: string,
  ServiceType: string,
  SourceType: string,
  TripItemVersionStatus: string,
  CheckinDate: string,
  CheckoutDate: string,
  EventDate: string,
  City: string,
  Description: string,
  Reason: string,
  CreatedBy: string,
  JsonData: string,
  selected: boolean;
  TripItems: any;
  Name:string
}

interface DialogType {
  sources: TripType[],
  trips: TripType[],
  showTrips: boolean,
  checkbox: boolean,
  isValid: boolean,
  showButtonMore: boolean,
  loading: boolean,
  startDate: Moment | null,
  endDate: Moment | null,
  showCompanies: boolean,
  companies: any[],
  currentCompany: string | number | any,
  employee: any | null,
  [key: string]: Moment | string | Date | boolean | any;
}

interface SettingsExpenseType {
  RuDailyExpenses: number,
  OverseasDailyExpenses: number,
  Rewritable: boolean,
}

interface ObjectFilterType {
  list: any[],
  selected: any,
}

interface MineCheckboxType {
  list: any[],
  checked: boolean,
  name: string,
}

interface FiltersType {
  employees: ObjectFilterType,
  companies: ObjectFilterType,
  mineCheckbox: MineCheckboxType,
  name?: string,
  statuses:any
}

interface ApproversType {
  EmployeeId: number,
  Name: string,
  FirstName: string,
  LastName: string,
  MiddleName: string,
  Email: string,
}

interface ApproversForExpenseReports {
  ConditionOfApproval: number,
  Approvers: ApproversType[]
}

interface MessagesType {
  Comment: string,
  Date: string,
  Email: string,
  Name: string,
  ShowResolution?: boolean,
}

interface ApprovesType {
  ApprovalStepId: null,
  ApproverName: string,
  Email: string,
  EventDate: string,
  ExpenseReportId: number,
  Id: string,
  Resolution: string,
  UserId: string,
  Messages: MessagesType[],
}

interface ReportItemType {
  Approves: ApprovesType[],
  CheckinDate: string,
  CheckoutDate: string,
  CompanyId: number,
  Creator: string,
  Data: DataTypes,
  EmployeeId: number,
  Id: number,
  IsEditingAvailable: boolean,
  IsRemoveAvailable: boolean,
  ItemIds: number[],
  Name: string,
  ProjectNames: string[],
  Stamp: string
  Status: number,
  Updated: boolean,
}

interface ExpenseReportsService {
  loadExpenseReports(name: string): void;
  setPaginate(page: number): void;
  setClearDialog(value: boolean): void;
  openReport(value: null, employee: EmployeeType): void;
  getApproversSteps(value: EmployeeType, selectedTrips: TripType[]): void;
  getApprovers(value: EmployeeType): void;
  setEmployee: () => void;
  setCompany: () => void;
  updateCheckbox: () => void;
  updateDate: () => void;
  updateCheckboxTrip: () => void;
  openAllTrips: () => void;
  getEmployee: () => void;
  sendEmail: () => void;
  downloadReport: () => void;
  updateFilters: () => void;
  resetFilters: () => void;
  loadSettingsExpense(): void,
  resetStoreSettings(): void,
  updateRewritable(value: boolean): void,
  saveSettings(): void,
  updateRuExpense(value: number): void,
  updateAbroadExpense(value: number): void,
}

interface WorkspaceService {
  canCreateExpenseReports: string;
  dataUserEmployee: any;
  get(): any;
  isDemo:boolean
}

interface FeatureFlagsService {
  getShowExpenseReports(): boolean;
  getReservedHotelSmartagent(): boolean;
}

interface StepSetting {
  stepName: string;
  isCompleted: boolean;
}

interface User {
  id: number;
  name: string;
  email: string;
}

interface ReportApprovalData {
  StepSettings: StepSetting[];
  Users: User[];
  Comment: string;
  Email: string;
  EmployeeId: number;
}

interface ExpenseReportsPageState {
  showDialog: boolean;
  showIntroDialog: boolean;
}

interface ExpenseReportsPageProps {
  expenseReportsService: ExpenseReportsService;
  employeeService: Employee;
  workspaceService: WorkspaceService;
  featureFlagsService: FeatureFlagsService;
  history: any;
  stores: {
    expenseReportsStore: ExpenseReportsStore;
  };
}

interface ListItem {
  CheckinDate: string;
  CheckoutDate: string;
  selected: boolean;
}

type CreateDialogPropsType = {
  show: boolean,
  dataUserEmployee: any,
  dialog: DialogType,
  getEmployees: (value: string) => Promise<any[]>,
  onSelectEmployee: () => void,
  onSelectCompany: () => void,
  onClose: () => void,
  onCreate: (argument?: ReportsType | null, _?: any, employee?: string, employeeId?: string | number) => void,
  onChangeCheckbox: () => void,
  onChangeDate: (field: string, value: string | Moment | null) => void,
  onChangeCheckboxTrip: (value: boolean, tripId: string | number) => void,
  onOpenAllTrips: () => void,
  getUserEmployee: (employeeId?: string | number) => void,
  isDemo: boolean,
};

type ItemType = {
  label: string,
  value: number | string,
};

type ItemTypes = {
  label: string,
  value: number | null,
};

export enum AnalyticAdvanceStatus {
  PREPARING = 'preparing',
  DELIVERED = 'delivered',
  ERROR_DELIVERED = 'error_delivered',
}

export enum AnalyticAddExpense {
  SAVE = 'save',
  CANCEL = 'cancel',
}

export type {
  MineCheckboxType,
  ExpenseReportsType,
  ExpenseReportItemProps,
  ReportApprovalData,
  ReportsType,
  PagingType,
  FormatsType,
  TripType,
  DialogType,
  ReportForCreateType,
  PrepaymentsType,
  ExpensesType,
  ItemsType,
  SettingsExpenseType,
  FiltersType,
  ApproversForExpenseReports,
  ApproversType,
  ApprovesType,
  MessagesType,
  DocumentsType,
  DocumentsTypeForSave,
  FileType,
  ReportItemType,
  ItemType,
  ItemTypes,
  ListItem,
  EmployeeType,
  FiltersProps,
  ExpenseReportsService,
  WorkspaceService,
  FeatureFlagsService,
  ExpenseReportsPageState,
  ExpenseReportsPageProps,
  ExpenseItem,
  CreateDialogPropsType,
  IApproveStore,
  Message,
  Approval,
};
