import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Text, Button, ItemPanel } from 'new-ui';
import { getText } from '../../../../i18n';

import { withStores } from '../../../bi/context';
import { MOBX_STORES } from '../../../bi/context/stores';

import { MainAnalytic } from '../../../bi/utils/analytics';
import { getTrainNameByNumber } from '../../../bi/utils/train';
import { applyTrainTP } from '../../../bi/utils/travelPolicy';
import { isSmartAgent } from '../../../bi/utils/env';
import { parseSearchString } from '../../../bi/utils/convertSearchParams';

import FAVORITESACTION from '../../../bi/constants/favorites';
import ANIMATION from '../../../bi/constants/animation';
import CLASSNAMESFORTESTS from '../../../bi/constants/classnamesfortests';
import { QA_ATTRIBUTES } from '../../../bi/constants/attributesForTests';

import { FavoriteAction } from '../../../components/FavoriteAction';
import { LinkAction } from '../../../components/LinkAction';
import { NameErrorsTravelPolicy } from '../../../components/NameErrorsTravelPolicy';
import { InfoProviderIM } from '../../../components/InfoProviderIM';
import { InfoPathTrain } from '../../../components/InfoPathTrain';

import { TrainSearchItem } from './searchItem';
import { FreePlacesDetails } from './freePlaces';

import { TrainTicketProps, TrainTicketState } from './types';

import styles from '../styles/ticket.module.css';

const LABELS = {
  TRAIN_WITH_NUMBER: getText('trains:ticket.train'),
  CHOOSE_CAR: getText('trains:ticket.chooseCar'),
  NON_REFUNDABLE: getText('trains:ticket.nonRefundable'),
  EREGISTRATION_IS_NOT_AVAILABLE: getText('trains:results.eRegWarning'),
};

@withStores([MOBX_STORES.TRAIN_SEARCH, MOBX_STORES.TRAIN_TICKETS])
@observer
class TrainTicket extends Component<TrainTicketProps, TrainTicketState> {
  static defaultProps = {
    onCopyLinkTrip: () => {},
    onGetLinkTrip: () => {},
    onAddToFavorite: () => {},
    onSelectTrain: () => {},
  };

  state = {
    animationClass: '',
  };

  getQAAttrs = () => {
    const { ind } = this.props;

    const {
      itemWrapper,
      buttonCart,
      time,
      duration,
      station,
      dates,
      transfer,
      price,
    } = QA_ATTRIBUTES.search.train.item;

    const isFirstEl = ind === 0;
    const firstElItem = isFirstEl ? itemWrapper : '';
    const firstElButton = isFirstEl ? buttonCart : '';
    const firstElTimeFrom = isFirstEl ? time.from : '';
    const firstElTimeTo = isFirstEl ? time.to : '';
    const firstElDuration = isFirstEl ? duration : '';
    const firstElStationFrom = isFirstEl ? station.from : '';
    const firstElStationTo = isFirstEl ? station.to : '';
    const firstElDateFrom = isFirstEl ? dates.from : '';
    const firstElDateTo = isFirstEl ? dates.to : '';
    const firstElTransfer = isFirstEl ? transfer : '';
    const firstElPrice = isFirstEl ? price : '';

    return {
      firstElItem,
      firstElButton,
      firstElTimeFrom,
      firstElTimeTo,
      firstElDuration,
      firstElStationFrom,
      firstElStationTo,
      firstElDateFrom,
      firstElDateTo,
      firstElTransfer,
      firstElPrice,
    };
  };

  handleChangeFavorite = (action: string) => {
    const { onAddToFavorite, onChangeFavorite, ticket } = this.props;

    if (action === FAVORITESACTION.ADD) {
      const value = parseInt(ticket.TrainNumber, 10);

      this.setState({
        animationClass: ANIMATION.PULSE,
      });

      if (onAddToFavorite) {
        onAddToFavorite(MainAnalytic.LABELS.TRAINS.SEARCHRESULTS, value);
      }
    }

    onChangeFavorite(ticket, action);
  };

  handleAnimationEnd = () => this.setState({
    animationClass: '',
  });

  handleClick = () => {
    const {
      idRequestItem,
      ticket,
      ticket: {
        TrainName,
        TrainNumber,
        TrainId,
        SearchId,
      },
      ind,
      onSelectTrain,
      history,
    } = this.props;

    const trainNameIdentifier = getTrainNameByNumber(ticket);
    const trainName = TrainName || trainNameIdentifier || '';
    const fullTrainName = ` ${TrainNumber} ${trainName ? `(${trainName})` : ''}`;

    if (onSelectTrain) {
      onSelectTrain(fullTrainName, ind + 1);
    }

    const linkUrl = `/search/train/${SearchId}/${TrainId}`;
    const searchParams = parseSearchString(history.location.search);
    const tripId = searchParams?.tripId ? `?tripId=${searchParams.tripId}` : '';

    if (idRequestItem > 0) {
      return history.push({ pathname: linkUrl, search: `is_request=true${tripId}` });
    }

    return history.push({ pathname: linkUrl, search: tripId });
  };

  renderHeader = () => {
    const {
      ticket,
      ticket: {
        TrainName,
        TrainNumberLocal,
        TravelPolicy,
        TrainNumber,
        FavoriteId,
        SearchId,
      },
      travelPolicyList,
      onGetLink,
      onCopyLinkTrip,
      onGetLinkTrip,
      stores: { trainTicketsStore: { filters: { selectedTravelPolicy } } },
      ind,
    } = this.props;

    const trainNameIdentifier = getTrainNameByNumber(ticket);
    const applyTP = applyTrainTP(TravelPolicy, selectedTravelPolicy);

    const trainName = TrainName || trainNameIdentifier || '';
    const trainNumber = TrainNumberLocal || TrainNumber;

    const fullTrainNumber = `${LABELS.TRAIN_WITH_NUMBER} ${trainNumber} ${trainName ? `(${trainName})` : ''}`;

    const qaAttrFirstElTrainNumber = ind === 0 ? QA_ATTRIBUTES.search.train.item.number : '';
    const qaAttrFirstElFavorite = ind === 0 ? QA_ATTRIBUTES.search.train.item.favorite : '';

    const favoriteHtml = !isSmartAgent && (
      <FavoriteAction
        disabled={ applyTP }
        className={ `${styles.link} ${CLASSNAMESFORTESTS.TRAINS.TOFAVORITE}` }
        id={ FavoriteId }
        onClick={ this.handleChangeFavorite }
        qaAttrIcon={ qaAttrFirstElFavorite }
        qaAttrDialog={ QA_ATTRIBUTES.favorites.deleteDialog }
      />
    );

    return (
      <div className={ styles.header }>
        <Text
          type='NORMAL_18'
          qaAttr={ qaAttrFirstElTrainNumber }
        >
          { fullTrainNumber }
        </Text>
        <div className={ styles.actions }>
          <NameErrorsTravelPolicy
            oneTravelPolicy
            hiddenNames
            item={ ticket }
            applyTP={ applyTP }
            travelPolicyList={ travelPolicyList }
            selectedTravelPolicy={ selectedTravelPolicy }
          />
          <LinkAction
            generateLink={ (item) => (SearchId ? onGetLink(item, SearchId) : '') }
            onCopyToClipboard={ onCopyLinkTrip }
            onClick={ onGetLinkTrip }
            item={ ticket }
            className={ `${styles.link} ${CLASSNAMESFORTESTS.TRAINS.GETLINK}` }
          />
          { favoriteHtml }
        </div>
      </div>
    );
  };

  renderERegistrationWarning = () => {
    const { ticket: { ERegistration } } = this.props;

    return !ERegistration && (
      <Text
        className={ styles.warning_er }
        color='red'
        type='NORMAL_14'
      >
        { LABELS.EREGISTRATION_IS_NOT_AVAILABLE }
      </Text>
    );
  };

  render() {
    const {
      ticket,
      ticket: {
        TravelPolicy,
        TrainId,
        FavoriteId,
        Cars,
        NonRefundable,
        ProviderName,
      },
      ind,
      stores: {
        trainSearchStore: { travellers },
        trainTicketsStore: { filters: { selectedTravelPolicy } },
      },
      appService,
    } = this.props;
    const { animationClass } = this.state;

    const { agentMode } = appService.get();

    const classes = Cars.map(({ MinimalCost: { Total, AgentFee }, Type, FreePlaces }) => ({
      Type,
      FreePlaces,
      Price: Total,
      AgentFee,
    }));

    const applyTP = applyTrainTP(TravelPolicy, selectedTravelPolicy);

    const {
      firstElItem,
      firstElPrice,
      firstElButton,
    } = this.getQAAttrs();

    const trainRefundable = NonRefundable && (
      <Text
        type='NORMAL_14'
        color='gray'
        className={ styles.refundable }
      >
        { LABELS.NON_REFUNDABLE }
      </Text>
    );

    return (
      <ItemPanel
        key={ `train_${TrainId}_${ind}` }
        index={ ind }
        warning={ applyTP }
        animationClass={ animationClass }
        className={ CLASSNAMESFORTESTS.TRAINS.ITEM }
        onAnimationEnd={ this.handleAnimationEnd }
        renderHeader={ this.renderHeader }
        favorite={ !!FavoriteId }
        qaAttr={ firstElItem }
      >
        <div className={ styles.wrap }>
          <div className={ styles.info }>
            <div className={ styles.ticket }>
              <TrainSearchItem
                ticket={ ticket }
                qaAttr={ this.getQAAttrs() }
              >
                <FreePlacesDetails
                  classes={ classes }
                  travellers={ travellers }
                  qaAttrPrice={ firstElPrice }
                  agentMode={ agentMode }
                />
              </TrainSearchItem>
            </div>
            <InfoPathTrain className={ styles.path } train={ ticket }/>
            { trainRefundable }
            { this.renderERegistrationWarning() }
            <InfoProviderIM className={ styles.provider } providerName={ ProviderName }/>
          </div>
          <div className={ styles.action }>
            <div className={ styles.button }>
              <Button
                type='primary'
                onClick={ this.handleClick }
                qaAttr={ firstElButton }
              >
                { LABELS.CHOOSE_CAR }
              </Button>
            </div>
          </div>
        </div>
      </ItemPanel>
    );
  }
}

export { TrainTicket };
