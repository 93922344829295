// @ts-nocheck
import PropTypes from 'prop-types';
import React, { createRef } from 'react';
import { observer } from 'mobx-react';
import { Text, Suggest, StyledWrapper, Datepicker, Select, Button, PROPS } from 'new-ui';
import clsx from 'clsx';

import { getText } from '../../../../../i18n';

import {
  AEROEXPRESS_DIRECTION_TYPES,
  AEROEXPRESS_ROUTE_TYPES,
  AEROEXPRESS_SHEREMETYEVO,
  AEROEXPRESS_TARIFF_MAP,
  AEROEXPRESS_TARIFF_TYPES,
} from '../../../../bi/services/aeroexpress/const';

import createDateFromTo from '../../../../bi/utils/createDateFromTo';
import { getMoment, isAfterDate } from '../../../../bi/utils/formatDate';
import { MainAnalytic } from '../../../../bi/utils/analytics';
import { isSmartAgent } from '../../../../bi/utils/env';

import { AnalyticTypeClassAeroExpress } from '../../../../bi/types/aeroExpress';

import styles from './index.module.css';

const LABELS = {
  SUGGEST: getText('aeroexpress:menu.suggest'),
  SEARCH: getText('common:search'),
  DEPARTURE_DATE: getText('aeroexpress:menu.to'),
  BACK_DATE: getText('aeroexpress:menu.back'),
  CHOOSE_SCHEDULE: getText('aeroexpress:menu.selectSchedule'),
  PASSENGERS: getText('aeroexpress:menu.passengers'),
  CLASS: getText('aeroexpress:menu.class'),
  TRIP_TO: getText('aeroexpress:menu.tripTo'),
  TRIP_BACK: getText('aeroexpress:menu.tripBack'),
  NOT_SELECTED: getText('aeroexpress:menu.notSelected'),
  NOT_SELECT: getText('aeroexpress:menu.notSelect'),
  ODINTSOVO: getText('aeroexpress:menu.odintsovo'),
};

const FIELDS = {
  CHECKIN: 'checkin',
  CHECKOUT: 'checkout',
};

const PASSENGERS_COUNT = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
];

export const AEROEXPRESS_CLASSES = [
  { label: AEROEXPRESS_TARIFF_MAP[AEROEXPRESS_TARIFF_TYPES.STANDARD], value: AEROEXPRESS_TARIFF_TYPES.STANDARD, analyticValue: AnalyticTypeClassAeroExpress.standard },
  { label: AEROEXPRESS_TARIFF_MAP[AEROEXPRESS_TARIFF_TYPES.BUSINESS], value: AEROEXPRESS_TARIFF_TYPES.BUSINESS, analyticValue: AnalyticTypeClassAeroExpress.business },
];

@observer
class AeroexpressSearchMenu extends React.Component {
  static propTypes = {
    settings: PropTypes.object.isRequired,
    schedules: PropTypes.object.isRequired,
    isValid: PropTypes.bool.isRequired,
    routes: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onSearch: PropTypes.func.isRequired,
    departureRange: PropTypes.object.isRequired,
    backRange: PropTypes.object.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
    qaAttrs: PropTypes.oneOfType([
      PropTypes.oneOf([null]).isRequired,
      PropTypes.object.isRequired,
    ]),
  };

  static defaultProps = {
    qaAttrs: null,
  };

  constructor() {
    super();

    const currentDate = getMoment();
    const { start, end, minStart } = createDateFromTo(currentDate);

    this.state = {
      checkin: start.set({ hour: 0, minute: 0 }),
      checkout: end.set({ hour: 0, minute: 0 }),
      minFrom: minStart,
      preparedCheckout: null,
      dateValue: true,
    };
  }

  suggest = createRef();

  isSheremetyevoRoute = ({ from: { code: fCode }, to: { code: tCode } }) =>
    fCode === AEROEXPRESS_SHEREMETYEVO || tCode === AEROEXPRESS_SHEREMETYEVO;

  prepareLabel = (data, addTags) => {
    const { from: { name: fName, type: fType }, to: { name: tName, type: tType } } = data;

    const isSheremetyevo = this.isSheremetyevoRoute(data);
    const fromLabel = fType === AEROEXPRESS_ROUTE_TYPES.AIRPORT ? (() => (addTags ? <mark>{ fName }</mark> : fName))() : (() => (isSheremetyevo ? this.prepareSheremetyevoLabel(fName, true) : fName))();
    const toLabel = tType === AEROEXPRESS_ROUTE_TYPES.AIRPORT ? (() => (addTags ? <mark>{ tName }</mark> : tName))() : (() => (isSheremetyevo ? this.prepareSheremetyevoLabel(tName, false) : tName))();

    return { fromLabel, toLabel };
  };

  prepareSheremetyevoLabel = (name, isToAirport) => (isToAirport ? `${LABELS.ODINTSOVO} \u27F6 ${name}` : `${name} \u27F6 ${LABELS.ODINTSOVO}`);

  handleChangeDateAmp = <Fn extends (args: any[]) => void>(func: Fn, ...value: Parameters<Fn>) => {
    func(...value);
    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_AERO_EXPRESS_DATE_CHOSEN(value[1]?.format()),
    );
  };

  handleChangeDate = (field, value) => {
    const { onChange, disableSearchButton } = this.props;

    if (field === FIELDS.CHECKIN) {
      this.setState({
        dateValue: !!value,
      });

      disableSearchButton(!!value);

      if (value) {
        const { end, minStart } = createDateFromTo(value);
        const { checkout, preparedCheckout } = this.state;

        const dateTo = isAfterDate(value, checkout)
          ? end
          : preparedCheckout;

        onChange({ dateDeparture: value });

        if (preparedCheckout) {
          onChange({ dateBack: dateTo || '' });

          return this.setState({
            checkin: value,
            checkout: dateTo,
            preparedCheckout: dateTo,
            minFrom: minStart,
          });
        }

        return this.setState({
          checkin: value,
          minFrom: minStart,
        });
      }
    }

    onChange({ dateBack: value || '' });

    if (field === FIELDS.CHECKOUT) {
      return this.setState({
        preparedCheckout: value,
      });
    }

    return this.setState({
      [field]: value,
    });
  };

  renderSuggest = (data) => {
    const { fromLabel, toLabel } = this.prepareLabel(data, true);

    return <Text>{fromLabel} &#x27F6; {toLabel}</Text>;
  };

  handleSelectChange = (query) => {
    const { onChange, disableSearchButton } = this.props;
    const { dateValue } = this.state;

    onChange({ route: { label: query.trim(), isSelected: false } });
    disableSearchButton(dateValue);
  };

  handleOnRouteSelected = (value) => {
    const { disableSearchButton } = this.props;
    const { dateValue } = this.state;

    if (value) {
      const { onChange } = this.props;
      const { from: { type: fType, code: fCode }, to: { code: tCode } } = value;

      const type = fType === AEROEXPRESS_ROUTE_TYPES.AIRPORT ?
        AEROEXPRESS_DIRECTION_TYPES.FROM_AIRPORT :
        AEROEXPRESS_DIRECTION_TYPES.TO_AIRPORT;
      const code = type === AEROEXPRESS_DIRECTION_TYPES.FROM_AIRPORT ? fCode : tCode;

      const { fromLabel, toLabel } = this.prepareLabel(value);

      onChange({ route: { label: `${fromLabel} \u27F6 ${toLabel}`, isSelected: true, type, code } });
    }

    disableSearchButton(dateValue);

    MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.SEARCH.SEARCH_AERO_EXPRESS_ROUTE_CHOSEN);
  };

  render() {
    const { minFrom, checkin, preparedCheckout } = this.state;
    const {
      settings: { route: { label: rLabel }, count, type, dateDeparture },
      isValid,
      routes,
      departureRange,
      backRange,
      onSearch,
      onChange,
      qaAttrs,
      featureFlagsService: {
        getShowElementForSmartagent,
      },
    } = this.props;

    const colorText = getShowElementForSmartagent() && isSmartAgent ? 'white' : 'default';

    const optionsWrapper = clsx(styles.options, {
      [styles.options_sa]: isSmartAgent && getShowElementForSmartagent(),
    });

    const preparedCheckin = dateDeparture || checkin;

    const classSuggestContent = AEROEXPRESS_CLASSES.length > 1 && (
      <div className={ styles.option }>
        <Text
          type={ PROPS.TEXT.TYPES.NORMAL_14 }
          className={ styles.label }
          color={ colorText }
        >
          { LABELS.CLASS }
        </Text>
        <Select
          theme={ PROPS.SELECT.THEMES.DEFAULT_SMALL }
          items={ AEROEXPRESS_CLASSES }
          placeholder={ LABELS.NOT_SELECT }
          value={ type }
          onChange={ v => onChange({ type: v }) }
          qaAttr={ qaAttrs?.class || '' }
        />
      </div>
    );

    return (
      <StyledWrapper className={ styles.menu_wrap }>
        <div className={ styles.menu }>
          <div className={ `${styles.item} ${styles.suggest}` }>
            <Suggest
              autoFocus
              theme={ PROPS.SUGGEST.THEME.LIGHT }
              value={ rLabel }
              items={ routes }
              placeholder={ LABELS.SUGGEST }
              inputClassName={ styles.suggest_border }
              ref={ this.suggest }
              onSelect={ this.handleOnRouteSelected }
              onChange={ query => this.handleSelectChange(query) }
              renderItem={ this.renderSuggest }
              keyExtractor={ ({ from: { name } }) => name }
              qaAttr={ qaAttrs?.route || '' }
              qaAttrFirstEl={ qaAttrs?.firstResult || '' }
            />
          </div>
          <div className={ `${styles.item} ${styles.date}` }>
            <Datepicker
              closeOnTabOut
              inputTheme={ PROPS.INPUT.THEME.OPEN }
              placeholder={ LABELS.DEPARTURE_DATE }
              withLabel
              value={ preparedCheckin }
              onChange={ value => {
                this.handleChangeDateAmp(this.handleChangeDate, FIELDS.CHECKIN, value);
              } }
              { ...departureRange }
              min={ minFrom }
              wrapperClassName={ styles.wrapper }
              inputClassName={ styles.input }
              isDuration
              durationDates={ [preparedCheckin, preparedCheckout] }
              qaAttr={ qaAttrs?.dates.from || '' }
            />
          </div>
          <div className={ `${styles.item} ${styles.date}` }>
            <Datepicker
              closeOnTabOut
              isCleansing
              inputTheme={ PROPS.INPUT.THEME.OPEN }
              placeholder={ LABELS.BACK_DATE }
              withLabel
              value={ preparedCheckout }
              onChange={ value => {
                this.handleChangeDateAmp(this.handleChangeDate, FIELDS.CHECKOUT, value);
              } }
              { ...backRange }
              min={ checkin }
              wrapperClassName={ styles.wrapper }
              inputClassName={ styles.input }
              isDuration
              durationDates={ [preparedCheckin, preparedCheckout] }
              qaAttr={ qaAttrs?.dates.to || '' }
            />
          </div>
          <div className={ `${styles.item} ${styles.action}` }>
            <Button
              className={ styles.button }
              type={ PROPS.BUTTON.TYPES.LARGE }
              onClick={ onSearch }
              disabled={ !isValid }
              qaAttr={ qaAttrs?.searchButton || '' }
            >
              { LABELS.SEARCH }
            </Button>
          </div>
        </div>
        <div className={ optionsWrapper }>
          <div className={ styles.option }>
            <Text
              type={ PROPS.TEXT.TYPES.NORMAL_14 }
              className={ styles.label }
              color={ colorText }
            >
              { LABELS.PASSENGERS }
            </Text>
            <Select
              className={ styles.passengers }
              theme={ PROPS.SELECT.THEMES.DEFAULT_SMALL }
              items={ PASSENGERS_COUNT }
              value={ count }
              onChange={ v => onChange({ count: v }) }
              qaAttr={ qaAttrs?.passengers || '' }
            />
            { classSuggestContent }
          </div>
        </div>
      </StyledWrapper>
    );
  }
}

export { AeroexpressSearchMenu };
