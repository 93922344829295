import { Moment } from 'moment';
import travelPolicyPopup from '../../../../images/travel-policy-popup.png';

import parseUnix from '../../utils/parseDateTime';
import MainAnalytic from '../../utils/analytics/main';

import { PATTERN } from '../../constants/dateFormats';
import {
  POPUP_POSITIONS,
  POPUP_GROUP_TYPES,
  POPUP_TYPES,
  LABELS,
  POPUPS_KIND,
  POPUP_NAME,
} from '../../constants/popups';
import { MOBILE_APP_BANNER, TAXI_BANNER, SERVICE_QUALITY_POPUP } from '../../constants/elementsIds';
import { QA_ATTRIBUTES } from '../../constants/attributesForTests';

import { AEROEXPRESS_TARIFF_TYPES } from '../aeroexpress/const';

import { PopupsStore, PopupsStoreType } from './store';
import { PopupItem } from './types';

const AIRPORTS_CODES_TO_BE_HANDLED = ['DME', 'SVO'];

const SMARTAGENT_POPUP_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSdjHz5jc0rdR5tKYs3RWs5FtZJOCc5tK7t_jd_uLRSnI_257g/viewform';

interface Model {
  type: string,
  count: number
  dateDeparture: Moment,
  dateBack?: Moment
}

const getDateDependsOfTime = (momentDate: Moment) => {
  const clone = momentDate.clone().set({ h: 3, m: 59 });

  return clone.valueOf() > momentDate.valueOf() ? momentDate.subtract(1, 'd') : momentDate;
};

const prepareAeroexpressSearchModel = (dates: number[], count: number): Model => {
  const sortedDates = dates.sort((a, b) => a - b);

  const model: Model = {
    type: AEROEXPRESS_TARIFF_TYPES.STANDARD,
    count,
    dateDeparture: getDateDependsOfTime(parseUnix(sortedDates[0])),
  };

  if (dates.length > 1) {
    model.dateBack = getDateDependsOfTime(parseUnix(sortedDates[1]));
  }

  return model;
};

const hasChangeInAirportsWithAeroexpress = (routes: any[], hasChange: boolean) => {
  if (hasChange) {
    const firstSegmentDepartureAirport = routes[0].Segments[0].DepartureAirport.ID;

    const lastRoute = routes[routes.length - 1];
    const lastSegmentArrivalAirport = lastRoute.Segments[lastRoute.Segments.length - 1].ArrivalAirport.ID;

    return !AIRPORTS_CODES_TO_BE_HANDLED.includes(firstSegmentDepartureAirport) && !AIRPORTS_CODES_TO_BE_HANDLED.includes(lastSegmentArrivalAirport);
  }

  return false;
};

class PopupsService {
  api: any;
  store: PopupsStoreType;

  constructor(api: any) {
    this.api = api.popups;
    this.store = PopupsStore;
  }

  offerAeroexpressPurchase = (airlineTrip: any, { count, isComplex }: any, onSubmit:(item: Model) => void) => {
    if (isComplex) {
      return null;
    }

    const { Routes } = airlineTrip;

    // @ts-ignore
    const dates = [];
    let hasChange = false;

    // @ts-ignore
    Routes.forEach(({ Segments }) => {
      // @ts-ignore
      Segments.forEach((s) => {
        const { ArrivalAirport, DepartureAirport, ArrivalTime, DepartureTime, ChangeDuration } = s;

        const arrivalIncluded = AIRPORTS_CODES_TO_BE_HANDLED.includes(ArrivalAirport.ID);
        const departureIncluded = AIRPORTS_CODES_TO_BE_HANDLED.includes(DepartureAirport.ID);

        if (!arrivalIncluded && !departureIncluded) {
          return;
        }

        if (ChangeDuration) {
          hasChange = true;
        }

        if (arrivalIncluded) {
          dates.push(ArrivalTime);
        } else {
          dates.push(DepartureTime);
        }
      });
    });

    if (hasChangeInAirportsWithAeroexpress(Routes, hasChange)) {
      return null;
    }

    if (!dates.length) {
      return null;
    }

    // @ts-ignore
    const aeroexpressSearchModel: Model = prepareAeroexpressSearchModel(dates, count);

    const { dateDeparture, dateBack } = aeroexpressSearchModel;

    const datesText = dateBack
      ? `${dateDeparture.format(PATTERN.DAY_OF_MONTH)} - ${dateBack.format(PATTERN.DAY_OF_MONTH)}`
      : dateDeparture.format(PATTERN.DAY_OF_MONTH);

    if (this.store.popups.find(({ type }) => type === POPUP_TYPES.AEROEXPRESS)) {
      return null;
    }

    return this.addPopup({
      title: LABELS.AEROEXPRESS.TITLE,
      description: LABELS.AEROEXPRESS.DESCRIPTION(datesText),
      submit: { action: () => onSubmit(aeroexpressSearchModel), label: LABELS.AEROEXPRESS.SUBMIT },
      cancel: { action: () => {}, label: LABELS.AEROEXPRESS.CANCEL },
      position: POPUP_POSITIONS.TOP_RIGHT,
      show: true,
      group: POPUP_GROUP_TYPES.SEARCH_RESULT,
      type: POPUP_TYPES.AEROEXPRESS,
      qaAttrs: QA_ATTRIBUTES.search.aeroexpress.popup.aeroexpress,
    });
  };

  setShowTravelPopup = (value: boolean) => this.store.setShowTravelPopup(value);

  addTPPopupSecond = (redirectToUnique: () => void) => this.addPopup({
    title: LABELS.TRAVEL_POLICIES.SECOND.TITLE,
    description: LABELS.TRAVEL_POLICIES.SECOND.DESCRIPTION,
    submit: {
      action: () => {
        redirectToUnique();
        MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.TRAVEL_POLICY.PRESSED_TP_POPUP);
      },
      label: LABELS.TRAVEL_POLICIES.SECOND.SUBMIT },
    cancel: {
      action: () => this.setPopupState(POPUPS_KIND.TRAVEL_POLICIES_SECOND_RUN, MainAnalytic.ACTIONS.TRAVEL_POLICY.CLOSED_TP_POPUP),
      label: LABELS.TRAVEL_POLICIES.SECOND.CANCEL,
    },
    position: POPUP_POSITIONS.TOP_RIGHT,
    show: true,
    group: POPUP_GROUP_TYPES.DEFAULT,
    type: POPUP_TYPES.TRAVEL_POLICIES,
    image: travelPolicyPopup,
    qaAttrs: QA_ATTRIBUTES.smartdesk.popup.travelPolicy,
  });

  setShowAnalyticsPopup = (value: boolean) => this.store.setShowAnalyticsPopup(value);

  addAnalyticsPopup = () => {
    this.setShowAnalyticsPopup(true);
  };

  getPopupsState = async () => {
    try {
      const popupsState = await this.api.getStatePopups();

      this.store.setPopupsState(popupsState);
    } catch (e) {
      this.store.setPopupsState({});
    }
  };

  closePopupState = (name: string) => {
    this.api.setStatePopups(name);
    this.store.updatePopoutState(name);
  };

  setPopupState = (name: string, action: string) => {
    this.closePopupState(name);
    MainAnalytic.sendFirebase(action);
  };

  setShowTripPlanPopup = (value: boolean) => this.store.setShowTripPlanPopup(value);

  setShowTripItemPopup = (value: boolean) => this.store.setShowTripItemPopup(value);

  setShowSmartagentPopup = (value: boolean) => this.store.setShowSmartagentPopup(value);

  clearPopups = () => this.store.clearPopup();

  addPopup = (payload: PopupItem) => {
    // you always sacrifice something to reach something
    // here we prevent smartway.today of becoming holiday tree
    const mobileBanner = document.getElementById(MOBILE_APP_BANNER);
    const taxiBanner = document.getElementById(TAXI_BANNER);
    const serviceQualityPopup = document.getElementById(SERVICE_QUALITY_POPUP);
    const skipPopups = payload.type !== POPUP_TYPES.VIP_HALL
      && payload.type !== POPUP_TYPES.VIP_HALL_INFO
      && payload.type !== POPUP_TYPES.ADD_TRIP_ITEM
      && payload.type !== POPUP_TYPES.ADD_TRIP_ITEM_IN_CART
      && payload.type !== POPUP_TYPES.ADD_TRIP_PLAN;

    if ((mobileBanner || taxiBanner || serviceQualityPopup) && skipPopups) {
      return null;
    }

    return this.store.addPopup(payload);
  };

  closePopupsByType = (type: string) => this.store.closePopupsByType(type);

  addVipHallPopup = (onSubmit: () => void) => this.addPopup({
    title: LABELS.VIP_HALLS.TITLE,
    description: LABELS.VIP_HALLS.DESCRIPTION,
    submit: {
      label: LABELS.VIP_HALLS.SUBMIT,
      action: () => {
        this.confirmPostponeShowing(true);
        onSubmit();

        MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.VIP_HALL_BANNERS.AIRTICKETPURCHASE_POPUP_CLOSED, {
          popup: 'yes',
        });
      },
    },
    cancel: {
      label: LABELS.VIP_HALLS.CANCEL,
      action: () => {
        this.confirmPostponeShowing(false);
        this.store.setShowVipHallPopup({ show: false, id: null });

        MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.VIP_HALL_BANNERS.AIRTICKETPURCHASE_POPUP_CLOSED, {
          popup: 'closed',
        });
      },
    },
    position: POPUP_POSITIONS.TOP_RIGHT,
    show: true,
    group: POPUP_GROUP_TYPES.SEARCH_RESULT,
    type: POPUP_TYPES.VIP_HALL,
    qaAttrs: QA_ATTRIBUTES.search.vip.popup,
  });

  addVipHallInfoPopup = (onSubmit: () => void) => this.addPopup({
    title: LABELS.VIP_HALLS_INFO.TITLE,
    description: LABELS.VIP_HALLS_INFO.DESCRIPTION,
    submit: {
      action: () => {
        this.store.setShowVipHallPopup({ show: false, id: null });
        onSubmit();

        MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.VIP_HALL_BANNERS.AIRTRIPDETAIL_LEARNINGPOPUP_CLOSED, {
          learningpopup: 'closed',
        });
      },
      label: LABELS.VIP_HALLS_INFO.SUBMIT },
    cancel: {
      action: () => {
        this.store.setShowVipHallPopup({ show: false, id: null });
        this.closePopupState(POPUP_NAME.VIPHALL_POPUP);

        MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.VIP_HALL_BANNERS.AIRTRIPDETAIL_LEARNINGPOPUP_CLOSED, {
          learningpopup: 'closed',
        });
      },
      label: LABELS.VIP_HALLS_INFO.CANCEL,
    },
    position: POPUP_POSITIONS.TOP_RIGHT,
    show: true,
    group: POPUP_GROUP_TYPES.SEARCH_RESULT,
    type: POPUP_TYPES.VIP_HALL_INFO,
    qaAttrs: QA_ATTRIBUTES.search.vip.popup,
  });

  addAddingOrderPopup = (side: string, closingKey: string) => (
    this.addPopup({
      title: LABELS.ADDING_ORDER.TITLE,
      description: LABELS.ADDING_ORDER.DESCRIPTION,
      submit: {
        action: () => {
          this.closePopupState(closingKey);
          this.setShowTripItemPopup(false);
        },
        label: LABELS.ADDING_ORDER.SUBMIT,
      },
      cancel: {
        action: () => {
          this.closePopupState(closingKey);
          this.setShowTripItemPopup(false);
        },
        label: LABELS.ADDING_ORDER.CANCEL,
      },
      position: side,
      show: true,
      group: POPUP_GROUP_TYPES.DEFAULT,
      type: POPUP_TYPES.ADD_TRIP_ITEM,
      qaAttrs: QA_ATTRIBUTES.smartdesk.popup.addingOrder,
    })
  );

  showErrorPopup = (side: string, closingKey: string) => (
    this.addPopup({
      title: LABELS.ADDING_ORDER.TITLE,
      description: LABELS.SHOW_ERROR,
      submit: {
        action: () => {
          this.closePopupState(closingKey);
          this.setShowTripItemPopup(false);
        },
        label: LABELS.ADDING_ORDER.SUBMIT,
      },
      cancel: {
        action: () => {
          this.closePopupState(closingKey);
          this.setShowTripItemPopup(false);
        },
        label: LABELS.ADDING_ORDER.CANCEL,
      },
      position: side,
      show: true,
      group: POPUP_GROUP_TYPES.DEFAULT,
      type: POPUP_TYPES.ADD_TRIP_ITEM,
      qaAttrs: QA_ATTRIBUTES.smartdesk.popup.addingOrder,
    })
  );

  addTripPlanPopup = (side: string, closingKey: string, onSubmit:() => void) => this.addPopup({
    title: LABELS.ADDING_TRIP_PLAN.TITLE,
    description: LABELS.ADDING_TRIP_PLAN.DESCRIPTION,
    submit: {
      action: () => {
        onSubmit();
        this.closePopupState(closingKey);
        this.setShowTripPlanPopup(false);
      },
      label: LABELS.ADDING_TRIP_PLAN.SUBMIT,
    },
    cancel: {
      action: () => {
        onSubmit();
        this.closePopupState(closingKey);
        this.setShowTripPlanPopup(false);
      },
      label: LABELS.ADDING_TRIP_PLAN.CANCEL,
    },
    position: side,
    show: true,
    group: POPUP_GROUP_TYPES.DEFAULT,
    type: POPUP_TYPES.ADD_TRIP_PLAN,
    qaAttrs: QA_ATTRIBUTES.smartdesk.popup.addingOrder,
  });

  addSmartagentPopup = () => this.addPopup({
    title: LABELS.SMARTAGENT.LABEL,
    description: LABELS.SMARTAGENT.BODY,
    link: SMARTAGENT_POPUP_URL,
    submit: {
      action: () => {
        this.closePopupState(POPUP_NAME.SMARTAGENT);
        this.setShowSmartagentPopup(false);
      },
      label: LABELS.SMARTAGENT.GO,
    },
    cancel: {
      action: () => {
        this.closePopupState(POPUP_NAME.SMARTAGENT);
        this.setShowSmartagentPopup(false);
      },
      label: LABELS.SMARTAGENT.CANCEL,
    },
    position: POPUP_POSITIONS.TOP_RIGHT,
    show: true,
    group: POPUP_GROUP_TYPES.SEARCH_RESULT,
    type: POPUP_TYPES.SMARTAGENT,
    qaAttrs: QA_ATTRIBUTES.smartdesk.popup.smartagent,
  });

  getStateVipHallPopup = async (tripId: number) => {
    try {
      const res = await this.api.getStateVipHallPopup(tripId);
      this.store.setShowVipHallPopup({ show: res, id: tripId });

      return res;
    } catch (e) {
      return false;
    }
  };

  confirmPostponeShowing = (value: boolean) => {
    try {
      this.api.confirmPostponeShowing(value);
    } catch (e) {}
  };
}

export default PopupsService;
