import {
  BUYTRIPSPERSONALRIGHT,
  BUYTRIPSACCOUNTRIGHT,
  FINANCEDOCUMENTSRIGHT,
  APPROVETRIPRIGHT,
  EDITEMPLOYEERIGHT,
  VIEWTRIPSRIGHT,
  EDITACCESSRIGHT,
  NOTIFICATIONRIGHT,
  COMMONRIGHT,
  DOCUMENTS,
  RIGHTSTEXT,
  FIELDRIGHTS,
  SHORTFIELDRIGHTS,
  APPROVE_EXPENSE_REPORTS,
  VIEW_EXPENSE_REPORTS, CREATE_EXPENSE_REPORT, EDIT_BUY_WITH_PERSONAL_FUNDS,
  VIEW_MICE_RIGHT,
} from '../constants/rights';

import {
  IAdditionalPrepared,
  IPreparedRigthsBackend,
  IPreparedRigthsFrontend,
} from '../services/employee/types';

const getExpenseReportsRight = (
  createERUnlimited: boolean,
  createERAfterApprove: boolean,
  createERApprovalScheme: boolean,
) => {
  if (createERUnlimited) return COMMONRIGHT.Unlimited;

  if (createERAfterApprove) return COMMONRIGHT.OnlyAfterApprove;

  if (createERApprovalScheme) return COMMONRIGHT.ApprovalScheme;

  return COMMONRIGHT.Unavailable;
};

export const preparedRightsToFrontend = ({
  BuyTripPersonal,
  BuyTripAccount,
  Approve,
  Finance,
  ApproveExpenseReports,
  EditEmployee,
  ViewTrips,
  EditRights,
  EditTravelPolicies,
  Notifications,
  SendDocuments,
  ViewExpenseReports,
  ViewMiceEvents,
  CreateExpenseReports,
  showReports = true,
  Acquiring,
  BuyInsurance = true,
}: IPreparedRigthsFrontend) => {
  const buyTripAccount = BuyTripAccount !== BUYTRIPSACCOUNTRIGHT.Unavailable;
  const buyTripPersonal = BuyTripPersonal !== BUYTRIPSPERSONALRIGHT.Unavailable;
  const buyTravelPolicy = BuyTripAccount === BUYTRIPSACCOUNTRIGHT.TravelPolicy || BuyTripPersonal === BUYTRIPSPERSONALRIGHT.TravelPolicy;
  const buyApprovalScheme = BuyTripAccount === BUYTRIPSACCOUNTRIGHT.ApprovalScheme || BuyTripPersonal === BUYTRIPSPERSONALRIGHT.ApprovalScheme;

  const showEditTravelPolicy = buyTripAccount || !buyTripPersonal || !buyTravelPolicy;
  const accountVouchers = NOTIFICATIONRIGHT.AccountVouchers;
  const accountInvoices = NOTIFICATIONRIGHT.AccountInvoices;
  const canByVouchersInvoices = Notifications === NOTIFICATIONRIGHT.PersonalVouchersAndInvoices || Notifications === NOTIFICATIONRIGHT.AccountVouchersAndInvoices;

  const editEmployee = EditEmployee === EDITEMPLOYEERIGHT.All;
  const disableEditRights = !editEmployee;
  const editRights = editEmployee && EditRights === EDITACCESSRIGHT.Available;

  const createERApprovalScheme = CreateExpenseReports === CREATE_EXPENSE_REPORT.ApprovalScheme;
  const createERAfterApprove = CreateExpenseReports === CREATE_EXPENSE_REPORT.OnlyAfterApprove;
  const createERUnlimited = CreateExpenseReports === CREATE_EXPENSE_REPORT.Unlimited;

  let canGetVouchers = false;
  let canGetInvoices = false;

  if (Notifications !== NOTIFICATIONRIGHT.Unavailable) {
    canGetVouchers = canByVouchersInvoices || Notifications === NOTIFICATIONRIGHT.PersonalVouchers || Notifications === accountVouchers;
    canGetInvoices = canByVouchersInvoices || Notifications === NOTIFICATIONRIGHT.PersonalInvoices || Notifications === accountInvoices;
  }

  const documents = Notifications === accountInvoices ||
    Notifications === accountVouchers ||
    Notifications === NOTIFICATIONRIGHT.AccountVouchersAndInvoices ? DOCUMENTS.AccountTrips : DOCUMENTS.PersonalTrips;

  const buyUnlimited = BuyTripAccount === BUYTRIPSACCOUNTRIGHT.Unlimited ||
    BuyTripPersonal === BUYTRIPSPERSONALRIGHT.Unlimited ||
    BuyTripPersonal === BUYTRIPSPERSONALRIGHT.Limited; // дабы увести всех пользователей от старых лимитов

  return {
    buyTripPersonal,
    buyTripAccount,
    buyUnlimited,
    buyApprovalScheme,
    disableEditRights,
    editEmployee,
    buyTravelPolicy,
    showEditTravelPolicy,
    canGetVouchers,
    canGetInvoices,
    documents,
    editRights,
    viewMiceEvents: ViewMiceEvents === VIEW_MICE_RIGHT.All,
    disableBuyTripPersonal: buyTripAccount,
    approve: Approve === APPROVETRIPRIGHT.Available,
    finance: Finance === FINANCEDOCUMENTSRIGHT.Available,
    approveExpenseReports: ApproveExpenseReports === APPROVE_EXPENSE_REPORTS.Available,
    showSecondStep: buyTripPersonal || buyTripAccount,
    buyAfterApprove: BuyTripAccount === BUYTRIPSACCOUNTRIGHT.OnlyAfterApprove || BuyTripPersonal === BUYTRIPSPERSONALRIGHT.OnlyAfterApprove,
    editSelf: EditEmployee !== EDITEMPLOYEERIGHT.Undefined,
    viewTrips: ViewTrips === VIEWTRIPSRIGHT.All,
    viewExpenseReports: ViewExpenseReports === VIEW_EXPENSE_REPORTS.All,
    viewSelfTrips: ViewTrips === VIEWTRIPSRIGHT.All || ViewTrips === VIEWTRIPSRIGHT.Personal,
    editTravelPolicies: showEditTravelPolicy ? EditTravelPolicies : false,
    sendDocuments: SendDocuments,
    showSelectDocuments: buyTripAccount || buyTripPersonal,
    showReports,
    createERUnlimited,
    createERAfterApprove,
    createERApprovalScheme,
    buyWithPersonalFunds: Acquiring,
    buyInsurance: BuyInsurance,
  };
};

const right = (
  buyTrip: boolean,
  buyUnlimited: boolean,
  buyAfterApprove: boolean,
  buyTravelPolicy: boolean,
  buyApprovalScheme: boolean,
) => {
  if (!buyTrip) return COMMONRIGHT.Unavailable;

  if (buyUnlimited) return COMMONRIGHT.Unlimited;

  if (buyAfterApprove) return COMMONRIGHT.OnlyAfterApprove;

  if (buyTravelPolicy) return COMMONRIGHT.TravelPolicy;

  if (buyApprovalScheme) return COMMONRIGHT.ApprovalScheme;

  return COMMONRIGHT.Unavailable;
};

export const preparedRightsToBackend = ({
  buyTripPersonal,
  buyTripAccount,
  buyUnlimited,
  buyAfterApprove,
  buyApprovalScheme,
  buyTravelPolicy,
  approve,
  finance,
  approveExpenseReports,
  editEmployee,
  editTravelPolicies,
  viewTrips,
  viewSelfTrips,
  editRights,
  editSelf,
  canGetVouchers,
  canGetInvoices,
  sendDocuments,
  documents,
  viewExpenseReports,
  createERUnlimited,
  createERAfterApprove,
  createERApprovalScheme,
  buyWithPersonalFunds,
  viewMiceEvents,
  buyInsurance,
}: IPreparedRigthsBackend) => {
  let notifications = NOTIFICATIONRIGHT.Unavailable;

  const personal = DOCUMENTS.PersonalTrips;
  const account = DOCUMENTS.AccountTrips;

  let employee = EDITEMPLOYEERIGHT.Undefined;
  let trips = VIEWTRIPSRIGHT.Undefined;

  if (canGetVouchers && canGetInvoices) {
    if (documents === personal) {
      notifications = NOTIFICATIONRIGHT.PersonalVouchersAndInvoices;
    }

    if (documents === account) {
      notifications = NOTIFICATIONRIGHT.AccountVouchersAndInvoices;
    }
  } else if (!canGetVouchers && canGetInvoices) {
    notifications = documents === personal ? NOTIFICATIONRIGHT.PersonalInvoices : NOTIFICATIONRIGHT.AccountInvoices;
  } else if (canGetVouchers) {
    notifications = documents === personal ? NOTIFICATIONRIGHT.PersonalVouchers : NOTIFICATIONRIGHT.AccountVouchers;
  }

  if (editEmployee) {
    employee = EDITEMPLOYEERIGHT.All;
  } else if (editSelf) {
    employee = EDITEMPLOYEERIGHT.Personal;
  }

  if (viewTrips) {
    trips = VIEWTRIPSRIGHT.All;
  } else if (viewSelfTrips) {
    trips = VIEWTRIPSRIGHT.Personal;
  }

  return {
    BuyTripPersonal: right(buyTripPersonal, buyUnlimited, buyAfterApprove, buyTravelPolicy, buyApprovalScheme),
    BuyTripAccount: right(buyTripAccount, buyUnlimited, buyAfterApprove, buyTravelPolicy, buyApprovalScheme),
    Approve: approve ? APPROVETRIPRIGHT.Available : APPROVETRIPRIGHT.Unavailable,
    Finance: finance ? FINANCEDOCUMENTSRIGHT.Available : FINANCEDOCUMENTSRIGHT.Unavailable,
    ApproveExpenseReports: approveExpenseReports ? APPROVE_EXPENSE_REPORTS.Available : APPROVE_EXPENSE_REPORTS.Unavailable,
    EditEmployee: employee,
    ViewTrips: trips,
    ViewExpenseReports: viewExpenseReports ? VIEW_EXPENSE_REPORTS.All : VIEW_EXPENSE_REPORTS.Undefined,
    ViewMiceEvents: viewMiceEvents ? VIEW_MICE_RIGHT.All : VIEW_MICE_RIGHT.Personal,
    EditRights: editRights ? EDITACCESSRIGHT.Available : EDITACCESSRIGHT.Unavailable,
    Notifications: notifications,
    EditTravelPolicies: editTravelPolicies,
    SendDocuments: sendDocuments,
    CreateExpenseReports: getExpenseReportsRight(createERUnlimited, createERAfterApprove, createERApprovalScheme),
    Acquiring: buyWithPersonalFunds,
    BuyInsurance: buyInsurance,
  };
};

const checkDocuments = (
  canGetVouchers: boolean,
  canGetInvoices: boolean,
) => {
  let mainText = '';

  if (canGetVouchers && !canGetInvoices) {
    mainText = RIGHTSTEXT.CANGETVOUCHERS;
  } else if (!canGetVouchers && canGetInvoices) {
    mainText = RIGHTSTEXT.CANGETINVOICES;
  } else if (canGetVouchers) {
    mainText = RIGHTSTEXT.CANGETVOUCHERSANDINVOICES;
  }

  return mainText;
};

export const convertAvailableRightsToText = ({
  buyTripPersonal,
  buyTripAccount,
  buyUnlimited,
  buyAfterApprove,
  buyApprovalScheme,
  buyTravelPolicy,
  buyWithPersonalFunds,
  approve,
  finance,
  editSelf,
  editEmployee,
  editTravelPolicies,
  viewTrips,
  viewSelfTrips,
  editRights,
  canGetVouchers,
  canGetInvoices,
  sendDocuments,
  documents,
  showReports,
  approveExpenseReports,
  viewExpenseReports,
  createERAfterApprove,
  createERUnlimited,
  showExpenseReportApprove,
  viewMiceEvents,
  isSmartAgent,
  buyInsurance,
  isWhiteLabel,
  isDisplayInsurance,
  showPersonalPaymentRights,
}: IPreparedRigthsBackend & IAdditionalPrepared) => {
  const availableRights = [];

  availableRights.push({
    label: RIGHTSTEXT.SEARCHBESTTRIP,
    id: SHORTFIELDRIGHTS.SEARCHBESTTRIP,
  });

  if (buyTripPersonal || buyTripAccount) {
    const textForSmartAgnet = isSmartAgent ? RIGHTSTEXT.FOR_ANOTHERS : RIGHTSTEXT.BUYTRIPACCOUNT;
    const mainText = buyTripAccount ? textForSmartAgnet : RIGHTSTEXT.BUYTRIPPERSONAL;

    let additionalText = '';

    if (!isSmartAgent) {
      if (buyUnlimited) {
        additionalText = RIGHTSTEXT.BUYUNLIMITED;
      } else if (buyAfterApprove) {
        additionalText = RIGHTSTEXT.BUYAFTERAPPROVE;
      } else if (buyTravelPolicy) {
        additionalText = RIGHTSTEXT.BUYTRAVELPOLICY;
      } else if (buyApprovalScheme) {
        additionalText = RIGHTSTEXT.BUY_APPROVAL_SCHEME;
      }
    }

    availableRights.push({
      label: mainText + additionalText,
      id: SHORTFIELDRIGHTS.BUYTRIP,
    });
  }

  if (!isSmartAgent) {
    if (viewTrips) {
      availableRights.push({
        label: RIGHTSTEXT.VIEWTRIPS,
        id: FIELDRIGHTS.VIEWTRIPS,
      });
    } else if (viewSelfTrips) {
      availableRights.splice(1, 0, {
        label: RIGHTSTEXT.VIEWSELFTRIPS,
        id: SHORTFIELDRIGHTS.VIEWSELFTRIPS,
      });
    }
  }

  if (finance && showReports) {
    availableRights.push({
      label: RIGHTSTEXT.FINANCE,
      id: FIELDRIGHTS.FINANCE,
    });
  }

  if (!isSmartAgent && approve) {
    availableRights.push({
      label: RIGHTSTEXT.APPROVE,
      id: FIELDRIGHTS.APPROVE,
    });
  }

  if (!isSmartAgent && !isWhiteLabel && buyInsurance && isDisplayInsurance) {
    availableRights.push({
      label: RIGHTSTEXT.BUY_INSURANCE,
      id: FIELDRIGHTS.BUY_INSURANCE,
    });
  }

  if (!isSmartAgent && approveExpenseReports && showExpenseReportApprove) {
    availableRights.push({
      label: RIGHTSTEXT.APPROVE_EXPENSE_REPORTS,
      id: FIELDRIGHTS.APPROVE_EXPENSE_REPORTS,
    });
  }

  if (!isSmartAgent) {
    const additionText = () => {
      if (createERUnlimited) return RIGHTSTEXT.BUYUNLIMITED;

      if (createERAfterApprove) return RIGHTSTEXT.IN_APPROVE_WITH;

      return RIGHTSTEXT.BUY_APPROVAL_SCHEME;
    };

    availableRights.push({
      label: additionText(),
      id: SHORTFIELDRIGHTS.CREATE_EXPENSE_REPORTS,
    });
  }

  if (editEmployee) {
    availableRights.push({
      label: !isSmartAgent ? RIGHTSTEXT.EDITEMPLOYEE : RIGHTSTEXT.EDITEMPLOYEE_AND_PASSANGERS,
      id: FIELDRIGHTS.EDITEMPLOYEE,
    });
  } else if (editSelf) {
    availableRights.push({
      label: RIGHTSTEXT.EDITSELF,
      id: SHORTFIELDRIGHTS.EDITSELF,
    });
  }

  if (
    buyWithPersonalFunds !== EDIT_BUY_WITH_PERSONAL_FUNDS.Unavailable &&
    buyWithPersonalFunds !== EDIT_BUY_WITH_PERSONAL_FUNDS.Undefined &&
    showPersonalPaymentRights
  ) {
    availableRights.push({
      label: RIGHTSTEXT.BUY_WITH_PERSONAL_FUNDS,
      id: SHORTFIELDRIGHTS.BUY_WITH_PERSONAL_FUNDS,
    });
  }

  if (!isSmartAgent && editTravelPolicies) {
    availableRights.push({
      label: RIGHTSTEXT.EDITTRAVELPOLICIES,
      id: FIELDRIGHTS.EDITTRAVELPOLICIES,
    });
  }

  if (editRights) {
    availableRights.push({
      label: !isSmartAgent ? RIGHTSTEXT.EDITRIGHTS : RIGHTSTEXT.EDITRIGHTS_FOR_AGENTS,
      id: FIELDRIGHTS.EDITRIGHTS,
    });
  }

  if (!isSmartAgent && viewExpenseReports) {
    availableRights.push({
      label: RIGHTSTEXT.VIEW_EXPENSE_REPORTS,
      id: FIELDRIGHTS.VIEW_EXPENSE_REPORTS,
    });
  }

  if (viewMiceEvents && !isSmartAgent) {
    availableRights.push({
      label: RIGHTSTEXT.VIEW_MICE_EVENTS,
      id: SHORTFIELDRIGHTS.VIEW_MICE_EVENT,
    });
  }

  if (canGetVouchers || canGetInvoices) {
    const showInvoices = canGetInvoices && showReports;

    const additionalText = documents === DOCUMENTS.PersonalTrips ? RIGHTSTEXT.PERSONALTRIPS : RIGHTSTEXT.ACCOUNTTRIPS;
    const rightsText = checkDocuments(canGetVouchers, showInvoices);

    if (rightsText) {
      availableRights.push({
        label: rightsText + additionalText,
        id: SHORTFIELDRIGHTS.DOCUMENTS,
      });
    }
  }

  if (sendDocuments && showReports) {
    availableRights.push({
      label: RIGHTSTEXT.SENDDOCUMENTS,
      id: FIELDRIGHTS.SENDDOCUMENTS,
    });
  }

  return availableRights;
};

export const convertUnavailableRightsToText = ({
  buyTripPersonal,
  buyTripAccount,
  buyWithPersonalFunds,
  approve,
  finance,
  editSelf,
  editEmployee,
  editTravelPolicies,
  viewTrips,
  viewSelfTrips,
  editRights,
  canGetVouchers,
  canGetInvoices,
  sendDocuments,
  documents,
  showReports,
  approveExpenseReports,
  viewExpenseReports,
  viewMiceEvents,
  showExpenseReportApprove,
  isSmartAgent,
  buyInsurance,
  isWhiteLabel,
  isDisplayInsurance,
  showPersonalPaymentRights,
}: IPreparedRigthsBackend & IAdditionalPrepared) => {
  const unavailableRights = [];

  if (!buyTripAccount) {
    const notBuyTripText = isSmartAgent ? RIGHTSTEXT.NOT_BUY_TRIP_ACCOUNT_USERS : RIGHTSTEXT.NOTBUYTRIPACCOUNT;
    const text = !buyTripPersonal ? RIGHTSTEXT.NOTBUYTRIP : notBuyTripText;

    unavailableRights.push({
      label: text,
      id: SHORTFIELDRIGHTS.NOTBUYTRIP,
    });
  }

  if (!isSmartAgent) {
    if (!viewTrips && !viewSelfTrips) {
      unavailableRights.push({
        label: RIGHTSTEXT.NOTVIEWTRIPS,
        id: FIELDRIGHTS.VIEWTRIPS,
      });
    } else if (!viewTrips) {
      unavailableRights.push({
        label: RIGHTSTEXT.VIEWTRIPS,
        id: FIELDRIGHTS.VIEWTRIPS,
      });
    }
  }

  if (!finance && showReports) {
    unavailableRights.push({
      label: RIGHTSTEXT.FINANCE,
      id: FIELDRIGHTS.FINANCE,
    });
  }

  if (!isSmartAgent && !approve) {
    unavailableRights.push({
      label: RIGHTSTEXT.APPROVE,
      id: FIELDRIGHTS.APPROVE,
    });
  }

  if (!isSmartAgent && !isWhiteLabel && !buyInsurance && isDisplayInsurance) {
    unavailableRights.push({
      label: RIGHTSTEXT.BUY_INSURANCE,
      id: FIELDRIGHTS.BUY_INSURANCE,
    });
  }

  if (!isSmartAgent && !approveExpenseReports && showExpenseReportApprove) {
    unavailableRights.push({
      label: RIGHTSTEXT.APPROVE_EXPENSE_REPORTS,
      id: FIELDRIGHTS.APPROVE_EXPENSE_REPORTS,
    });
  }

  if (!editEmployee && !editSelf) {
    const noEditSelfText = isSmartAgent ? RIGHTSTEXT.NOT_EDIT_SELF_USERS : RIGHTSTEXT.NOTEDITSELF;

    unavailableRights.push({
      label: noEditSelfText,
      id: SHORTFIELDRIGHTS.EDITSELF,
    });
  } else if (!editEmployee) {
    const noEditText = isSmartAgent ? RIGHTSTEXT.NOT_EDIT_USERS : RIGHTSTEXT.NOTEDITEMPLOYEE;

    unavailableRights.push({
      label: noEditText,
      id: FIELDRIGHTS.EDITEMPLOYEE,
    });
  }

  if (!isSmartAgent && !editTravelPolicies) {
    unavailableRights.push({
      label: RIGHTSTEXT.EDITTRAVELPOLICIES,
      id: FIELDRIGHTS.EDITTRAVELPOLICIES,
    });
  }

  if (!isSmartAgent && !viewExpenseReports) {
    unavailableRights.push({
      label: RIGHTSTEXT.VIEW_EXPENSE_REPORTS,
      id: FIELDRIGHTS.VIEW_EXPENSE_REPORTS,
    });
  }

  if (!editRights) {
    unavailableRights.push({
      label: !isSmartAgent ? RIGHTSTEXT.EDITRIGHTS : RIGHTSTEXT.EDITRIGHTS_FOR_AGENTS,
      id: FIELDRIGHTS.EDITRIGHTS,
    });
  }

  if (!viewMiceEvents && !isSmartAgent) {
    unavailableRights.push({
      label: RIGHTSTEXT.VIEW_MICE_EVENTS,
      id: SHORTFIELDRIGHTS.VIEW_MICE_EVENT,
    });
  }

  if (documents === DOCUMENTS.PersonalTrips && canGetVouchers && canGetInvoices) {
    const showInvoices = canGetInvoices && showReports;

    unavailableRights.push({
      label: checkDocuments(canGetVouchers, showInvoices) + RIGHTSTEXT.NOPERSONALTRIPS,
      id: SHORTFIELDRIGHTS.DOCUMENTS,
    });
  } else {
    const showInvoices = !canGetInvoices && showReports;

    const rightsText = checkDocuments(!canGetVouchers, showInvoices);

    if (rightsText) {
      unavailableRights.push({
        label: rightsText + RIGHTSTEXT.ACCOUNTTRIPS,
        id: SHORTFIELDRIGHTS.DOCUMENTS,
      });
    }
  }

  if (!sendDocuments && showReports) {
    unavailableRights.push({
      label: RIGHTSTEXT.SENDDOCUMENTS,
      id: FIELDRIGHTS.SENDDOCUMENTS,
    });
  }

  if ((
    buyWithPersonalFunds === EDIT_BUY_WITH_PERSONAL_FUNDS.Unavailable ||
    buyWithPersonalFunds === EDIT_BUY_WITH_PERSONAL_FUNDS.Undefined
  ) && showPersonalPaymentRights
  ) {
    unavailableRights.push({
      label: RIGHTSTEXT.BUY_WITH_PERSONAL_FUNDS,
      id: SHORTFIELDRIGHTS.BUY_WITH_PERSONAL_FUNDS,
    });
  }

  return unavailableRights;
};

export const checkDifferencesInRights = (
  source: IPreparedRigthsFrontend,
  current: IPreparedRigthsBackend,
) => {
  if (!source) return true;

  if (!current) return true;

  const newSource = preparedRightsToFrontend(source);

  return current.buyTripPersonal !== newSource.buyTripPersonal ||
    current.buyTripAccount !== newSource.buyTripAccount ||
    current.buyUnlimited !== newSource.buyUnlimited ||
    current.buyTravelPolicy !== newSource.buyTravelPolicy ||
    current.buyAfterApprove !== newSource.buyAfterApprove ||
    current.approve !== newSource.approve ||
    current.finance !== newSource.finance ||
    current.approveExpenseReports !== newSource.approveExpenseReports ||
    current.editEmployee !== newSource.editEmployee ||
    current.editSelf !== newSource.editSelf ||
    current.editRights !== newSource.editRights ||
    current.viewTrips !== newSource.viewTrips ||
    current.viewExpenseReports !== newSource.viewExpenseReports ||
    current.viewSelfTrips !== newSource.viewSelfTrips ||
    current.editTravelPolicies !== newSource.editTravelPolicies ||
    current.canGetVouchers !== newSource.canGetVouchers ||
    current.canGetInvoices !== newSource.canGetInvoices ||
    current.documents !== newSource.documents ||
    current.sendDocuments !== newSource.sendDocuments;
};

export const checkDifferences = (
  source: IPreparedRigthsFrontend,
  current: IPreparedRigthsBackend,
  sourceApprovers: string[],
  currentApprovers: string[],
  sourceTp: string,
  currentTp: string,
) => {
  if (!source && !current) {
    return false;
  }

  const checkRights = checkDifferencesInRights(source, current);

  if (checkRights) return true;

  if ((!sourceApprovers.length && currentApprovers.length) ||
    (sourceApprovers.length && !currentApprovers.length) ||
    (sourceApprovers.length !== currentApprovers.length)
  ) return true;

  const checkApprovers = (sourceApprovers.length || currentApprovers.length) && !sourceApprovers.every(item => currentApprovers.includes(item));

  if (checkApprovers) return true;

  return (!sourceTp.length || currentTp.length) &&
    (sourceTp.length || currentTp.length) &&
    sourceTp !== currentTp;
};
