// @ts-nocheck
import CHECKOUTACTION from './action';
import CheckoutStore from './checkoutStore';

class Checkout {
  constructor(api) {
    this.api = api.cart;
    this.checkoutStore = CheckoutStore();
  }

  get = () => this.checkoutStore.getState();

  clearState = () => this.checkoutStore.dispatch({
    type: CHECKOUTACTION.CHECKOUTCLEARSTATE,
  });

  start = () => this.checkoutStore.dispatch({
    type: CHECKOUTACTION.CHECKOUTLOAD,
  });

  load = (id) => this.api.getCartById(id).then((res) => {
    this.checkoutStore.dispatch({
      type: CHECKOUTACTION.UPDATE,
      payload: res,
    });
    this.checkoutStore.dispatch({
      type: CHECKOUTACTION.VALIDATERATE,
      payload: res,
    });
    this.validateEmployees(id);

    return res;
  });

  validateEmployees = (cartId) => this.api.validateEmployees(cartId)
    .then(payload => this.checkoutStore.dispatch({ type: CHECKOUTACTION.CHECKOUTVALIDATION, payload }))
    .catch(() => this.checkoutStore.dispatch({ type: CHECKOUTACTION.CHECKOUTVALIDATION, payload: null }));

  loadPreCheckout = id => this.api.preCheckout(id).then((res) => {
    this.checkoutStore.dispatch({
      type: CHECKOUTACTION.PREUPDATE,
      payload: res,
    });
  });

  loadDiscount = async id => {
    try {
      const res = await this.api.getDiscount(id);

      this.checkoutStore.dispatch({ type: CHECKOUTACTION.LOADDISCOUNT, payload: res });

      return res;
    } catch (error) {
      this.checkoutStore.dispatch({ type: CHECKOUTACTION.LOADDISCOUNT, payload: [] });

      return [];
    }
  };

  changeAccept = value => this.checkoutStore.dispatch({
    type: CHECKOUTACTION.CHECKOUTUPDATEACCEPT,
    payload: value,
  });

  cancellationInfo = id => this.api.cancellationInfo(id).then((res) => {
    this.checkoutStore.dispatch({
      type: CHECKOUTACTION.CHECKOUTUPDATERULES,
      payload: {
        Id: id,
        Rules: res,
      },
    });
  });

  rename = params => this.api.rename(params).then(() =>
    this.checkoutStore.dispatch({
      type: CHECKOUTACTION.CHECKOUTUPDATENAME,
      payload: params,
    }));

  applyDiscount = value => this.api.applyDiscount(this.get().id, value);

  subscribeCart = cb => this.checkoutStore.subscribe(cb);

  itemsCartReservation = async (idCart) => {
    try {
      this.checkoutStore.dispatch({
        type: CHECKOUTACTION.CHECKOUTLOAD,
      });

      const result = await this.api.itemsCartReservation(idCart);

      this.checkoutStore.dispatch({
        type: CHECKOUTACTION.LOAD_ITEMS_STATUS_RESERVE,
        payload: result,
      });

      await this.load(idCart);
      await this.loadPreCheckout(idCart);
    } catch (error) {
      this.checkoutStore.dispatch({
        type: CHECKOUTACTION.LOAD_ITEMS_STATUS_RESERVE,
        payload: [{ Success: false, ErrorMessage: '' }],
      });

      await this.load(idCart);
    }
  };
}

export default Checkout;
