import React from 'react';

import { Tooltip, Text } from 'new-ui';
import { getText } from '../../../../../../../i18n';

import { TRAIN_TARIFFS } from '../../../../../../bi/constants/train';

import {
  IPlace,
} from '../../../../../../bi/types/train';
import AppService from '../../../../../../bi/services/app';

import styles from './index.module.css';
import MoneyFormat from '../../../../../../bi/utils/money';
import { isSmartAgent } from '../../../../../../bi/utils/env';

const TOOLTIP_MSG = {
  up: getText('trains:carScheme.placePositions.up'),
  bottom: getText('trains:carScheme.placePositions.down'),
  disabled: getText('trains:carScheme.placePositions.disabled'),
  withPets: getText('trains:carScheme.placePositions.withPets'),
  single: getText('trains:carScheme.placePositions.single'),
  bp: getText('trains:carScheme.placePositions.bp'),
  kupek: getText('trains:carScheme.placePositions.kupek'),
  default: '',
};

const TOOLTIP_TYPES = {
  UP: 'up',
  DEFAULT: 'default',
  BOTTOM: 'bottom',
  DOWNTOOLTIP: 'downTooltip',
};

const LABELS = {
  NON_REFUNDABLE_TEXT: getText('trains:carScheme.nonRefundableFare'),
  REFUNDABLE_TEXT: getText('trains:carScheme.refundableFare'),
  BUY_FULL_TEXT: getText('trains:carScheme.entireCompartment'),
  FAMILY_PLACE: getText('trains:carScheme.familyPlace'),
  NOT_AVAILABLE_FAMILY_PLACE: getText('trains:carScheme.notAvailableFamilyPlace'),
};

interface TooltipStyles {
  color: 'default' | 'white';
  theme: 'light' | 'red';
}

interface IPlaceProps {
  appService: AppService,
  place: IPlace,
  onHover: (value: boolean) => void,
  handleClick: (place: IPlace) => void,
  selected: boolean,
  hovered: boolean,
  qaAttr?: string,
  isSapsanFamily: boolean,
  differentTrains: boolean,
}

const Place: React.FC<IPlaceProps> = ({
  place,
  place: {
    buyFullPlaces,
    disabled,
    readOnly,
    type,
    css,
    price,
    nonRefundable,
    number,
    altPrice,
    alwaysDisabled,
    compartDetails,
    altFee,
    agentFee,
  },
  onHover = () => {},
  handleClick = () => {},
  selected,
  hovered = false,
  qaAttr = '',
  isSapsanFamily,
  differentTrains,
  appService,
}) => {
  const handleHoverPlace = (value: boolean) => {
    if (!buyFullPlaces) return null;

    return onHover(value);
  };

  const handleClickPlace = (): void => {
    if (alwaysDisabled) {
      return;
    }

    if (!disabled && !readOnly) {
      handleClick(place);
    }
  };

  const getPlaceText = (): string => {
    if (buyFullPlaces) return LABELS.BUY_FULL_TEXT;

    if (!disabled && isSapsanFamily && alwaysDisabled) {
      return LABELS.NOT_AVAILABLE_FAMILY_PLACE;
    }

    return disabled
      ? TOOLTIP_MSG.disabled
      : TOOLTIP_MSG[type as keyof typeof TOOLTIP_MSG];
  };

  const prepareTooltipStyles = (): TooltipStyles => {
    const isDisabled = (!!disabled || !!readOnly || alwaysDisabled);

    const color: 'default' | 'white' = isDisabled ? 'default' : 'white';

    const theme: 'light' | 'red' = isDisabled ? 'light' : 'red';

    return {
      color,
      theme,
    };
  };

  const tooltipPosition: 'top' | 'bottom' = type === TOOLTIP_TYPES.UP || type === TOOLTIP_TYPES.DEFAULT
    ? 'top'
    : 'bottom';

  const { color, theme } = prepareTooltipStyles();

  const nonRefundableText: string = nonRefundable ? LABELS.NON_REFUNDABLE_TEXT : '';

  const familyCarText: string = (isSapsanFamily && alwaysDisabled) ? LABELS.FAMILY_PLACE : '';

  const compartPrice: string | null = compartDetails
    && compartDetails.compartmentPrice
    ? MoneyFormat.moneyWithDecimal(compartDetails.compartmentPrice, true)
    : null;

  const compartAltPrice: string | null = compartDetails && compartDetails.compartmentAltPrice
    ? MoneyFormat.moneyWithDecimal(compartDetails.compartmentAltPrice, true)
    : null;

  const compartmentPlaceText = (): string | null => {
    if (!compartDetails) {
      return null;
    }

    if (compartDetails.specialTariff === TRAIN_TARIFFS.KUPEK) return TOOLTIP_MSG.kupek;

    if (compartDetails.specialTariff === TRAIN_TARIFFS.SINGLE) {
      return differentTrains ? TOOLTIP_MSG.bp : TOOLTIP_MSG.single;
    }

    return null;
  };

  const renderCompartmentPlace = (): JSX.Element | null => {
    if (!compartDetails) return null;

    return (
      <>
        <Text color={ color } type='NORMAL_14_130'>{compartmentPlaceText()}</Text>
        <Text color={ color } type='NORMAL_14_130'>{ LABELS.NON_REFUNDABLE_TEXT }:</Text>
        <Text color={ color } type='NORMAL_14_130'>{ compartAltPrice }</Text>
        <Text color={ color } type='NORMAL_14_130'>{ LABELS.REFUNDABLE_TEXT }:</Text>
        <Text color={ color } type='NORMAL_14_130'>{ compartPrice }</Text>
      </>
    );
  };

  const renderCompartmentPlaceInTooltip = (): JSX.Element | null => {
    if (!compartDetails || !Object.keys(compartDetails).length) return null;

    return renderCompartmentPlace();
  };

  const wrapClasses = [styles.place];

  if (selected || hovered) {
    wrapClasses.push(styles.selected);
  }

  if (readOnly) {
    wrapClasses.push(styles.read_only);
  }

  const { agentMode } = appService.get();
  const preparedPriceText = isSmartAgent && !agentMode ? Number(agentFee) + Number(price) : price;
  const preparedAltPriceText = isSmartAgent && !agentMode ? Number(altFee) + Number(altPrice) : altPrice;

  const altPriceText: string = (altPrice && !alwaysDisabled)
    ? MoneyFormat.moneyWithDecimal(Number(preparedAltPriceText), true)
    : '';

  const priceText: string = (preparedPriceText && !alwaysDisabled)
    ? MoneyFormat.moneyWithDecimal(preparedPriceText, true)
    : '';

  const noRefund = altPrice && altPrice !== 0
    ? (
      <div className={ `${styles.content} ${styles.content_refund}` }>
        <Text color={ color } type='NORMAL_14_130'>{ getPlaceText() }</Text>
        <Text color={ color } type='NORMAL_14_130'>{ LABELS.NON_REFUNDABLE_TEXT }:</Text>
        <Text color={ color } type='NORMAL_14_130'>{ altPriceText }</Text>
        <Text color={ color } type='NORMAL_14_130'>{ LABELS.REFUNDABLE_TEXT }:</Text>
        <Text color={ color } type='NORMAL_14_130'>{ priceText }</Text>
        { renderCompartmentPlaceInTooltip() }
      </div>
    )
    : (
      <div className={ styles.content }>
        <Text color={ color } type='NORMAL_14_130'>{ priceText }</Text>
        <Text color={ color } type='NORMAL_14_130'>{ getPlaceText() }</Text>
        <Text color={ color } type='NORMAL_14_130'>{ nonRefundableText }</Text>
        <Text color={ color } type='NORMAL_14_130'>{ familyCarText }</Text>
        <Text color={ color } type='NORMAL_14_130'>{ compartmentPlaceText() }</Text>
        <Text color={ color } type='NORMAL_14_130'>{ compartPrice }</Text>
      </div>
    );

  return (
    <div
      className={ wrapClasses.join(' ') }
      style={ { ...css } }
      // @ts-ignore
      disabled={ disabled || alwaysDisabled }
      onClick={ handleClickPlace }
      onMouseEnter={ () => handleHoverPlace(true) }
      onMouseLeave={ () => handleHoverPlace(false) }
      data-qa={ qaAttr }
    >
      <Tooltip
        className={ styles.tooltip }
        theme={ theme }
        position={ tooltipPosition }
        bodyClassName={ styles['body-tooltip'] }
        childrenClassName={ styles.wrapper_number }
        renderContent={ () => noRefund }
        isVisibleOnlyHoverObject
      >
        <Text
          className={ styles.number }
          type='NORMAL_12'
        >
          { number }
        </Text>
      </Tooltip>
    </div>
  );
};

export { Place };
