// @ts-nocheck
import axios from 'axios';
import { stringify } from 'query-string';

import xhr from './xhr';

import CONFIG from '../../../config';
import ROUTES from './routes';

import { IConfirmResponse } from '../types/quickApprove';
import { IAutocompleteEmployeeArgs } from '../services/employee/types';
import { GroupedDepartmentStatus } from '../services/departments/types';
import { IInfoByTripIds } from '../services/notepad/types';
import { IOrderDetailsData } from '../types/order';
import { IParamsAnalytics, IParamsAnalyticsFull } from '../types/analytics';

const CARTTYPE = 'po';
const CLIENTID = 1;
const GRANTTYPES = {
  PASSWORD: 'password',
  UUID: 'uuid',
};
const CLIENT_TYPE = 'Workplace';
export const LOCALSTORAGEKEY = {
  MAIN: 'smartway-state',
  TOKEN: 'smartway-current',
  REFRESH: 'smartway-refresh',
  IAT: 'smartway-iat',
  PROCESS: 'smartway-process',
};

export const logout = () => {
  window.localStorage.removeItem(LOCALSTORAGEKEY.MAIN);
  window.sessionStorage.removeItem(LOCALSTORAGEKEY.MAIN);

  window.localStorage.removeItem(LOCALSTORAGEKEY.TOKEN);
  window.localStorage.removeItem(LOCALSTORAGEKEY.REFRESH);
  window.localStorage.removeItem(LOCALSTORAGEKEY.IAT);
  window.localStorage.removeItem(LOCALSTORAGEKEY.PROCESS);
};

class Api {
  reconnectionTimeout = 3000;
  getTokenTimout = 1000;
  requestReduction = 10;
  currentRequestAttempts = 0;
  requestForRefresh = null;
  exp = 0;

  constructor(opts) {
    let store = window.localStorage.getItem(LOCALSTORAGEKEY.MAIN);

    if (!store) {
      store = window.sessionStorage.getItem(LOCALSTORAGEKEY.MAIN);
      window.sessionStorage.removeItem(LOCALSTORAGEKEY.MAIN);
    }

    try {
      this.setCurrentAuthState = store ? JSON.parse(store) : null;
    } catch (e) {
      this.logout();
      window.location.reload();
    }

    this.opts = {
      ...opts,
    };
  }

  static currentTimestamp = () => Math.ceil(new Date().valueOf() / 1000);

  static getToken = params => xhr.sendJSONWithoutToken(CONFIG.LOGIN, params);

  checkAuthError = err => !!err && !!err.status && (err.status === 401 || err.status === 403);

  tryRedirectOnSetPassword = ({ RegistrationConfirmUrl, Email, Id }) => {
    if (!RegistrationConfirmUrl || !Email || !Id) return null;

    return window.location.replace(`${CONFIG.PROMO_MAIN}/set-password?userId=${Id}&email=${Email}`);
  };

  login = (username, password, quickAuthUuid) => {
    const paramsForRefreshToken = {
      client_id: CLIENTID,
      client_secret: CONFIG.AUTH.CLIENT_SECRET,
    };

    if (quickAuthUuid) {
      paramsForRefreshToken.grant_type = GRANTTYPES.UUID;
      paramsForRefreshToken.uuid = quickAuthUuid;
    } else {
      paramsForRefreshToken.grant_type = GRANTTYPES.PASSWORD;
      paramsForRefreshToken.username = Object.keys(CONFIG.USERSMAPPING).includes(username) ? CONFIG.USERSMAPPING[username] : username;
      paramsForRefreshToken.password = password;
    }

    return Api.getToken(paramsForRefreshToken).then((res) => {
      if (res && res.TimeOutAttempt) return res;

      if (quickAuthUuid) this.tryRedirectOnSetPassword(res);

      this.setCurrentAuthState = res;

      return 200;
    });
  };

  clientSessionLogin = (username, password) => {
    const token = window.localStorage.getItem(LOCALSTORAGEKEY.TOKEN);

    const paramsForRefreshToken = {
      client_id: CLIENTID,
      client_secret: CONFIG.AUTH.CLIENT_SECRET,
      grant_type: GRANTTYPES.PASSWORD,
      username,
      password,
    };

    return Api.getToken(paramsForRefreshToken)
      .then((res) => {
        if (res && res.TimeOutAttempt) return res;

        if (token) {
          this.logout();
        }

        this.setCurrentAuthState = res;

        return 200;
      })
      .catch(() => ({ error: true }));
  };

  logout = () => logout();

  logoutAfterAuthError = () => {
    this.requestForRefresh = null;
    this.logout();
    window.location.reload();
  };

  requestAfterUpdateToken = (request) => {
    if (this.requestForRefresh) {
      return this.requestForRefresh.then(() => request());
    }

    this.requestForRefresh = new Promise((resolve) => {
      const getTokenFn = () => {
        request().then(mainRequestRes => resolve(mainRequestRes)).catch((err) => {
          if (this.checkAuthError(err)) {
            return this.logoutAfterAuthError();
          }

          return window.console.log(err);
        });
      };

      if (this.runProcess) {
        setTimeout(getTokenFn, this.getTokenTimout);
      } else {
        this.runProcess = true;

        Api.getToken(this.paramsForRefreshToken)
          .then((res) => {
            this.runProcess = false;
            this.requestForRefresh = null;
            this.setCurrentAuthState = res;
            getTokenFn();
          })
          .catch((err) => {
            window.console.log(`error connection - ${err.status || err.message || JSON.stringify(err)}`);

            this.runProcess = false;

            if (this.checkAuthError(err)) {
              this.logoutAfterAuthError();
            } else if (this.currentRequestAttempts < 1) {
              this.currentRequestAttempts += 1;
              this.requestForRefresh = null;
              this.requestAfterUpdateToken(request);
            } else {
              this.currentRequestAttempts = 0;
              this.reconnect();
            }
          });
      }
    });

    return this.requestForRefresh;
  };

  reconnect = () => {
    this.currentRequestAttempts += 1;

    if (this.opts.reconnectCb) {
      this.opts.reconnectCb('reconnect', { reconnectAttempt: this.currentRequestAttempts });
    }

    if (this.currentRequestAttempts < 4) {
      Api.getToken(this.paramsForRefreshToken)
        .then((res) => {
          this.setCurrentAuthState = res;
          this.opts.reconnectCb('connect');
          window.location.reload();
        })
        .catch((err) => {
          if (err && err.status === undefined) {
            setTimeout(this.reconnect, this.reconnectionTimeout);
          }
        });
    }
  };

  tryReconnect = () => {
    this.currentRequestAttempts = 0;
    this.reconnect();
  };

  set setCurrentAuthState(state) {
    if (state) {
      window.localStorage.setItem(LOCALSTORAGEKEY.TOKEN, state.access_token);
      window.localStorage.setItem(LOCALSTORAGEKEY.REFRESH, state.refresh_token);
      window.localStorage.setItem(LOCALSTORAGEKEY.IAT, state.expires_in);
    }

    const token = this.currentAccessToken;

    if (token) {
      const payload = this.currentAccessToken.split('.')[1];
      const decodePayload = atob(payload);
      const payloadObj = JSON.parse(decodePayload);

      this.exp = (payloadObj.iat + payloadObj.exp) - this.requestReduction;
    }

    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

    this.currentRequestAttempts = 0;
  }

  set runProcess(process) {
    window.localStorage.setItem(LOCALSTORAGEKEY.PROCESS, process);
  }

  get currentToken() {
    if (Api.currentTimestamp() < this.exp) {
      return this.currentAccessToken;
    }

    return null;
  }

  requestWithTokenWrap = (cb: (token: string) => Promise<any>) => {
    const token = this.currentToken;

    const request = cToken => cb(cToken);

    if (token) {
      return request(token);
    }

    return this.requestAfterUpdateToken(() => request(this.currentToken));
  };

  get currentAccessToken() {
    return window.localStorage.getItem(LOCALSTORAGEKEY.TOKEN);
  }

  get currentRefreshToken() {
    return window.localStorage.getItem(LOCALSTORAGEKEY.REFRESH);
  }

  get runProcess() {
    const value = window.localStorage.getItem(LOCALSTORAGEKEY.PROCESS);

    return value === 'true';
  }

  get paramsForRefreshToken() {
    return {
      grant_type: 'refresh_token',
      client_id: 1,
      client_secret: CONFIG.AUTH.CLIENT_SECRET,
      refresh_token: this.currentRefreshToken,
    };
  }

  get workspace() {
    const self = this;

    return {
      account() {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/account`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/account`));
      },
      getAdmin() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_APPROVAL.GET_ADMIN}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get userSession() {
    const self = this;

    return {
      setDepartmentHead(departmentId: number, employeeId: number) {
        return self.requestWithTokenWrap((token) => xhr.sendJSON(
          token, `${CONFIG.API_CLUSTER}${ROUTES.DEPARTMENT.SET.HEAD(departmentId, employeeId)}`,
        ));
      },
      removeDepartmentHead(departmentId: number) {
        return self.requestWithTokenWrap((token) => xhr.delJSON(
          token, `${CONFIG.API_CLUSTER}${ROUTES.DEPARTMENT.REMOVE.HEAD(departmentId)}`,
        ));
      },
      removeDepartmentEmployees(departmentId: number, ids: number[]) {
        return self.requestWithTokenWrap((token) => xhr.delJSON(
          token, `${CONFIG.API_CLUSTER}${ROUTES.DEPARTMENT.REMOVE.EMPLOYEES(departmentId)}`, ids,
        ));
      },
      setDepartmentName(depId: number, name: string) {
        return self.requestWithTokenWrap((token) => xhr.putJSON(
          token, `${CONFIG.API_CLUSTER}${ROUTES.DEPARTMENT.SET.NAME(depId, name)}`,
        ));
      },
      setDepartmentEmployees(depId: number, ids: number[]) {
        return self.requestWithTokenWrap((token) => xhr.putJSON(
          token, `${CONFIG.API_CLUSTER}${ROUTES.DEPARTMENT.SET.EMPLOYEES(depId)}`, ids,
        ));
      },
      getEnums(data) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/enums`, { keys: data });
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/enums`, { keys: data }));
      },

      getProjects() {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}${ROUTES.PROJECTS.GET}`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}${ROUTES.PROJECTS.GET}`));
      },
      getCitizenship() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.GET_CITIZENSHIP}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getTravelPolicies() {
        const token = self.currentToken;
        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_POLICIES.LOAD}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      saveProject(data) {
        const token = self.currentToken;
        const method = data.Id ? xhr.putJSON : xhr.sendJSON;

        if (token) {
          return method(token, `${CONFIG.API_ROOT}${ROUTES.PROJECTS.SET}`, data);
        }

        return self.requestAfterUpdateToken(() => method(self.currentToken, `${CONFIG.API_ROOT}${ROUTES.PROJECTS.SET}`, data));
      },
      getDepartments() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.DEPARTMENTS.GET}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getDepartmentsByFilter(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.DEPARTMENTS.GET_BY_FILTERS}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getDepartment(id: string | number) {
        return self.requestWithTokenWrap((cToken) =>
          xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.DEPARTMENT.GET(id)}`),
        );
      },
      getDepartmentsCompanies() {
        const token = self.currentToken;
        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.DEPARTMENTS.GET_DEPARTMENTS_COMPANIES}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getDepartmentsCount(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.DEPARTMENT.DEPARTMENTS_COUNT}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      saveDepartment(data) {
        const token = self.currentToken;
        const method = data.Id !== null ? xhr.putJSON : xhr.sendJSON;
        const request = cToken => method(cToken, `${CONFIG.API_CLUSTER}${ROUTES.DEPARTMENTS.SET}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      deleteDepartment(id) {
        const token = self.currentToken;
        const request = cToken => xhr.delJSON(cToken, `${CONFIG.API_CLUSTER}/account-service/departments/${id}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getRoles() {
        const token = self.currentToken;
        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.ROLES.GET}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getRolesForExpenseReportSchemes() {
        const token = self.currentToken;
        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.ROLES.GET}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getAccountAdmins() {
        const token = self.currentToken;

        const request = currentToken => xhr.getJSON(currentToken, `${CONFIG.API_ROOT}${ROUTES.ACCOUNT_ADMINS.GET}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getProjectHead(id) {
        const token = self.currentToken;

        const request = currentToken => xhr.getJSON(currentToken, `${CONFIG.API_ROOT}/projects/${id}/responsible`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getDepartmentHead(id) {
        const token = self.currentToken;

        const request = currentToken => xhr.getJSON(currentToken, `${CONFIG.API_ROOT}/departments/${id}/head`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getGroupedDepartments(status: GroupedDepartmentStatus) {
        const query = stringify({ status: status === -1 ? null : status }, { skipNull: true });

        return self.requestWithTokenWrap((token) => xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.DEPARTMENTS.GET_GROUPED_DEPARTMENTS(query)}`));
      },
    };
  }

  get header() {
    const self = this;

    return {
      get() {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_CLUSTER}/account-service/account/header`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_CLUSTER}/account-service/account/header`));
      },
    };
  }

  get agentMode() {
    const self = this;

    return {
      getAgentMode() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}/access-settings/users/agent-mode`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      updateAgentMode(value) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}/access-settings/users/agent-mode?enable=${value}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get airline() {
    const self = this;

    return {
      search(params) {
        const token = self.currentToken;
        const request = currentToken => xhr.sendJSON(currentToken, `${CONFIG.API_PROXY}${ROUTES.AIRLINES.MULTISEARCH}`, params);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      autocomplete(params) {
        const token = self.currentToken;
        const request = currentToken => xhr.getJSON(currentToken, `${CONFIG.API_CLUSTER}${ROUTES.AIRLINES.AUTOCOMPLETE}/${params.query}`);

        if (token) return request(token);

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getSearchHistory() {
        const token = self.currentToken;
        const request = currentToken => xhr.getJSON(currentToken, `${CONFIG.API_PROXY}${ROUTES.AIRLINES.HISTORY}`);

        if (token) return request(token);

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      addToCart(tickets) {
        const token = self.currentToken;
        const request = currentToken => xhr.sendJSON(currentToken, `${CONFIG.API_ROOT}${ROUTES.AIRLINES.TO_CART}`, tickets);

        if (token) return request(token);

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      addToNote(tickets) {
        const token = self.currentToken;
        const request = currentToken => xhr.sendJSON(currentToken, `${CONFIG.API_ROOT}${ROUTES.AIRLINES.TO_NOTE}`, tickets);

        if (token) return request(token);

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      addToFavorite(ticket) {
        const token = self.currentToken;
        const request = currentToken => xhr.sendJSON(currentToken, `${CONFIG.API_PROXY}${ROUTES.AIRLINES.TO_FAVORITE}`, ticket);

        if (token) return request(token);

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getSearchByGuid(guid) {
        const token = self.currentToken;
        const request = currentToken => xhr.getJSON(currentToken, `${CONFIG.API_PROXY}${ROUTES.AIRLINES.MULTISEARCH_BY_ID(guid)}`);

        if (token) return request(token);

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getRegionByAirportId(airportId) {
        const token = self.currentToken;
        const request = currentToken => xhr.getJSON(currentToken, `${CONFIG.API_PROXY}${ROUTES.AIRLINES.REGION_BY_AIRPORT_ID(airportId)}`);

        if (token) return request(token);

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getWarningTemplate() {
        const token = self.currentToken;
        const request = currentToken => xhr.getJSON(currentToken, `${CONFIG.API_CLUSTER}${ROUTES.AIRLINES.WARNING_TEMPLATE}`);

        if (token) return request(token);

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get hotel() {
    const self = this;

    return {
      search(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendFullJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.START_REGION_SEARCH}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },

      radiusSearch(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.RADIUS_SEARCH}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },

      getStatic(id) {
        const token = self.currentToken;
        const req = (tkn) => xhr.getJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.STATIC}/${id}`);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },

      getStaticForce(id) {
        const token = self.currentToken;
        const req = (tkn) => xhr.getJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.STATIC_FORCE(id)}`);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },

      autocomplete(query) {
        const token = self.currentToken;
        const req = (tkn) => xhr.getJSON(tkn, `${CONFIG.API_CLUSTER}/autocomplete-hotels/${query}`);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },

      saveAutocompleteStats(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.STATS.AUTOCOMPLETE}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },

      saveHotelsStats(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.STATS.ANALYTICS}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },

      getSearchHistory() {
        const token = self.currentToken;
        const req = (tkn) => xhr.getJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.HISTORY}`);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },

      getTemplateWarning() {
        const token = self.currentToken;
        const req = (tkn) => xhr.getJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.TEMPLATE_WARNING}`);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },

      loadByGuid(guid) {
        const token = self.currentToken;
        const req = (tkn) => xhr.getFullJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.LOAD_BY_GUID(guid)}`);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },

      newSearchLoadByGuid(dataSearch) {
        const { guid, data } = dataSearch;

        const token = self.currentToken;
        const req = (tkn) => xhr.sendFullJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.LOAD_BY_GUID(guid)}`, data);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },

      loadHotelRate(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendFullJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.RATES}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getHotelById(hotelId, params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.getJSON(tkn, `${CONFIG.API_ROOT}/hotels/${hotelId}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      addToCart(code, count, searchId, requestItemId = null, tripId) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_ROOT}/hotels/${code}/tocart`, {
          TripId: tripId || null,
          SearchId: searchId,
          RoomCount: count || 1,
          RequestItemId: requestItemId,
        });

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      addToNote(code, count, searchId, TripId) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_ROOT}/hotels/${code}/tonote`, {
          SearchId: searchId,
          RoomCount: count || 1,
          TripId,
        });

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      addToFavorite(hotel) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}/hotel-service/favorite`, hotel);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      requestContract(action, guid) {
        return xhr.putJSONWithoutToken(`${CONFIG.API_CLUSTER}${ROUTES.HOTELS.REQUEST_CONTRACT(action, guid)}`);
      },
      getCountReserved(accountId: number) {
        const token = self.currentToken;
        const req = (tkn) => xhr.getJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.COUNT_RESERVED(accountId)}`);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      downloadNoteVoucher(noteId: number, locale: string) {
        const token = self.currentToken;
        const req = (tkn) => xhr.getJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.DOWNLOAD_VOUCHER(noteId, locale)}`);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
    };
  }

  get train() {
    const self = this;

    return {
      search(params) {
        const token = self.currentToken;

        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.TRAINS.SEARCH}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      searchTransfers(params) {
        const token = self.currentToken;

        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.TRAINS.SEARCH_TRANSFERS}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      autocomplete(params) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRAINS.AUTOCOMPLETE}/${params}`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAINS.AUTOCOMPLETE}/${params}`));
      },
      getSearchHistory() {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/trains/history`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/trains/history`));
      },

      saveCarsWithoutSchemaStats(params) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRAINS.STATS.ANALYTICS.CARWITHOUTSCHEMA}`, params);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAINS.STATS.ANALYTICS.CARWITHOUTSCHEMA}`, params));
      },

      getTrainById(trainId, searchId) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/trains/${searchId}/${trainId}/places/v2`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/trains/${searchId}/${trainId}/places/v2`));
      },
      getCarPlacesDetails(trainId, searchId, carNumber, carId) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/trains/${searchId}/${trainId}/places/details/${carNumber}/${carId}`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/trains/${searchId}/${trainId}/places/details/${carNumber}/${carId}`));
      },
      getCarPlacesSchema(trainNumber, currentLng, params) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAINS.GET_CAR_SCHEMA(trainNumber, currentLng)}`, params);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      addToCart(params) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}/trains/tocart`, params);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}/trains/tocart`, params));
      },
      addToFavorite(train) {
        const token = self.currentToken;
        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}/train-service/favorite`, train);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      addToNote(params) {
        const token = self.currentToken;

        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_ROOT}/trains/tonote`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getTicketAvailability(params) {
        const token = self.currentToken;

        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_ROOT}/trains/availability`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getCarDescription(params) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRAINS.CARDESCRIPTION}`, params);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAINS.CARDESCRIPTION}`, params));
      },
      getWarningTemplate() {
        const token = self.currentToken;

        const request = currentToken => xhr.getJSON(currentToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAINS.WARNING_TEMPLATE}`);

        if (token) return request(token);

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get transfer() {
    const self = this;

    return {
      search(params) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRANSFERS.SEARCH}`, params);

        if (token) return request(token);

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getSearchByGuid(guid) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRANSFERS.SEARCH_BY_GUID(guid)}`);

        if (token) return request(token);

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      autocomplete(query) {
        const token = self.currentToken;
        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRANSFERS.AUTOCOMPLETE(query)}`);

        if (token) return request(token);

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getSearchHistory() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRANSFERS.HISTORY}`);

        if (token) return request(token);

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      getTextWarning() {
        const token = self.currentToken;
        const req = (tkn) => xhr.getJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.TRANSFERS.GET_TEXT_WARNING}`);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },

      addToNote(searchId, transferId, data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.TRANSFERS.TO_NOTE(searchId, transferId)}`, data);

        if (token) return request(token);

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      addToCart(searchId, rateId, TripId) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.TRANSFERS.TO_CART(searchId, rateId)}`, TripId);

        if (token) return request(token);

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      addToFavorite(transfer) {
        const token = self.currentToken;
        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}/transfer-service/favorite`, transfer);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get vipHall() {
    const self = this;

    return {
      search(params) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.VIP_HALLS.SEARCH}`, params);

        if (token) return request(token);

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      getInfoVipHall(params) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.VIP_HALLS.INFO(params)}`);

        if (token) return request(token);

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      getAvailableAirports() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.VIP_HALLS.AVAILABLE_AIRPORTS}`);

        if (token) return request(token);

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      getTextWarning() {
        const token = self.currentToken;
        const req = (tkn) => xhr.getJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.VIP_HALLS.GET_TEXT_WARNING}`);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },

      updateCost(params) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.VIP_HALLS.CALCULATE_PRICE}`, params);

        if (token) return request(token);

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      recalculatePrice(params) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.VIP_HALLS.RECALCULATE_PRICE}`, params);

        if (token) return request(token);

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      confirmPaymentInMicroserviceWithProjectId(projectId, tripItemId, params) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.VIP_HALLS.PAYMENT_PROJECT_MICROSERVICE(tripItemId, projectId)}`, params);

        if (token) return request(token);

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      confirmPaymentInMicroservice(tripItemId, params) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.VIP_HALLS.PAYMENT_MICROSERVICE(tripItemId)}`, params);

        if (token) return request(token);

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get event() {
    const self = this;

    return {
      getEvents(data) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.EVENT.GET_EVENTS}`, data);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}${ROUTES.EVENT.GET_EVENTS}`, data));
      },
      getEvent(id) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.EVENT.GET_EVENT(id)}`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}${ROUTES.EVENT.GET_EVENT(id)}`));
      },
      createEvent(data) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.EVENT.CREATE_EVENT}`, data);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}${ROUTES.EVENT.CREATE_EVENT}`, data));
      },
      renameEvent(data) {
        const token = self.currentToken;

        if (token) {
          return xhr.putJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.EVENT.RENAME_EVENT}`, data);
        }

        return self.requestAfterUpdateToken(() => xhr.putJSON(self.currentToken, `${CONFIG.API_ROOT}${ROUTES.EVENT.RENAME_EVENT}`, data));
      },
      linkApplication(data) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.EVENT.LINK_APPLICATION}`, data);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}${ROUTES.EVENT.LINK_APPLICATION}`, data));
      },
    };
  }

  get cart() {
    const self = this;

    return {
      get() {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_CLUSTER}/cart-service/cart-wp`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/cart-service/cart-wp`));
      },
      loadSuggestedRequests(id) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.CART.GET_SUGGESTED_REQUESTS(id)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      bookFlightCertificateFromCart(tripItemId, isBook) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}/airline/${tripItemId}/flight-certificate?flightCertificate=${isBook}`);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}/airline/${tripItemId}/flight-certificate?flightCertificate=${isBook}`));
      },
      getCartById(id) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.CART.GET_CART_BY_ID(id)}`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/cart/${id}`));
      },
      getItem(id) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.CART.GET_CART_ITEM(id)}`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/cart/${id}`));
      },
      getInfoByTripIds(data: number[]): Promise<IInfoByTripIds> {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.INFO_BY_TRIPS_IDS}`, data);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}${ROUTES.TRIP.INFO_BY_TRIPS_IDS}`, data));
      },
      employeeToCart(id, employeeId) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}/cart/${id}/employees/${employeeId}`);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}/cart/${id}/employees/${employeeId}`));
      },
      setEmployeeInCartItem(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.CART.SET_EMPLOYEE}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      employeeCompanyToCart(id, employeeId, body) {
        const token = self.currentToken;

        const request = cToken => xhr.putJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.CART.EMPLOYEE_COMPANY_TO_CART(id, employeeId)}`, body);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      employeeProjectToCart(id, employeeId, projectId) {
        const token = self.currentToken;

        const request = cToken => xhr.putJSON(cToken, `${CONFIG.API_CLUSTER}/cart-service/cart-wp/item/${id}/employee/${employeeId}/project`, projectId);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      removeEmployeeToCart(cartItemIds, employeeId) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}/cart-service/cart-wp/items/employee/${employeeId}/delete`, cartItemIds);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      changePassport(shoppingCartItemId, employeeId, passportId) {
        const token = self.currentToken;

        if (token) {
          return xhr.putJSON(token, `${CONFIG.API_ROOT}/cart/${shoppingCartItemId}/employees/${employeeId}/documents/${passportId}`);
        }

        return self.requestAfterUpdateToken(() => xhr.putJSON(self.currentToken, `${CONFIG.API_ROOT}/cart/${shoppingCartItemId}/employees/${employeeId}/documents/${passportId}`));
      },
      updateReason(itemId, reason) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}/item/${itemId}/message`, reason);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}/item/${itemId}/message`, reason));
      },
      updateTransfer(id, data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.CART.UPDATE_CART_ITEM(id)}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      setRequestForItem(data) {
        const token = self.currentToken;

        const request = cToken => xhr.putJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.CART.SET_REQUEST_FOR_ITEM(data)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      unsetRequestForItem(data) {
        const token = self.currentToken;

        const request = cToken => xhr.delJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.CART.UNSET_REQUEST_FOR_ITEM(data)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      setAdditionalFeeSmartagentCart(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.CART.SET_FEE_SMARTAGENT}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getRecommendedTime(params) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRANSFERS.RECOMMENDED_TIME}`, params);

        if (token) return request(token);

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getCheckTime(params) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRANSFERS.CHECK_TIME}`, params);

        if (token) return request(token);

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      clear(id) {
        const token = self.currentToken;

        if (token) {
          return xhr.delJSON(token, `${CONFIG.API_ROOT}/cart/${id}/clear`);
        }

        return self.requestAfterUpdateToken(() => xhr.delJSON(self.currentToken, `${CONFIG.API_ROOT}/cart/${id}/clear`));
      },
      remove(id, airToNote = false) {
        const token = self.currentToken;

        if (token) {
          return xhr.delJSON(token, `${CONFIG.API_ROOT}/cart/${id}/PO?airToNote=${airToNote}`);
        }

        return self.requestAfterUpdateToken(() => xhr.delJSON(self.currentToken, `${CONFIG.API_ROOT}/cart/${id}/PO`));
      },
      sendToApprove(data) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}/approve`, data);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}/approve`, data));
      },
      sendToOfflineChat(cartId) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.CART.SEND_TO_OFFLINE_CHAT(cartId)}`);

        if (token) return request(token);

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      sendToApproveRequest(data, requestId) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}${ROUTES.TRAVEL_APPROVAL.APPROVE_REQUEST}/${requestId}`, data);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}${ROUTES.TRAVEL_APPROVAL.APPROVE_REQUEST}/${requestId}`, data));
      },
      decideApprove(data, approveId) {
        const token = self.currentToken;

        if (token) {
          return xhr.putJSON(token, `${CONFIG.API_ROOT}/approve/${approveId}`, data);
        }

        return self.requestAfterUpdateToken(() => xhr.putJSON(self.currentToken, `${CONFIG.API_ROOT}/approve/${approveId}`, data));
      },
      waitingApproveList() {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/approve`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/approve`));
      },
      removeCartItem(id) {
        const token = self.currentToken;

        if (token) {
          return xhr.delJSON(token, `${CONFIG.API_ROOT}/cart/item/${id}`);
        }

        return self.requestAfterUpdateToken(() => xhr.delJSON(self.currentToken, `${CONFIG.API_ROOT}/cart/item/${id}`));
      },
      moveToNotepad(item) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}/cart/${item.Id}/tonote`);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}/cart/${item.Id}/tonote`));
      },
      rename({ id, name }) {
        const token = self.currentToken;

        if (token) {
          return xhr.putJSON(token, `${CONFIG.API_ROOT}/cart/${id}/name`, `"${name}"`);
        }

        return self.requestAfterUpdateToken(() => xhr.putJSON(self.currentToken, `${CONFIG.API_ROOT}/cart/${id}/name`, `"${name}"`));
      },
      preCheckout(id) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/cart/${id}/status`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/cart/${id}/status`));
      },
      checkout(data) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}/cart/status`, data);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}/cart/status`, data));
      },
      getDiscount(cartId) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.CART.GET_DISCOUNT(cartId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      applyDiscount(cartId, apply) {
        const token = self.currentToken;

        const request = cToken => xhr.putJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.CART.APPLY_DISCOUNT(cartId, apply)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      itemsCartReservation(cartId) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_ROOT}/v2/booking/${cartId}/reserve`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      checkVerificationCode(data) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}/verification-code`, data);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}/verification-code`, data));
      },
      saveVerificationCode(id, code) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}/cart/${id}/code`, `"${code}"`);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}/cart/${id}/code`, `"${code}"`));
      },
      validateEmployees(cartId) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/cart/${cartId}/Validate`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/cart/${cartId}/Validate`));
      },
      cancellationInfo(id) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/cart/${id}/CancellationInfo`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/cart/${id}/CancellationInfo`));
      },
      continueBookingPaymentMethods(cartId) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.BOOKING.CONTINUE(cartId, CARTTYPE)}`);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.BOOKING.CONTINUE(cartId, CARTTYPE)}`));
      },
      cancelBookingPaymentMethods(cartId) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.BOOKING.CANCEL(cartId, CARTTYPE)}`);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.BOOKING.CANCEL(cartId, CARTTYPE)}`));
      },
      getBookingStatusPaymentMethods(bookId) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.BOOKING.STATUS(bookId)}`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_CLUSTER}${ROUTES.BOOKING.STATUS(bookId)}`));
      },
      startBookingPaymentMethods(cartId, paymentMethod) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.BOOKING.START(cartId, CARTTYPE, paymentMethod)}`);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.BOOKING.START(cartId, CARTTYPE, paymentMethod)}`));
      },
      applyChanges(cartId, itemId, apply = false) {
        const token = self.currentToken;

        if (token) {
          return xhr.putJSON(token, `${CONFIG.API_ROOT}/v2/booking/${cartId}/items/${itemId}/${apply}`);
        }

        return self.requestAfterUpdateToken(() => xhr.putJSON(token, `${CONFIG.API_ROOT}/v2/booking/${cartId}/items/${itemId}/${apply}`));
      },
      applyChangesHotel(cartItemId, rateId, bookingId) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_ROOT}/v2/booking/hotels/${cartItemId}/${rateId}/${bookingId}/update`);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      loadAnalogRooms(rateId) {
        const token = self.currentToken;
        const req = (tkn) => xhr.getJSON(tkn, `${CONFIG.API_CLUSTER}/hotel-service/analog/${rateId}`);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      sendUnderageCart(cartId) {
        const token = self.currentToken;
        const request = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_ROOT}${ROUTES.CART.SEND_UNDERAGE(cartId)}`, { CartType: CARTTYPE });

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      reserveItem(itemId) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}/cart/${itemId}/reserve`);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}/cart/${itemId}/reserve`));
      },
      deleteAddedTrip(cartItemId) {
        const token = self.currentToken;

        if (token) {
          return xhr.delJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.CART.CLEAR_ADDING_TRIP(cartItemId)}`);
        }

        return self.requestAfterUpdateToken(() => xhr.delJSON(self.currentToken, `${CONFIG.API_CLUSTER}${ROUTES.CART.CLEAR_ADDING_TRIP(cartItemId)}`));
      },
      getTripsToAddOrder(data) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.INFO_TO_ADD_ORDER}`, data);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.INFO_TO_ADD_ORDER}`, data));
      },
      addingTripInCart(data) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.CART.ADDING_TRIP}`, data);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_CLUSTER}${ROUTES.CART.ADDING_TRIP}`, data));
      },
      setCustomAnalytics(cartId, analyticsValueId) {
        const token = self.currentToken;

        const request = cToken => xhr.putJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.CART.SET_ANALYTICS(cartId)}`, analyticsValueId);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      unsetCustomAnalytics(cartId, analyticsId) {
        const token = self.currentToken;

        const request = cToken => xhr.delJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.CART.UNSET_ANALYTICS(cartId, analyticsId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      setCustomAnalyticsForItem(cartItemId, analyticsValueId) {
        const token = self.currentToken;

        const request = cToken => xhr.putJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.CART.SET_ANALYTICS_FOR_ITEM(cartItemId)}`, analyticsValueId);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      unsetCustomAnalyticsForItem(cartItemId, analyticsId) {
        const token = self.currentToken;

        const request = cToken => xhr.delJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.CART.UNSET_ANALYTICS_FOR_ITEM(cartItemId, analyticsId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get notepad() {
    const self = this;

    return {
      add(item) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}/note/Add`, item);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}/note/Add`, item));
      },
      get(isSmartDesk) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}${ROUTES.NOTEPAD.GET(isSmartDesk)}`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}${ROUTES.NOTEPAD.GET(isSmartDesk)}`));
      },
      remove(ids) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}/note/remove?ids=[${ids.join(',')}]`);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}/note/remove?ids=[${ids.join(',')}]`));
      },
      removeAll(id) {
        return this.remove(id);
      },
      moveToCart(ids) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}/note/tocart?ids=[${ids.join(',')}]`);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}/note/tocart?ids=[${ids.join(',')}]`));
      },
      send(data) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}/note/send`, data);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}/note/send`, data));
      },
      cancelBooking(id) {
        const token = self.currentToken;

        const req = (t) => xhr.sendJSON(t, `${CONFIG.API_ROOT}/note/${id}/cancel`);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getUserIdentity(data: { UserName: string, AccountId: string }) {
        const token = self.currentToken;

        const userIdentity = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.NOTEPAD.GET_LOGIN}`, data);

        if (token) {
          return userIdentity(token);
        }

        return self.requestAfterUpdateToken(() => userIdentity(self.currentToken));
      },
      loadBooking(id: number) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/note/${id}`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/note/${id}`));
      },
      addEmployee(id: number, employeeId: number) {
        const token = self.currentToken;

        if (token) {
          return xhr.putJSON(token, `${CONFIG.API_ROOT}/note/${id}/employee/${employeeId}`);
        }

        return self.requestAfterUpdateToken(() => xhr.putJSON(self.currentToken, `${CONFIG.API_ROOT}/note/${id}/employee/${employeeId}`));
      },
      addEmployeeDocument(id: number, employeeId: number, documentId: number) {
        const token = self.currentToken;

        if (token) {
          return xhr.putJSON(token, `${CONFIG.API_ROOT}/note/${id}/employee/${employeeId}/documents/${documentId}`);
        }

        return self.requestAfterUpdateToken(() => xhr.putJSON(self.currentToken, `${CONFIG.API_ROOT}/note/${id}/employee/${employeeId}/documents/${documentId}`));
      },
      addEmployeeCompany(id: number, employeeId: number, body) {
        const token = self.currentToken;

        const request = cToken => xhr.putJSON(cToken, `${CONFIG.API_ROOT}/note/${id}/employee/${employeeId}/company`, body);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      booking(id: number) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}/note/${id}/book`);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}/note/${id}/book`));
      },
      removeEmployee(id: number, employeeId: number) {
        const token = self.currentToken;

        if (token) {
          return xhr.delJSON(token, `${CONFIG.API_ROOT}/note/${id}/employee/${employeeId}`);
        }

        return self.requestAfterUpdateToken(() => xhr.delJSON(self.currentToken, `${CONFIG.API_ROOT}/note/${id}/employee/${employeeId}`));
      },
      employeeProjectToNote(id: number, employeeId: number, projectId: number) {
        const token = self.currentToken;
        const request = currentToken => xhr.putJSON(currentToken, `${CONFIG.API_ROOT}/note/${id}/employees/${employeeId}/projects/${projectId}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(request(self.currentToken));
      },
      delEmployeeProjectFromNote(id: number, employeeId: number) {
        const token = self.currentToken;
        const request = currentToken => xhr.delJSON(currentToken, `${CONFIG.API_ROOT}/note/${id}/employees/${employeeId}/projects`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(request(self.currentToken));
      },
      cancellationInfo(id: number) {
        const token = self.currentToken;
        const request = currentToken => xhr.getJSON(currentToken, `${CONFIG.API_ROOT}${ROUTES.NOTEPAD.GET_CANCELLATION_INFO(id)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      setAdditionalFeeSmartagentNotepad(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.NOTEPAD.SET_FEE_SMARTAGENT}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get metrics() {
    const self = this;

    return {
      sendMetric(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.MERTICS.SEND}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      sendHotelsMetric(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.MERTICS.SEND_HOTELS}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get payment() {
    const self = this;

    return {
      sendResult(companyId, data) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}${ROUTES.PAYMENTS.CARD}`, data);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}/companies/${companyId}${ROUTES.PAYMENTS.CARD}`, data));
      },
    };
  }

  get employee() {
    const self = this;

    return {
      get() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.EMPLOYEES.GET}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getOptimizedList() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_PROXY}${ROUTES.EMPLOYEES.GET_OPTIMIZED}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getEmployee(id) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_PROXY}${ROUTES.EMPLOYEES.GET_EMPLOYEE(id)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getWithRights() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.EMPLOYEES.WITH_ROLES}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      save(data) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}/account/employees`, data);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}/account/employees`, data));
      },
      update(data) {
        const token = self.currentToken;

        if (token) {
          return xhr.putJSON(token, `${CONFIG.API_ROOT}/account/employees`, data);
        }

        return self.requestAfterUpdateToken(() => xhr.putJSON(self.currentToken, `${CONFIG.API_ROOT}/account/employees`, data));
      },
      getEmployeeListWithProjects(employeeIds: number[]) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_PROXY}/employees/filter/ids`, employeeIds);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_PROXY}/employees/filter/ids`, employeeIds));
      },

      archive(employeeId) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}/account/employees/${employeeId}/arhive`);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}/account/employees/${employeeId}/arhive`));
      },
      toRestore(employeeId) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}/account/employees/${employeeId}/restore`);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}/account/employees/${employeeId}/restore`));
      },

      autocomplete(query, onlyAdult) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}${ROUTES.EMPLOYEES.AUTOCOMPLETE}`, { query, onlyAdult });
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}${ROUTES.EMPLOYEES.AUTOCOMPLETE}`, {
          query,
          onlyAdult,
        }));
      },
      getAutocompleteForDepartment({ search, ...other }: IAutocompleteEmployeeArgs) {
        const query = stringify(other);

        return self.requestWithTokenWrap((token) => xhr.getJSON(
          token,
          `${CONFIG.API_CLUSTER}${ROUTES.EMPLOYEES.AUTOCOMPLETE_DEPARTMENT(search, query)}`,
        ));
      },
      autoCompleteEmployeeOnly(query) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_PROXY}${ROUTES.EMPLOYEES.AUTOCOMPLETE_EMPLOYEE_ONLY}?query=${query}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      autoCompleteOnly(query) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_PROXY}${ROUTES.EMPLOYEES.AUTOCOMPLETE_ONLY}?query=${query}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      citizenship(query) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/citizenship`, { query });
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/citizenship`, { query }));
      },

      getRights(employeeId) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_ROOT}/uac/${employeeId}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getUserIdentity(employeeId) {
        const token = self.currentToken;
        const userIdentity = cToken => xhr.getJSON(cToken, `${CONFIG.API_ROOT}/account/employee/${employeeId}/user`);

        if (token) {
          return userIdentity(token);
        }

        return self.requestAfterUpdateToken(() => userIdentity(self.currentToken));
      },
      getAllApprovers() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_ROOT}/approve/users`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getApproversForUser(employeeId) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_ROOT}/approve/users/${employeeId}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      saveApprovers(employeeId, list) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_ROOT}/approve/users/${employeeId}`, list);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      saveApproversId(employeeId, list) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}/travel-approval/approver/factual/users/${employeeId}`, list);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      saveRights(employeeId, rights) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_ROOT}/uac/${employeeId}`, rights);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      disableUser(employeeId) {
        const token = self.currentToken;

        const request = cToken => xhr.putJSON(cToken, `${CONFIG.API_ROOT}/uac/${employeeId}/disable`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      checkDoublePas(body) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_ROOT}/employee/passport/check`, body);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      checkCyrillicPassport(employeeId) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_ROOT}/employee/${employeeId}/cyrillic-passport/check`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      downloadRegistryXlsx() {
        const token = self.currentToken;

        const request = cToken => xhr.downloadWithGuid(cToken, `${CONFIG.API_CLUSTER}${ROUTES.EMPLOYEES.DOWNLOAD_REGISTRY}`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      checkLogin(login, employeeId = null) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_ROOT}/uac/validate`, { email: login, employeeId });

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      updateIntercomCommentForUser(id) {
        const token = self.currentToken;
        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_ROOT}/user/${id}/update-intercom-comment`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getTgLink(id) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TELEGRAM.GET_LINK(id)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getLinkedTgAccount(id) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TELEGRAM.GET_RELATION(id)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      unlinkEmployeeTg(id) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TELEGRAM.UNLINK_EMPLOYEE(id)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      sendMailWithTgLink(employeeId, email) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TELEGRAM.SEND_MAIL(employeeId, email)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getEmployeesByCompany(companyId: number, adultsOnly: boolean = false) {
        return self.requestWithTokenWrap(
          (token) => xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.EMPLOYEES.BY_COMPANY(companyId, adultsOnly)}`),
        );
      },
    };
  }

  get trips() {
    const self = this;

    return {
      getTrip(tripId) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/trips/${tripId}`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/trips/${tripId}`));
      },
      getTrips(params) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/trips`, params);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/trips`, params));
      },
      getTripsInCalendar(params) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/trips/calendar`, params);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/trips/calendar`, params));
      },
      downloadReport(params) {
        const token = self.currentToken;

        const request = cToken => xhr.downloadWithGuidSendJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.TRIPS.DOWNLOADREPORT}`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`, params);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getReservationNotepad() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.NOTEPAD.GET_RESERVATION_NODE}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getTripsSmartagentPanel() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRIPS.GET_TRIPS_SMARTAGENT}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getCompanies() {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}${ROUTES.CHARTS_ANALYTICS.GET_COMPANIES}`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}${ROUTES.CHARTS_ANALYTICS.GET_COMPANIES}`));
      },
      getAnalyticsSmartagentPanel({ startDate, endDate, companyIds }) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_ANALYTICS_SMARTAGENT}`, { startDate, endDate, companyIds });

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get trip() {
    const self = this;

    return {
      // getOrderByParams(params) {
      //   const token = self.currentToken;
      //
      //   if (token) {
      //     return xhr.getJSON(token, `${CONFIG.API_ROOT}/OrderTrips`, params);
      //   }
      //
      //   return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/OrderTrips`, params));
      // },

      getDetails(id: number): Promise<IOrderDetailsData> {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/trips/${id}`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/trips/${id}`));
      },
      getDetailsByIds(tripIds: number[]): Promise<IOrderDetailsData[]> {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}/trips`, tripIds);
        }

        return self.requestAfterUpdateToken(() => xhr.send(self.currentToken, `${CONFIG.API_ROOT}/trips`, tripIds));
      },

      getTripPlanDetails(tripId) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRIP_PLAN.GET_PLAN(tripId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      createPlanTripEvent(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRIP_PLAN.ADD_EVENT}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      deletePlanTripEvent(tripEventId) {
        const token = self.currentToken;

        const request = cToken => xhr.delJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRIP_PLAN.DELETE_EVENT(tripEventId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      updatePlanTripEvent(tripEvent) {
        const token = self.currentToken;

        const request = cToken => xhr.putJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRIP_PLAN.UPDATE_EVENT}`, tripEvent);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      downloadPlanTripEvent(tripEventId: number, ownerId: number, needAddVouchers: boolean) {
        const token = self.currentToken;

        const request = cToken => xhr.downloadWithGuid(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRIP_PLAN.DOWNLOAD_EVENT(tripEventId, ownerId, needAddVouchers)}`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`, null);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      sendToEmailPlanTripEvent(tripId, ownerId, addvouchers, locale, email) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.TRIP_PLAN.SEND_TO_EMAIL(tripId, ownerId, email, locale, addvouchers)}`, null);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      getPaymentInvoice(companyId, tripId, format) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}/trip-service/companyId/${companyId}/trip/${tripId}/${format}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      downloadVouchers(tripId, locale) {
        const token = self.currentToken;

        const request = cToken => xhr.downloadWithGuid(cToken, `${CONFIG.API_ROOT}/trips/${tripId}/vouchers/?locale=${locale}`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      sendInvoiceEmail(companyId, tripId, email) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}/trip-service/total-invoice/send`, { CompanyId: companyId, TripId: tripId, Email: email });

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      downloadPaymentInvoice(guid) {
        return xhr.getFile(`${CONFIG.API_CLUSTER}/files/file`, guid, '');
      },

      goChatPaymentInvoice(tripId) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}/trip-service/trip/${tripId}/total-invoice/send-message-to-chat`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getRefundTrain(companyId: number, tripItemId: number) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/trains/${companyId}/${tripItemId}/refund`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/trains/${companyId}/${tripItemId}/refund`));
      },
      getRefundHotel(tripItemId: number) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/hotels/${tripItemId}/refund`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/hotels/${tripItemId}/refund`));
      },
      getRefundAir(tripItemId) {
        const token = self.currentToken;
        const url = `${CONFIG.API_ROOT}/airline/${tripItemId}/refund/sum`;

        if (token) {
          return xhr.getJSON(token, url);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, url));
      },
      getRefundTaxi(tripItemId: number) {
        const token = self.currentToken;
        const req = (tkn) => xhr.getJSON(tkn, `${CONFIG.API_ROOT}${ROUTES.TAXI.GET_REFUND(tripItemId)}`);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getRefundVipHall(tripItemId: number) {
        const token = self.currentToken;
        const req = (tkn) => xhr.getJSON(tkn, `${CONFIG.API_ROOT}${ROUTES.VIP_HALLS.GET_REFUND(tripItemId)}`);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getTransferRefund(tripItemId: number) {
        const token = self.currentToken;
        const req = (tkn) => xhr.getJSON(tkn, `${CONFIG.API_ROOT}${ROUTES.TRANSFERS.GET_REFUND(tripItemId)}`);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      sendCancelTrain(tripItemId: number) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}/trains/${tripItemId}/cancel`);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}/trains/${tripItemId}/cancel`));
      },
      sendCancelHotel(tripItemId: number) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}/hotels/${tripItemId}/cancel`);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}/hotels/${tripItemId}/cancel`));
      },
      sendCancelAir(tripItemId: number) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}/airline/${tripItemId}/refund`);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}/airline/${tripItemId}/refund`));
      },
      getRefundAeroexpress(tripItemId: number) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.AEROEXPRESS.GET_REFUND}/${tripItemId}/`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(token));
      },
      sendCancelAeroexpress(tripItemId: number) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.AEROEXPRESS.CANCEL}/${tripItemId}/${CLIENT_TYPE}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(token));
      },
      sendCancelTaxiVoucher(tripItemId: number) {
        const token = self.currentToken;
        const req = (tkn) => xhr.getJSON(tkn, `${CONFIG.API_ROOT}${ROUTES.TAXI.CANCEL(tripItemId)}`);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      sendCancelVipHall(tripItemId: number) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_ROOT}${ROUTES.VIP_HALLS.CANCEL(tripItemId)}`);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      sendCancelTransfer(tripItemId: number) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_ROOT}${ROUTES.TRANSFERS.CANCEL(tripItemId)}`);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      send(data) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}/trip/${data.tripId}/send?email=${data.email}`);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}/trip/${data.tripId}/send?email=${data.email}`));
      },
      sendDocument(data) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}/trips/items/${data.tripItemId}/send?email=${data.email}`);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}/trips/items/${data.tripItemId}/send?email=${data.email}`));
      },
      sendServiceForm({ tripItemId, serviceId, email }) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}/trips/items/${tripItemId}/additional-services/${serviceId}/send?email=${email}`);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}/trips/items/${tripItemId}/additional-services/${serviceId}/send?email=${email}`));
      },
      rename(id, data) {
        const token = self.currentToken;

        if (token) {
          return xhr.putJSON(token, `${CONFIG.API_ROOT}/trips/${id}/name`, `"${data.Name}"`);
        }

        return self.requestAfterUpdateToken(() => xhr.putJSON(self.currentToken, `${CONFIG.API_ROOT}/trips/${id}/name`, `"${data.Name}"`));
      },
      recent({ count, status }) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/OrderTrip/Recent?count=${count}&tripStatus=${status}`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/OrderTrip/Recent?count=${count}&tripStatus=${status}`));
      },

      bookFlightCertificate(tripItemId, projectId) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.TRIP.BOOK_FLIGHT_CERTIFICATE(tripItemId, projectId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      getStatusFlightCertificate(tripItemId) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.GET_STATUS_FLIGHT_CERTIFICATES(tripItemId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      getStatusFlightCertificateFree(tripItemId) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.GET_STATUS_FLIGHT_CERTIFICATES_FREE(tripItemId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      downloadFlightCertificate(tripItemId, isFree) {
        const data = isFree ? { isFree: true } : null;
        const token = self.currentToken;

        const request = cToken => xhr.downloadWithGuid(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.DOWNLOAD_FLIGHT_CERTIFICATE(tripItemId)}`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      downloadCalendar(tripId, data) {
        const token = self.currentToken;

        const request = cToken => xhr.downloadWithGuidSendJSON(cToken, `${CONFIG.API_PROXY}${ROUTES.TRIPS.DOWNLOAD_CALENDAR(tripId)}`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      setCustomAnalytics(tripId, analyticsValueId) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.TRIP.SET_ANALYTICS(tripId, analyticsValueId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      unsetCustomAnalytics(tripId, analyticsId) {
        const token = self.currentToken;

        const request = cToken => xhr.delJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.TRIP.UNSET_ANALYTICS(tripId, analyticsId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      setCustomAnalyticsForItem(tripId, tripItemId, analyticsValueId) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.TRIP.SET_ANALYTICS_FOR_ITEM(tripId, tripItemId, analyticsValueId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      unsetCustomAnalyticsForItem(tripId, tripItemId, analyticsId) {
        const token = self.currentToken;

        const request = cToken => xhr.delJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.TRIP.UNSET_ANALYTICS_FOR_ITEM(tripId, tripItemId, analyticsId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getTripComments(tripId) {
        const token = self.currentToken;
        const req = (tkn) => xhr.getJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.GET_COMMENTS(tripId)}`);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      addTripComment(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.ADD_COMMENTS}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      setAdditionalFeeSmartagentTrip(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRIP.SET_FEE_SMARTAGENT}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get report() {
    const self = this;

    return {
      sendInvoice(data) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}/report/invoice/last`, data);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}/report/invoice/last`, data));
      },
      lastInvoice(companyId, number = 6) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/reporting/operations/last/`, { companyId, number });
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/reporting/operations/last/`, {
          companyId,
          number,
        }));
      },
      getLastInvoice(companyId) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/report/invoice/last`, { companyId });
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(token, `${CONFIG.API_ROOT}/report/invoice/last`, { companyId }));
      },
      getOperationsByDateRange(startDate, endDate, companyId) {
        const token = self.currentToken;

        if (token) {
          const params = {
            startDate, endDate, companyId,
          };

          return xhr.getJSON(token, `${CONFIG.API_ROOT}/reporting/operations`, params);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/reporting/operations`, {
          startDate,
          endDate,
          companyId,
        }));
      },
      getInvoiceDetails(id) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/reporting/operations/${id}/transactions`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/reporting/operations/${id}/transactions`));
      },
      getDocumentPackages(companyId) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/packages`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/companies/${companyId}/packages`));
      },
      getBalance(companyId, startDate?: string, endDate?: string) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/account/balance`, { companyId, startDate, endDate });
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/account/balance`, { companyId, startDate, endDate }));
      },

      getInvoiceRequisites(companyId) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_CLUSTER}/account-service-documents/invoice-requisites/${companyId}`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_CLUSTER}/account-service-documents/invoice-requisites/${companyId}`));
      },

      getAllInvoices(companyId) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/invoice/consolidated/all`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(token, `${CONFIG.API_ROOT}/companies/${companyId}/invoice/consolidated/all`));
      },

      getDeposit(companyId, sum, format) {
        const token = self.currentToken;

        const request = cToken => xhr.downloadWithGuid(cToken, `${CONFIG.API_ROOT}/report/${companyId}/deposit/${format}`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`, { sum });

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      downloadVoucher(orderId) {
        const token = self.currentToken;

        const request = cToken => xhr.downloadWithGuid(cToken, `${CONFIG.API_ROOT}/trips/items/${orderId}/voucher`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      downloadVoucherLocale(orderId: number, locale: string) {
        const token = self.currentToken;

        const request = cToken => xhr.downloadWithGuid(cToken, `${CONFIG.API_ROOT}${ROUTES.TRIP.DOWNLOAD_LOCALE_VOUCHER(orderId, locale)}`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      downloadServiceForm(tripItemId, serviceId) {
        const token = self.currentToken;

        const request = cToken => xhr.downloadWithGuid(cToken, `${CONFIG.API_ROOT}/trips/items/${tripItemId}/additional-services/${serviceId}/voucher`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      downloadTrip(id) {
        const token = self.currentToken;

        const request = cToken => xhr.downloadWithGuid(cToken, `${CONFIG.API_ROOT}/trips/${id}/download`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      downloadPaymentDetails(companyId, startDate, endDate, periodType) {
        const token = self.currentToken;

        const request = cToken => xhr.downloadWithGuid(cToken, `${CONFIG.API_CLUSTER}${ROUTES.REPORT.DOWNLOAD_PAYMENT_DETAILS(companyId, startDate, endDate, periodType)}`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      downloadOriginalTrip(id) {
        const token = self.currentToken;

        const request = cToken =>
          xhr.downloadWithGuid(
            cToken,
            `${CONFIG.API_ROOT}${ROUTES.REPORT.DOWNLOAD_ORIGINAL_TRIP(id)}`,
            `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`,
          );

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      downloadPenalty(companyId, operationId, format) {
        const token = self.currentToken;

        const request = cToken => xhr.downloadWithGuid(cToken, `${CONFIG.API_ROOT}/report/${companyId}/penaltyInvoice/${operationId}/${format}`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      downloadInvoice(companyId, invoiceNumber, format) {
        const token = self.currentToken;

        const request = cToken => xhr.downloadWithGuid(cToken, `${CONFIG.API_ROOT}/report/${companyId}/invoice/${invoiceNumber}/${format}`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      downloadAllInvoiceByData(companyId, packageId) {
        const token = self.currentToken;

        const request = cToken => xhr.downloadWithGuid(cToken, `${CONFIG.API_ROOT}/companies/${companyId}/packages/${packageId}/archive`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      downloadInvoiceWithoutSlash(companyId, invoiceNumber, format) {
        const token = self.currentToken;

        const request = cToken => xhr.downloadWithGuid(cToken, `${CONFIG.API_ROOT}/report/${companyId}/invoice/${invoiceNumber}/empty/${format}`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      downloadInvoiceDebt(companyId, data) {
        const token = self.currentToken;
        const request = cToken => xhr.downloadWithGuidSendJSON(cToken, `${CONFIG.API_ROOT}/report/${companyId}/invoice/consolidated`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`, data);

        if (token) { return request(token); }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      downloadInvoiceForDateRange(companyId, startDate, endDate, format) {
        const token = self.currentToken;
        const query = `startDate=${startDate}&endDate=${endDate}`;
        const request = cToken => xhr.downloadWithGuid(cToken, `${CONFIG.API_ROOT}/companies/${companyId}/invoice/total/${format}?${query}`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`);

        if (token) { return request(token); }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      downloadDocument(companyId, packageId, documentId, format) {
        const token = self.currentToken;

        const requiest = cToken => xhr.downloadWithGuid(cToken, `${CONFIG.API_ROOT}/companies/${companyId}/packages/${packageId}/document/${documentId}/${format}`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`);

        if (token) {
          return requiest(token);
        }

        return self.requestAfterUpdateToken(() => requiest(self.currentToken));
      },
      downloadNote(params) {
        const token = self.currentToken;

        const request = cToken => xhr.downloadWithGuid(cToken, `${CONFIG.API_ROOT}/note/print?ids=[${params.toString()}]`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      downloadSpecifiedReportGsprom(data) {
        const token = self.currentToken;
        const request = cToken => xhr.downloadWithGuidSendJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.REPORT.DOWNLOAD_SPECIFIED_REPORT_GSPROM}`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      downloadSpecifiedReportSevmash(data) {
        const token = self.currentToken;
        const request = cToken => xhr.downloadWithGuidSendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.REPORT.DOWNLOAD_SPECIFIED_REPORT_SEVMASH}`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      downloadApproveReport(params) {
        const token = self.currentToken;

        const request = cToken => xhr.downloadWithGuidSendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.APPROVE.DOWNLOADREPORT}`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`, params);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      requestAct(companyId, format, data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.REPORT.REQUEST_ACT(companyId, format)}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      downloadReportAct(guid) {
        const token = self.currentToken;

        const request = (cToken) => xhr.downloadWithGuidBinaryFile(cToken, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD_BY_GUID(guid)}`);

        return request(token);
      },
    };
  }

  get chat() {
    const self = this;

    return {
      sendMessageToAdmin(data) {
        const token = self.currentToken;
        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}/notifier-service/chats/message/send`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get intercomChat() {
    const self = this;

    return {
      sendRequestOffline(data) {
        const token = self.currentToken;
        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.OFFLINE}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      sendType(tripItemId) {
        const token = self.currentToken;
        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_ROOT}/trips/items/${tripItemId}/change`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      sendMessageByFlightCertificate(tripItemId) {
        const token = self.currentToken;
        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}/booking-service-documents/${tripItemId}/message`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get carrotQuestChat() {
    const self = this;

    return {
      sendRequestOffline(data) {
        const token = self.currentToken;
        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.HOTELS.OFFLINE}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      userAuthorizeData() {
        const token = self.currentToken;
        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}/carrotquest/user/auth`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      sendType(tripItemId) {
        const token = self.currentToken;
        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_ROOT}/trips/items/${tripItemId}/change`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get toDo() {
    const self = this;

    return {
      get() {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_CLUSTER}/todo-service/get`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_CLUSTER}/todo-service/get`));
      },
      save(item) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/todo-service/create`, item);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_CLUSTER}/todo-service/create`, item));
      },
      update(item) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_CLUSTER}/todo-service/update`, item);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_CLUSTER}/todo-service/update`, item));
      },
      remove(id) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_CLUSTER}/todo-service/delete/one/${id}`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_CLUSTER}/todo-service/delete/one/${id}`));
      },
      removeAll() {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_CLUSTER}/todo-service/delete/all`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_CLUSTER}/todo-service/delete/all`));
      },
    };
  }

  get settings() {
    const self = this;

    return {
      get() {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/Employee/Current`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/Employee/Current`));
      },
      getCompanies() {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/account/companies`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/account/companies`));
      },
      getCompany(id) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/account/companies/${id}`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/account/companies/${id}`));
      },
      employeeAirline(value) {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}/airline/airlines?query=${value}`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}/airline/airlines?query=${value}`));
      },

      getFeeConstruction() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.SETTINGS_PAGE.GET_FEE_CONSTRUCTIONS}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      getBrandName(accountId) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.SETTINGS_PAGE.GET_BRAND_NAME(accountId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      updateFeeConstruction(data) {
        const token = self.currentToken;
        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.SETTINGS_PAGE.GET_FEE_CONSTRUCTIONS}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      employeesSettingsSearch(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_PROXY}${ROUTES.SETTINGS_PAGE.EMPLOYEES_VIEW}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      employeesSettingsSearchProgressive(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.SETTINGS_PAGE.AUTOCOMPLETE_EMPLOYEE}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      setCompanyOrder(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.SETTINGS_PAGE.EDIT_COMPANY_ORDER}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      getEmployeesCount(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_PROXY}${ROUTES.SETTINGS_PAGE.EMPLOYEES_COUNT}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      changePassword(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.CHANGE_PASSWORD}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getVoucherSetting() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.VOUCHER_AGENT.GET}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      setVoucherSettings(data: ({ AdditionalInfo: string, ContactInfo: string, LogoFileId: string })) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.VOUCHER_AGENT.SET}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      sendFileVouchersLogo(file: any) {
        const token = self.currentToken;

        const request = cToken => xhr.sendFile(cToken, `${CONFIG.API_CLUSTER}${ROUTES.VOUCHER_AGENT.DOWNLOAD_LOGO}`, file);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getVoucherPreview(data: { (AdditionalInfo: string, ContactInfo: string, LogoFileId: string) }) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.VOUCHER_AGENT.GET_PREVIEW}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      downloadTestVoucher(serviceType: string, body: any) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.VOUCHER_AGENT.LOAD_VOUCHER(serviceType)}`, body);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      onDownloadFile(guid: any) {
        return xhr.getFile(`${CONFIG.API_CLUSTER}${ROUTES.VOUCHER_AGENT.ON_DOWNLOAD_FILE}`, guid);
      },
    };
  }

  get quality() {
    const self = this;

    return {
      send(message) {
        const token = self.currentToken;
        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.QUALITY.SEND}`, { message });

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      sendToAggregator(data) {
        const token = self.currentToken;
        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.QUALITY.SEND_TO_AGGREGATOR}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get favorites() {
    const self = this;

    return {
      load() {
        const token = self.currentToken;
        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}/favorites/all`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      remove(id) {
        const token = self.currentToken;
        const request = cToken => xhr.delJSON(cToken, `${CONFIG.API_CLUSTER}/favorites/${id}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getFavoritesHotels() {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_CLUSTER}${ROUTES.FAVORITES.GETHOTELS}`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_CLUSTER}${ROUTES.FAVORITES.GETHOTELS}`));
      },
    };
  }

  get travelPolicies() {
    const self = this;

    return {
      load() {
        const token = self.currentToken;
        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_POLICIES.LOAD}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      save(item) {
        const token = self.currentToken;
        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_POLICIES.SAVE}`, item);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getBundle(id) {
        const token = self.currentToken;
        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_POLICIES.GET_BUNDLE(id)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      saveBundle(data) {
        const token = self.currentToken;
        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_POLICIES.SAVE_BUNDLE}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      remove(id) {
        const token = self.currentToken;
        const request = cToken => xhr.delJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_POLICIES.REMOVE(id)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      updateIntercomCommentForUsers() {
        const token = self.currentToken;
        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.TRAVEL_POLICIES.UPDATE_INTERCOM_COMMENT_FOR_USERS}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getTravelPolicyForEmployeeById(id) {
        const token = self.currentToken;
        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_POLICIES.GET_TRAVEL_POLICIES_BY_ID(id)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getUniqueTravelPolicy() {
        const token = self.currentToken;
        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_POLICIES.GET_UNIQUE_TRAVEL_POLICY_AFTER_QUESTIONS}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getDoneUniqueTravelPolicy() {
        const token = self.currentToken;
        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_POLICIES.GET_DONE_UNIQUE_TRAVEL_POLICY}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      saveUniqueTravelPolicy(model) {
        const token = self.currentToken;
        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_POLICIES.SAVE_UNIQUE_TRAVEL_POLICY}`, model);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      deleteQuestions() {
        const token = self.currentToken;
        const request = cToken => xhr.delJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_POLICIES.DELETE_QUESTIONS}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get featureFlags() {
    const self = this;

    return {
      load() {
        const token = self.currentToken;
        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.FEATURE_FLAGS.GET}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      setMandatoryProjects(value) {
        const token = self.currentToken;

        const request = cToken => xhr.putJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.FEATURE_FLAGS.SET_MANDATORY_PROJECTS}`, { Value: value });

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getSettingsFlags(accountId) {
        const token = self.currentToken;
        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.FEATURE_FLAGS.GET_SETTINGS_FLAGS(accountId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get accountSettings() {
    const self = this;

    return {
      getAccountSettings() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}/access-settings/wp`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getProhibitionVouchers(accountId) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}/access-settings/company/prohibition-vouchers/${accountId}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getCompaniesWithCostCenter(accountId) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}/access-settings/company/mandatory-project/${accountId}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      updateCompaniesWithCostCenter(companies) {
        const token = self.currentToken;

        const request = cToken => xhr.putJSON(cToken, `${CONFIG.API_CLUSTER}/access-settings/company/mandatory-project`, companies);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get tripTags() {
    const self = this;

    return {
      loadAccountTripTags() {
        const token = self.currentToken;

        if (token) {
          return xhr.getJSON(token, `${CONFIG.API_ROOT}${ROUTES.TAGS.LOAD}`);
        }

        return self.requestAfterUpdateToken(() => xhr.getJSON(self.currentToken, `${CONFIG.API_ROOT}${ROUTES.TAGS.LOAD}`));
      },
      addAccountTripTag(tag) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}${ROUTES.TAGS.ADDTOACCOUNT}`, tag);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}${ROUTES.TAGS.ADDTOACCOUNT}`, tag));
      },
      updateTripItemTripTags(tripItemId, tags) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}${ROUTES.TAGS.GETTRIPSAVEROUTE(tripItemId)}`, tags);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}${ROUTES.TAGS.GETTRIPSAVEROUTE(tripItemId)}`, tags));
      },
      updateCartItemTripTags(cartItemId, tags) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}${ROUTES.TAGS.GETCARTSAVEROUTE(cartItemId)}`, tags);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}${ROUTES.TAGS.GETCARTSAVEROUTE(cartItemId)}`, tags));
      },
      updateNoteItemTripTags(noteItemId, tags) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}${ROUTES.TAGS.GETNOTESAVEROUTE(noteItemId)}`, tags);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}${ROUTES.TAGS.GETNOTESAVEROUTE(noteItemId)}`, tags));
      },
      updateEmployeeTripTags(employeeId, tags) {
        const token = self.currentToken;

        if (token) {
          return xhr.sendJSON(token, `${CONFIG.API_ROOT}${ROUTES.TAGS.GETEMPLOYEESAVEROUTE(employeeId)}`, tags);
        }

        return self.requestAfterUpdateToken(() => xhr.sendJSON(self.currentToken, `${CONFIG.API_ROOT}${ROUTES.TAGS.GETEMPLOYEESAVEROUTE(employeeId)}`, tags));
      },
    };
  }

  get approve() {
    return {
      confirmQuickApprove(id: string, action): Promise<IConfirmResponse> {
        return xhr.putJSONWithoutToken(`${CONFIG.API_ROOT}/quick-approve/${id}/${action}`);
      },
      confirmQuickApproveTravelApproval(
        approveId: number, userId: string, action: number,
      ): Promise<IConfirmResponse> {
        return xhr.putJSONWithoutToken(
          `${CONFIG.API_CLUSTER}/travel-approval/quick-approve`,
          { Id: approveId, UserID: userId, Resolution: action },
        );
      },
      confirmQuickApproveExpenseReportApproval(
        approveId: string, userId: string, action: number,
      ): Promise<IConfirmResponse> {
        return xhr.putJSONWithoutToken(
          `${CONFIG.API_CLUSTER}/expense-report-approval/quick-approve`,
          { Id: approveId, UserID: userId, Resolution: action },
        );
      },
    };
  }

  get requests() {
    const self = this;

    return {
      loadRequests() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.REQUESTS.GET_REQUESTS}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      loadRequest(id) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.REQUESTS.GET_REQUEST(id)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getTripsRequests(value) {
        const token = self.currentToken;
        const filter = value ? `?searchFilter=${value}` : '';

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.REQUESTS.GET_TRIP_REQUEST(filter)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      setTripsRequests(body) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRIPS.SET_TRIPS_REQUEST}`, body);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getIntegration() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_ROOT}/integration`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      disableIntegration() {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_ROOT}/integration/disable`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      deleteIntegration({ guid, companies }) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_ROOT}/integration/delete`, {
          Id: guid,
          Companies: companies,
        });

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      switchIntegration({ guid, companies }) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_ROOT}/integration/switch`, {
          Id: guid,
          Companies: companies,
        });

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get popups() {
    const self = this;

    return {
      getStatePopups() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.POPUPS.GET_STATE_POPUPS}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      setStatePopups(name: string) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.POPUPS.SET_STATE_POPUP}`, {
          type: name,
        });

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      getUserEmailsByUserIds(ids: number[]) {
        const token = self.currentToken;
        const request = currentToken => xhr.sendJSON(currentToken, `${CONFIG.API_CLUSTER}${ROUTES.POPUPS.USERS.EMAILS_BY_IDS}`, { ids });

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      getStateVipHallPopup(tripItemId) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.POPUPS.OFFER_STATE(tripItemId)}`);

        if (token) return request(token);

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      confirmPostponeShowing(value) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.POPUPS.OFFER}`, { Agreed: value });

        if (token) return request(token);

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get banners() {
    const self = this;

    return {
      getStateBanners() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.POPUPS.GET_STATE_BANNERS}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      setStateBanners(name: string) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.POPUPS.SET_STATE_BANNER}`, {
          type: name,
        });

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      sendMobileAppLinks() {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.POPUPS.MOBILE_APP_SEND_MESSAGE}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get aeroexpress() {
    const self = this;

    return {
      getRoutes() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.AEROEXPRESS.GET_ROUTES}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      search(params) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.AEROEXPRESS.SEARCH}`, params);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      addToCart(params) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.AEROEXPRESS.ADD_TO_CART}`, params);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get approvalSchemes() {
    const self = this;

    return {
      getList() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.APPROVAL_SCHEMES.GET}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getCartSchemeTravelApproval(cartId, requestId) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_APPROVAL.GET_CART_SCHEME_TRAVEL_APPROVAL(cartId, requestId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getCartScheme(cartId) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_APPROVAL.GET_CART_SCHEME(cartId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      save(data) {
        const token = self.currentToken;

        const method = data.Id ? xhr.putJSON : xhr.sendJSON;

        const request = cToken => method(cToken, `${CONFIG.API_ROOT}${ROUTES.APPROVAL_SCHEMES.UPDATE}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      remove(id) {
        const token = self.currentToken;

        const request = cToken => xhr.delJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.APPROVAL_SCHEMES.REMOVE}/${id}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      linkToEmployee(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.APPROVAL_SCHEMES.LINK_TO_EMPLOYEE}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getApprovers(employeeIds) {
        const token = self.currentToken;
        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.APPROVAL_SCHEMES.APPROVERS}`, employeeIds);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      loadApprovers(data) {
        const token = self.currentToken;
        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_APPROVAL.APPROVERS}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getNewApprovers() {
        const token = self.currentToken;
        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.APPROVAL_SCHEMES.NEW_APPROVERS}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getUsersInfo() {
        const token = self.currentToken;
        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.APPROVAL_SCHEMES.GET_USERS_INFO}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getSchemeById(id) {
        const token = self.currentToken;
        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.APPROVAL_SCHEMES.GET_SCHEME(id)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getNewList() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.APPROVAL_SCHEMES.GET_SHORT_INFO}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get approvalExpenseReports() {
    const self = this;

    return {
      getERSchemes() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.APPROVAL_ER_SCHEMES.GET}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      saveSchemePut(data) {
        const token = self.currentToken;

        const request = cToken => xhr.putJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.APPROVAL_ER_SCHEMES.SAVE}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      saveSchemeSend(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.APPROVAL_ER_SCHEMES.SAVE}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getApproversForExpenseReports() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}/expense-report-approval/approvers`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      saveApproversExpenseReports(employeeId, list) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}/expense-report-approval/approvers/${employeeId}`, list);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getERApproversForUser(employeeId) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}/expense-report-approval/approvers/${employeeId}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      linkExpenseReportsSchemeToEmployee(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.APPROVAL_ER_SCHEMES.LINK_TO_EMPLOYEE}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      removeScheme(id) {
        const token = self.currentToken;

        const request = cToken => xhr.delJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.APPROVAL_ER_SCHEMES.REMOVE}/${id}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get bonusProgram() {
    const self = this;

    return {
      getBonuses(data) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.S7.GET}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      setCode(code) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.S7.SETCODE}`, `"${code}"`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      checkEmail(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.S7.EMAIL}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      registration(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_ROOT}${ROUTES.S7.REGISTRATION}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get airlineSeats() {
    const self = this;

    return {
      getSeatMaps(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_PROXY}${ROUTES.AIRLINE_SEATS.GET_SEAT_MAPS}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      bookSeats(cartItemId, segmentId, cartId, data) {
        const token = self.currentToken;

        const request = cToken => xhr.putJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.CART.SET_CART_ITEM_SEGMENT_SEATS(cartItemId, segmentId, cartId)}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      deleteAllPlaces(cartItemId) {
        const token = self.currentToken;

        const request = cToken => xhr.delJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.AIRLINE_SEATS.DELETE_PLACES(cartItemId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getAirlineAdditionalService() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.AIRLINE_SEATS.AIRLINE_ADDITIONAL_SERVICE}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getAirlineSeatsBonusWarningS7(query: string) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.AIRLINE_SEATS.AIRLINE_SEATS_BONUS_WARNING_S7(query)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get airlineBaggage() {
    const self = this;

    return {
      getBaggageOffers(providerName: string, offerId: string) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_PROXY}${ROUTES.AIRLINE_BAGGAGE.GET_BAGGAGE_OFFER(providerName, offerId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      addBaggageToCart(cartItemId: number, data: any) {
        const token = self.currentToken;

        const request = cToken => xhr.putJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.AIRLINE_BAGGAGE.ADD_BAGGAGE_TO_CART(cartItemId)}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

    };
  }

  get expenseReports() {
    const self = this;

    return {
      getExpenseReports() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.EXPENSE_REPORTS.GET}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getApproversSteps(empId, trips) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.EXPENSE_REPORTS.GET_APPROVERS_SCHEME(empId)}`, trips);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getApprovers(empId) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.EXPENSE_REPORTS.GET_APPROVERS(empId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getExpenseReport(id) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.EXPENSE_REPORTS.SINGLE(id)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      deleteExpenseReport(id) {
        const token = self.currentToken;

        const request = cToken => xhr.delJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.EXPENSE_REPORTS.SINGLE(id)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      cancelApprovedExpenseReport(id) {
        const token = self.currentToken;

        const request = cToken => xhr.delJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.EXPENSE_REPORTS.SINGLE(id)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getTrips(employeeId, companyId) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.EXPENSE_REPORTS.GET_TRIPS(employeeId, companyId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      saveNewReport(employeeId, data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.EXPENSE_REPORTS.SAVE_NEW_REPORT(employeeId)}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      updateReport(reportId, data) {
        const token = self.currentToken;

        const request = cToken => xhr.putJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.EXPENSE_REPORTS.SAVE_REPORT(reportId)}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      sendReportForApprove(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.EXPENSE_REPORTS.SEND_REPORT_FOR_APPROVE}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getPrefiledValues() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.EXPENSE_REPORTS.GET_PREFILED_VALUE}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getSettingsExpense() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.EXPENSE_REPORTS.SETTINGS}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      saveSettings(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.EXPENSE_REPORTS.SETTINGS}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      downloadReport(reportId, file) {
        const token = self.currentToken;

        const request = cToken => xhr.downloadWithGuid(cToken, `${CONFIG.API_CLUSTER}${ROUTES.EXPENSE_REPORTS.DOWNLOAD_REPORT(reportId, file)}`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      sendEmail(reportId, email, file) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.EXPENSE_REPORTS.SEND_EMAIL(reportId, email, file)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getExpenseReportsApproves() {
        const token = self.currentToken;
        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.EXPENSE_REPORTS.APPROVES}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      sendFileExpenseReport(file, progressHandler) {
        const token = self.currentToken;
        const request = cToken => xhr.sendFile(cToken, `${CONFIG.API_CLUSTER}${ROUTES.EXPENSE_REPORTS.SEND_FILE}`, file, progressHandler);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      downloadDocumentsItem(reportId, guid) {
        const token = self.currentToken;

        const request = cToken => xhr.downloadWithGuid(cToken, `${CONFIG.API_CLUSTER}${ROUTES.EXPENSE_REPORTS.DOWNLOAD_DOCUMENTS_ITEM(reportId, guid)}`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      downloadArchiveDocs(reportId) {
        const token = self.currentToken;

        const request = cToken => xhr.downloadWithGuid(cToken, `${CONFIG.API_CLUSTER}${ROUTES.EXPENSE_REPORTS.DOWNLOAD_ARCHIVE_DOCS(reportId)}`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      approveExpenseReport(data, id) {
        const token = self.currentToken;
        const request = cToken => xhr.putJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.EXPENSE_REPORTS.APPROVE(id)}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get customAnalytics() {
    const self = this;

    return {
      getAnalytics() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_PROXY}${ROUTES.CUSTOM_ANALYTICS.GET}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      createAnalytics(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_PROXY}${ROUTES.CUSTOM_ANALYTICS.CREATE}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getAnalyticsById(id) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_PROXY}${ROUTES.CUSTOM_ANALYTICS.GET_BY_ID(id)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      updateAnalytics(id, data) {
        const token = self.currentToken;

        const request = cToken => xhr.putJSON(cToken, `${CONFIG.API_PROXY}${ROUTES.CUSTOM_ANALYTICS.UPDATE(id)}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      deleteAnalytics(id) {
        const token = self.currentToken;

        const request = cToken => xhr.delJSON(cToken, `${CONFIG.API_PROXY}${ROUTES.CUSTOM_ANALYTICS.DELETE(id)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      addValue(id, value) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_PROXY}${ROUTES.CUSTOM_ANALYTICS.ADD_VALUE(id)}`, value);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      deleteValue(id, valueId) {
        const token = self.currentToken;

        const request = cToken => xhr.delJSON(cToken, `${CONFIG.API_PROXY}${ROUTES.CUSTOM_ANALYTICS.DELETE_VALUE(id, valueId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get uiSettings() {
    const self = this;

    return {
      getUiSettings(aId) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.UI_SETTINGS.GET_UI(aId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      getLngSettings(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.UI_SETTINGS.GET_LNG}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      setAccountLngSettings(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.UI_SETTINGS.GET_LNG}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      updateAccountLngSettings(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.UI_SETTINGS.UPDATE}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get travelApproval() {
    const self = this;

    return {
      getTravelApprovals(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_APPROVAL.REQUESTS}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      getTravelApprovalsByUserId(userId) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_APPROVAL.USER_REQUESTS(userId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      sendApprovalRequest(data) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_APPROVAL.REQUEST}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      getAdmin() {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_APPROVAL.GET_ADMIN}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      downloadServiceAssignment(requestId, format = 'xlsx') {
        const token = self.currentToken;

        const request = cToken => xhr.downloadWithGuid(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_APPROVAL.REQUEST}/${requestId}/servicetask/${format}`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      // TODO: change xlsx, add formats
      downloadBusinessOrder(requestId, format = 'xlsx') {
        const token = self.currentToken;

        const request = cToken => xhr.downloadWithGuid(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_APPROVAL.REQUEST}/${requestId}/triporder/${format}`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      downloadPDF(requestId) {
        const token = self.currentToken;

        const request = cToken => xhr.downloadWithGuid(cToken, `${CONFIG.API_CLUSTER}/approve-service-report/travel-approval/request-pdf/${requestId}`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      validateRequest(reqId, cartId) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_APPROVAL.TRAVEL_APPROVAL}${ROUTES.TRAVEL_APPROVAL.VALIDATE_REQUEST(reqId, cartId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      assignRequest(reqId, cartId) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_APPROVAL.TRAVEL_APPROVAL}${ROUTES.TRAVEL_APPROVAL.ASSIGN_REQUEST(reqId, cartId)}`, {});

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      getRequest(id) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_APPROVAL.REQUEST}/${id}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      getApprovedPopUp(id) {
        const token = self.currentToken;
        const requestUnfiltered = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_APPROVAL.UNFILTERED(id)}`);

        if (token) {
          return requestUnfiltered(token);
        }

        return self.requestAfterUpdateToken(() => requestUnfiltered(self.currentToken));
      },

      approveRequest(data) {
        const token = self.currentToken;
        const request = cToken => xhr.putJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_APPROVAL.APPROVE}`, data);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      getApproveRequestExists(id) {
        const token = self.currentToken;
        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_APPROVAL.EXISTS(id)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      getApproves() {
        const token = self.currentToken;
        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_APPROVAL.APPROVES}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      updateEmployeeProject(requestId: number, projectId: number | null, employeeId: number) {
        const token = self.currentToken;
        const request = cToken => xhr.putJSON(
          cToken,
          `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_APPROVAL.UPDATE_EMPLOYEE_PROJECT(requestId, employeeId)}`,
          projectId,
        );

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      getApplicationScheme(schemeId) {
        const token = self.currentToken;
        const request = cToken => xhr.getJSON(
          cToken,
          `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_APPROVAL.APPROVAL_TEMPLATES(schemeId)}`,
        );

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      updateApprovalRequest(model, requestId: number | null, approvalId: number) {
        const token = self.currentToken;
        const request = cToken => xhr.putJSON(
          cToken,
          `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_APPROVAL.REQUEST_UPDATE(requestId, approvalId)}`,
          model,
        );

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },

      deleteRequest(id) {
        const token = self.currentToken;
        const request = cToken => xhr.delJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.TRAVEL_APPROVAL.DELETE_REQUEST(id)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get taxi() {
    const self = this;

    return {
      getActiveVouchers() {
        const token = self.currentToken;
        const req = (tkn) => xhr.getJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.TAXI.GET_ACTIVE_VOUCHERS}`);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },

      getCreatedVouchers() {
        const token = self.currentToken;
        const req = (tkn) => xhr.getJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.TAXI.GET_CREATED_VOUCHERS}`);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },

      getTextWarning() {
        const token = self.currentToken;
        const req = (tkn) => xhr.getJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.TAXI.GET_TEXT_WARNING}`);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },

      addToCart(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_ROOT}${ROUTES.TAXI.TO_CARD}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
    };
  }

  get chartsAnalytics() {
    const self = this;

    return {
      getGeneral(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_GENERAL}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getSmartSummary(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_SMART_SUMMARY}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getSmartServiceType(params, serviceType, smartAnalyticsType) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_SMART_SERVICE_TYPE(serviceType, smartAnalyticsType)}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getSmartAirline(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_SMART_AIRLINE}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getSmartTrain(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_SMART_TRAIN}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getSmartAeroexpress(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_SMART_AEROEXPRESS}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getSmartTransfer(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_SMART_TRANSFER}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getSmartMice(params: IParamsAnalytics) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_SMART_MICE}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getSmartHotel(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_SMART_HOTEL}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getSmartTaxi(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_SMART_TAXI}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getTaxiSummary(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_TAXI_SUMMARY}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getTaxiDirections(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_TAXI_DIRECTIONS}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getTaxiSummarySubPage(params, type) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_TAXI_SUMMARY_SUBPAGE(type)}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getTaxiSubPageVoucher(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_TAXI_SUBPAGE_VOUCHER}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getTaxiDirectionsSubPage(params, type) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_TAXI_DIRECTIONS_SUBPAGE(type)}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getSmartPageWithTrips(params, serviceType, smartAnalyticsType) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_SMART_PAGE_WITH_TRIPS(serviceType, smartAnalyticsType)}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getAirlineSummary(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_AIRLINE_SUMMARY}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getAirlineSummarySubPage(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_AIRLINE_SUMMARY_SUBPAGE}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getAirlineDirections(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_AIRLINE_DIRECTIONS}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getAirlineDirectionsSubPage(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_AIRLINE_DIRECTIONS_SUBPAGE}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getAirlineCompanies(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_AIRLINE_COMPANIES}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getAirlineCompaniesSubPage(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_AIRLINE_COMPANIES_SUBPAGE}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getAirlineEarliness(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_AIRLINE_EARLINESS}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getAirlineEarlinessSubPage(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_AIRLINE_EARLINESS_SUBPAGE}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getTrainSummary(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_TRAIN_SUMMARY}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getTrainSummarySubPage(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_TRAIN_SUMMARY_SUBPAGE}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getTrainDirections(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_TRAIN_DIRECTIONS}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getTrainDirectionsSubPage(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_TRAIN_DIRECTIONS_SUBPAGE}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getAeroexpressSummary(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_AEROEXPRESS_SUMMARY}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getAeroexpressSummarySubPage(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_AEROEXPRESS_SUMMARY_SUBPAGE}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getAeroexpressDirections(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_AEROEXPRESS_DIRECTIONS}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getAeroexpressDirectionsSubPage(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_AEROEXPRESS_DIRECTIONS_SUBPAGE}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getTransferSummary(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_TRANSFER_SUMMARY}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getTransferSummarySubPage(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_TRANSFER_SUMMARY_SUBPAGE}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getTransferDirections(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_TRANSFER_DIRECTIONS}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getTransferDirectionsSubPage(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_TRANSFER_DIRECTIONS_SUBPAGE}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getMiceSummary(params: IParamsAnalyticsFull) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_MICE_SUMMARY}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getMiceSummarySubPage(params: IParamsAnalyticsFull) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_MICE_SUMMARY_SUBPAGE}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getMiceDirections(params: IParamsAnalyticsFull) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_MICE_DIRECTIONS}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getMiceDirectionsSubPage(params: IParamsAnalyticsFull) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_MICE_DIRECTIONS_SUBPAGE}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getStructureDepartments(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_STRUCTURE_DEPARTMENTS}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getHotelSummary(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_HOTEL_SUMMARY}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getHotelDirections(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_HOTEL_DIRECTIONS}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getHotelPopular(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_HOTEL_POPULAR}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getHotelPopularSubPage(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_HOTEL_POPULAR_SUBPAGE}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getHotelDirectionsSubPage(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_HOTEL_DIRECTIONS_SUBPAGE}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getHotelSummarySubPage(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_HOTEL_SUMMARY_SUBPAGE}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getStructureProjects(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_STRUCTURE_PROJECTS}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getStructureServiceType(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_STRUCTURE_SERVICE_TYPE}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getStructureCustomAnalytics(params, id) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_STRUCTURE_CUSTOM_ANALYTICS(id)}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getStructureServiceTypeSubPage(params) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_STRUCTURE_SERVICE_TYPE_SUBPAGE}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getEmployeesAutocomplete(query: string, params: object, type: string) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_EMPLOYEES}/${encodeURI(query)}?type=${type}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getEmployeesAutocompleteForCustom(query, params, type, id) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_EMPLOYEES}/${encodeURI(query)}?type=${type}&&id=${id}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getDepartmentsAutocomplete(query, params, type) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_DEPARTMENTS}/${encodeURI(query)}?type=${type}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getDepartmentsAutocompleteForCustom(query, params, type, id) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_DEPARTMENTS}/${encodeURI(query)}?type=${type}&&id=${id}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getProjectsAutocomplete(query, params, type) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_PROJECTS}/${encodeURI(query)}?type=${type}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getTagsAutocomplete(query, params, type) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_TAGS}/${encodeURI(query)}?type=${type}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getTagsAutocompleteForCustom(query, params, type, id) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_TAGS}/${encodeURI(query)}?type=${type}&&id=${id}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      getProjectsAutocompleteForCustom(query, params, type, id) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.CHARTS_ANALYTICS.GET_PROJECTS}/${encodeURI(query)}?type=${type}&&id=${id}`, params);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
      downloadAnalyticsReport(route, params) {
        const token = self.currentToken;

        const request = cToken => xhr.downloadWithGuidSendJSON(cToken, `${CONFIG.API_CLUSTER}${route}`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`, params);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get productAnalytics() {
    const self = this;

    return {
      sendAnalytics(data) {
        const token = self.currentToken;
        const req = (tkn) => xhr.sendJSON(tkn, `${CONFIG.API_CLUSTER}${ROUTES.PRODUCT_ANALYTICS.SEND}`, data);

        if (token) {
          return req(token);
        }

        return self.requestAfterUpdateToken(() => req(self.currentToken));
      },
    };
  }

  get simpleSignature() {
    const self = this;

    return {
      loadSimpleSignature(employeeId) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.SIMPLE_SIGNATURE.LOAD_SIGNATURE(employeeId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getImagesSignature(employeeId) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.SIMPLE_SIGNATURE.GET_IMAGES_SIGNATURE(employeeId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getDocsForSignature(employeeId, page, limit, sortType) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.SIMPLE_SIGNATURE.GET_DOCS_SIGNATURE(employeeId, page, limit, sortType)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      createSimpleSignature(body) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.SIMPLE_SIGNATURE.CREATE_SIMPLE_SIGNATURE}`, body);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      sendSingingDocsCode(body) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.SIMPLE_SIGNATURE.SEND_SINGING_DOCS}`, body);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      sendSingingDocs(body) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.SIMPLE_SIGNATURE.SEND_SINGING_DOCS_CONFIRM}`, body);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      sendSignatureCode(body) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.SIMPLE_SIGNATURE.SEND_SIGNATURE_CODE}`, body);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      deleteSignature(employeeId) {
        const token = self.currentToken;

        const request = cToken => xhr.delJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.SIMPLE_SIGNATURE.DELETE_SIGNATURE(employeeId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      downloadXlsx(employeeId) {
        const token = self.currentToken;

        const request = cToken => xhr.downloadWithGuid(cToken, `${CONFIG.API_CLUSTER}${ROUTES.SIMPLE_SIGNATURE.DOWNLOAD_XLSX(employeeId)}`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      downloadDocument(documentId, employeeId) {
        const token = self.currentToken;

        const request = cToken => xhr.downloadWithGuid(cToken, `${CONFIG.API_CLUSTER}${ROUTES.SIMPLE_SIGNATURE.DOWNLOAD_DOC(documentId, employeeId)}`, `${CONFIG.API_CLUSTER}${ROUTES.FILES.DOWNLOAD}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getDocumentPreviewSignature(documentId, employeeId) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.SIMPLE_SIGNATURE.GET_DOCUMENT_IMAGE_SIGNATURE(documentId, employeeId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      sendDocumentToEmail(email:string, documentId: string, employeeId:string) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.SIMPLE_SIGNATURE.SEND_EMAIL(documentId, employeeId, email)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }

  get insurance() {
    const self = this;

    return {
      getPricesOfInsurances(cartId) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.INSURANCES.RATES(cartId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      getPricesOfInsurancesInTrips(tripId) {
        const token = self.currentToken;

        const request = cToken => xhr.getJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.INSURANCES.RATES_IN_TRIPS(tripId)}`);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      sendInsurancesToCart(body) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.INSURANCES.CARTITEMS}`, body);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
      recalculateRatesOfInsurancesInTrip(body) {
        const token = self.currentToken;

        const request = cToken => xhr.sendJSON(cToken, `${CONFIG.API_CLUSTER}${ROUTES.INSURANCES.RECALCULATE}`, body);

        if (token) {
          return request(token);
        }

        return self.requestAfterUpdateToken(() => request(self.currentToken));
      },
    };
  }
}

export default Api;
