import React, { Component, ReactNode } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Moment } from 'moment';
import { Text, Button, Icon, DotLoading, StyledWrapper, PROPS, NoResults } from 'new-ui';

import { getText } from '../../../../../i18n';

import ACTIONS from '../../../../bi/services/hotels/action';
import HotelsService from '../../../../bi/services/hotels';
import ChatService from '../../../../bi/services/chat';
import WorkspaceService from '../../../../bi/services/workspace';
import FeatureFlagsService from '../../../../bi/services/featureFlags';
import SidePanelService from '../../../../bi/services/sidePanel';
import UserSessionService from '../../../../bi/services/userSession';
import AppService from '../../../../bi/services/app';

import { HotelServices } from '../../../../components/HotelServices';
import { HotelCheckinCheckoutPanel } from '../../../../components/HotelCheckinCheckoutPanel';
import { HotelCarousel } from './components/HotelCarousel';
import { RoomGroup } from './components/RoomGroup';
import Filters from './components/Filters';

import { MainAnalytic } from '../../../../bi/utils/analytics';
import {
  getSearchParamsHotel,
  getSearchParamsCity,
  getParamDetailAddCart,
} from '../../../../bi/utils/hotel';
import { parseSearchString, stringifySearchParams } from '../../../../bi/utils/convertSearchParams';
import { diffDays, formatDate } from '../../../../bi/utils/formatDate';

import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';
import { DATEFORMATS } from '../../../../bi/constants/dateFormats';
import { ANALYTIC_SERVICE_TYPES } from '../../../../bi/constants/serviceType';
import { UPSELL_EXPERIMENTS } from '../../../../bi/constants/hotel';

import { IAccountTravelPolicy } from '../../../../bi/services/workspace/types';
import { Rights } from '../../../../bi/types/workspace';
import {
  IHotelStore,
  IHotel,
  OfflineRoomGroup,
  PrepareRate,
  ISearchStore,
  RoomGroup as IRoomGroup,
  PrepareRoomGroup,
} from '../../../../bi/services/hotels/types';
import { HotelSearchLocationType, YesNoType } from '../../../../bi/types/hotel';

import styles from './styles/index.module.css';

const LABELS = {
  LOADER: {
    FIRST_PART: getText('hotels:hotelResult.item.loader.fistPart'),
    SECOND_PART: getText('hotels:hotelResult.item.loader.secondPart'),
    ERROR_SELECTED_HOTEL: getText('hotels:hotelResult.item.loader.errorSelectedHotel'),
  },
  AVAILABLE_ROOMS: getText('hotels:hotelResult.item.availableRooms'),
  OPTIMAL_CHOISE: getText('hotels:hotelResult.item.optimalChoice'),
  ADD_EARLY_IN: getText('hotels:hotelResult.item.addEarlyIn'),
  LATE_OUT: getText('hotels:hotelResult.item.lateOut'),
  ADD_LATE_OUT: getText('hotels:hotelResult.item.addLateOut'),
  PRIMARY_INFO: getText('hotels:hotelResult.item.primaryInfo'),
  NOT_FOUND_HOTELS: getText('hotels:hotelResult.item.notFoundHotel'),
  NOT_FOUND_HOTELS_ONLINE: getText('hotels:hotelResult.item.notFoundOnlineHotel'),
  RECOMMENDED_HOTELS_AROUND: getText('hotels:hotelResult.item.recommendedHotelsNearby'),
  CHOOSE_ANOTHER_HOTEL: (city: string) => getText('hotels:hotelResult.item.chooseAnotherHotel', { city }),
  CORPORATE: getText('hotels:hotelResult.item.tariffs.corporate'),
  WARNING: getText('hotels:hotelResult.item.tariffs.warning'),
  DO_NOT_WORK: getText('hotels:hotelResult.item.tariffs.doNotWork'),
  STANDARD: getText('hotels:hotelResult.item.tariffs.standard'),
  EARLY_IN: (date: string) => getText('hotels:hotelResult.item.earlyIn', { date }),
  LATE_OUT_WITH_DATE: (date: string) => getText('hotels:hotelResult.item.lateOutWithDate', { date }),
};

const {
  DATEPICKER: {
    MODE: {
      TIME,
    },
  },
} = PROPS;

const HOTEL_CAROUSEL = {
  CARD_MARGIN: 33,
  CARDS_ROW: 4,
};

interface HotelProps {
  isRecommended?: boolean,
  hotel: IHotel,
  hotelsService: HotelsService,
  userSessionService: UserSessionService,
  workspaceService: WorkspaceService,
  chatService: ChatService,
  sidePanelService: SidePanelService,
  featureFlagsService: FeatureFlagsService,
  appService: AppService,
  location: RouteComponentProps['location'],
  history: RouteComponentProps['history'],
  buttonsRefs: any[],
  onAddToCart(hotel: IHotel, rate: PrepareRate): void,
  onAddToNote(hotel: IHotel, rate: PrepareRate): void,
  onRenderAmenities(ref: HTMLDivElement): void,
  onRenderRooms(ref: HTMLDivElement): void,
  openDatePickerTo(time: string): void,
  openDatePickerFrom(time: string): void,
  onUpdateFilters(field: string, value: { filterName: string, value: boolean | number[] }): void,
}

interface HotelState {
  checkin: Moment | null,
  checkout: Moment | null,
  customCheckin: Moment | null,
  customCheckout: Moment | null,
  sendingMessageToAdmin: boolean,
  hotel: IHotel | null,
  loadingRate: boolean,
  travelPolicyList: any,
  accountTravelPolicy: IAccountTravelPolicy,
  rightsBuyTrip: Rights,
  isMessageSend: boolean,
  disableToCartIfTPApply: boolean,
  filters: any,
  TripId: string | null,
  hotelsRecommended: any,
  staticRoomGroups: IRoomGroup[] | null,
  upsellFlags: string[],
  optimalRate?: string,
}

class Hotel extends Component<HotelProps, HotelState> {
  static defaultProps = {
    hotel: null,
    onAddToCart: () => {},
    onAddToNote: () => {},
    onAddToFavorite: () => {},
    onRenderAmenities: () => {},
    onRenderRooms: () => {},
    openDatePickerTo: () => {},
    openDatePickerFrom: () => {},
    nightsCount: '',
    isRecommended: false,
  };

  unsubscribeFn: () => void;
  unsubscribeHotelFn: () => void;
  unsubscribeChatFn: () => void;

  constructor(props: HotelProps) {
    super(props);

    const {
      featureFlagsService,
      hotelsService,
      chatService,
      userSessionService,
      workspaceService,
      hotel,
    } = props;

    const {
      checkin,
      checkout,
      customCheckin,
      customCheckout,
      optimalRate,
    } = hotelsService.getSearchState();

    const {
      loadingRate,
      isMessageSend,
      filters,
      hotelsRecommended,
      staticRoomGroups,
    } = hotelsService.getHotelState();

    const {
      sendingMessageToAdmin,
    } = chatService.get();

    const {
      travelPolicies,
    } = userSessionService.get();

    const {
      travelPolicy,
      rights,
    } = workspaceService;

    // @ts-ignore
    const { DisableToCartIfTPApply } = featureFlagsService.getFeatureFlags();
    const searchParams = parseSearchString(location.search);

    this.state = {
      checkin,
      checkout,
      customCheckin,
      customCheckout,
      sendingMessageToAdmin,
      hotel,
      loadingRate,
      travelPolicyList: travelPolicies,
      accountTravelPolicy: travelPolicy,
      rightsBuyTrip: rights,
      TripId: (searchParams?.tripId as string) ?? null,
      isMessageSend,
      disableToCartIfTPApply: DisableToCartIfTPApply || false,
      filters,
      hotelsRecommended,
      staticRoomGroups,
      upsellFlags: featureFlagsService.getUpsellFlags(),
      optimalRate,
    };
  }

  componentDidMount() {
    const { hotelsService, chatService } = this.props;

    this.unsubscribeFn = hotelsService.subscribeSearch(this.updateState);
    this.unsubscribeHotelFn = hotelsService.subscribeHotel(this.updateStateHotel);
    this.unsubscribeChatFn = chatService.subscribe(this.updateStateChat);
  }

  componentWillUnmount() {
    if (this.unsubscribeFn) this.unsubscribeFn();

    if (this.unsubscribeHotelFn) this.unsubscribeHotelFn();

    if (this.unsubscribeChatFn) this.unsubscribeChatFn();
  }

  updateState = (state: ISearchStore) => this.setState({
    checkin: state.checkin,
    checkout: state.checkout,
    customCheckin: state.customCheckin,
    customCheckout: state.customCheckout,
    optimalRate: state.optimalRate,
  });

  updateStateHotel = (state: IHotelStore) => this.setState({
    hotel: state.hotel,
    loadingRate: state.loadingRate,
    isMessageSend: state.isMessageSend,
    filters: state.filters,
    hotelsRecommended: state.hotelsRecommended,
    staticRoomGroups: state.staticRoomGroups,
  });

  updateStateChat = (state: any) => this.setState({
    sendingMessageToAdmin: state.sendingMessageToAdmin,
  });

  handleAddToCart = (rate: PrepareRate, room: PrepareRoomGroup, ind: number) => {
    const { hotel, onAddToCart, isRecommended } = this.props;
    const { TripId, upsellFlags, optimalRate } = this.state;
    const { Rates, PriceSortNum } = room;

    MainAnalytic.sendAmplitudeArrayArgs(
      MainAnalytic.ACTIONS.SEARCH.SEARCH_RESULTS_ADD_CART_BUTTON_PRESSED(ANALYTIC_SERVICE_TYPES.HOTEL),
    );

    if (upsellFlags.includes(UPSELL_EXPERIMENTS.FIRST)
    || upsellFlags.includes(UPSELL_EXPERIMENTS.SECOND)
    || upsellFlags.includes(UPSELL_EXPERIMENTS.THIRD)
    || upsellFlags.includes(UPSELL_EXPERIMENTS.FOURTH)) {
      const isRRVKtype = Rates.length > 1 && !isRecommended;
      const isRRVK = Rates.length > 1 && ind === 0 && !isRecommended;

      MainAnalytic.sendAmplitudeArrayArgs(
        MainAnalytic.ACTIONS.SEARCH.HOTEL_DETAIL_ADD_CART_BUTTON_PRESSED(
          getParamDetailAddCart(isRecommended || false, rate.IsOptimalRate),
          isRRVKtype ? YesNoType.yes : YesNoType.no,
          isRRVK ? YesNoType.yes : YesNoType.no,
          !optimalRate ? HotelSearchLocationType.hotel : HotelSearchLocationType.region,
          PriceSortNum,
        ),
      );
    }

    onAddToCart(hotel, { ...rate, TripId });
  };

  handleAddToNote = (rate: PrepareRate) => this.props.onAddToNote(this.props.hotel, { ...rate, TripId: this.state.TripId });

  // @ts-ignore
  handleChangeCount = (bookId: string, value: any) => this.props.hotelsService.setHotel(ACTIONS.UPDATERATECOUNTSELECT, { bookId, value }); // TODO НЕ так модель

  handleShowFormHotelSearchDialog = (room: OfflineRoomGroup) => this.props.hotelsService.showFormHotelSearchDialog(room, true);

  handleGallerySlideLeft = () => {
    MainAnalytic.sendGalleryEvent(MainAnalytic.CATEGORY.HOTELS, MainAnalytic.ACTIONS.HOTELS.SWITCHPHOTO, MainAnalytic.GALLERY.LEFT);
  };

  handleGallerySlideRight = () => {
    MainAnalytic.sendGalleryEvent(MainAnalytic.CATEGORY.HOTELS, MainAnalytic.ACTIONS.HOTELS.SWITCHPHOTO, MainAnalytic.GALLERY.RIGHT);
  };

  handleGalleryPreview = () => {
    MainAnalytic.sendGalleryEvent(MainAnalytic.CATEGORY.HOTELS, MainAnalytic.ACTIONS.HOTELS.SWITCHPHOTO, MainAnalytic.GALLERY.PREVIEW);
  };

  showRoomDetails = (renderFn: () => ReactNode) => {
    const { sidePanelService } = this.props;

    sidePanelService.setShow(true);
    sidePanelService.setRenderFn(renderFn);
  };

  handleChooseAnotherHotel = () => {
    // @ts-ignore
    const { hotel: { Name } } = this.state;

    MainAnalytic.send(
      MainAnalytic.CATEGORY.HOTELS,
      MainAnalytic.ACTIONS.HOTELS.HOTEL_REGION_SEARCH,
      {
        label: Name,
      },
    );
  };

  renderNoResults = (noResultLabel: string) => (
    <NoResults
      className={ styles['not-found'] }
      renderContent={ () => (
        <div className={ styles['content-wrapper'] }>
          <Text
            type='bold_24'
            qaAttr={ QA_ATTRIBUTES.hotels.noResult.notFoundHotels }
          >
            { noResultLabel }
          </Text>
        </div>
      ) }
    />
  );

  renderRecommendHotels = () => {
    const { hotelsService } = this.props;
    const {
      hotelsRecommended,
      hotel,
      checkin,
      customCheckin,
      customCheckout,
      checkout,
    } = this.state;
    const { travellersCount, adult, redirectSearchId, redirectSearchType } = hotelsService.getSearchState();

    if (!hotelsRecommended.length) return null;

    const nigthsCount = diffDays(checkin, checkout);
    const searchParamsHotel = getSearchParamsHotel({
      checkin,
      checkout,
      customCheckin,
      customCheckout,
      travellersCount,
      adult,
      redirectSearchId,
      redirectSearchType,
    });
    const searchParamsHotelString = stringifySearchParams(searchParamsHotel);

    return (
      <div className={ styles['recommended-hotels'] }>
        <Text
          type='bold_32'
          className={ styles.text }
        >
          { LABELS.RECOMMENDED_HOTELS_AROUND }
        </Text>
        <HotelCarousel
          items={ hotelsRecommended }
          currentHotelName={ hotel?.Name || '' }
          nightsCount={ nigthsCount }
          searchParams={ searchParamsHotelString }
          cardMarginRight={ HOTEL_CAROUSEL.CARD_MARGIN }
          cardsRow={ HOTEL_CAROUSEL.CARDS_ROW }
        />
      </div>
    );
  };

  renderNotFound = (noResultLabel: string) => {
    const {
      hotelsService,
      hotel: { RegionName, RegionId },
    } = this.props;
    const {
      checkin,
      customCheckin,
      customCheckout,
      checkout,
    } = this.state;
    const { travellersCount, adult } = hotelsService.getSearchState();

    const searchParamsCity = getSearchParamsCity({
      checkin,
      checkout,
      RegionId,
      RegionName,
      adult,
      travellersCount,
      customParams: hotelsService.prepareCustomTime(customCheckin, customCheckout, {
        from: 'time_from',
        to: 'time_to',
      }),
    });

    const searchParamsCityString = stringifySearchParams(searchParamsCity);

    return (
      <div className={ styles['not-found'] }>
        { this.renderNoResults(noResultLabel) }
        { this.renderRecommendHotels() }
        <Link
          to={ `/search/hotels/region?${searchParamsCityString}` }
          target='_blank'
        >
          <Button
            type='textual'
            className={ styles['another-hotel'] }
            onClick={ this.handleChooseAnotherHotel }
          >
            { LABELS.CHOOSE_ANOTHER_HOTEL(RegionName) }
          </Button>
        </Link>
      </div>
    );
  };

  renderRoomGroup() {
    const {
      hotelsService,
      location,
      history,
      isRecommended,
      appService,
      featureFlagsService: {
        getReservedHotelSmartagent,
      },
    } = this.props;
    const {
      hotel,
      loadingRate,
      rightsBuyTrip,
      accountTravelPolicy,
      travelPolicyList,
      customCheckin,
      customCheckout,
      checkin,
      checkout,
      sendingMessageToAdmin,
      isMessageSend,
      disableToCartIfTPApply,
      filters: {
        online,
      },
      staticRoomGroups,
      upsellFlags,
    } = this.state;

    const { errorHotelResearch } = hotelsService.getHotelState();

    const isOfflineRate = staticRoomGroups?.some(({ Rates }) => Rates.some(({ Offline }) => Offline));
    const isOnlineRate = staticRoomGroups?.some(({ Rates }) => Rates.some(({ Offline }) => !Offline));

    if (loadingRate) {
      return (
        <div className={ styles.loading }>
          <DotLoading />
          <Text
            qaAttr={ QA_ATTRIBUTES.hotels.search.checkRooms }
            type='NORMAL_16_140'
            className={ styles.text }
          >
            { LABELS.LOADER.FIRST_PART }<br />
            { LABELS.LOADER.SECOND_PART }
          </Text>
        </div>
      );
    }

    if (errorHotelResearch) {
      return (
        <div className={ styles.loading }>
          <Text
            type='SEMIBOLD_16'
            className={ styles['text-error'] }
          >
            { LABELS.LOADER.ERROR_SELECTED_HOTEL }
          </Text>
          <Icon type='warning'/>
        </div>
      );
    }

    if (staticRoomGroups && !isOnlineRate && isOfflineRate && online) {
      return this.renderNotFound(LABELS.NOT_FOUND_HOTELS_ONLINE);
    }

    if (!hotel?.RoomGroups.length) {
      return this.renderNotFound(LABELS.NOT_FOUND_HOTELS);
    }

    const searchSettings = {
      customCheckin,
      customCheckout,
      daysCount: checkout?.diff(checkin, 'days'),
      checkin,
      checkout,
    };

    const chatState = {
      sendingMessageToAdmin,
    };

    return hotel.RoomGroups.map((room, ind) => {
      const rateIncludesOptimalRate = !!room.Rates.find(({ IsOptimalRate }) => IsOptimalRate);

      if (isRecommended && !rateIncludesOptimalRate) {
        return null;
      }

      return (
        <RoomGroup
          isRecommended={ isRecommended }
          location={ location }
          history={ history }
          key={ `${room.Name}-${room.RoomType}-${ind}` }
          index={ ind }
          room={ room }
          hotel={ hotel }
          onToCart={ (rate, index) => this.handleAddToCart(rate, room, index) }
          onToNote={ this.handleAddToNote }
          onChangeCount={ this.handleChangeCount }
          onSendRequest={ this.handleShowFormHotelSearchDialog }
          accountTravelPolicy={ accountTravelPolicy }
          isMessageSend={ isMessageSend }
          disableToCartIfTPApply={ disableToCartIfTPApply }
          travelPolicyList={ travelPolicyList }
          showPriceDetails={ hotelsService.isShowPriceDetails() }
          rightsBuyTrip={ rightsBuyTrip }
          upsellFlags={ upsellFlags }
          onGallerySlideLeft={ this.handleGallerySlideLeft }
          onGallerySlideRight={ this.handleGallerySlideRight }
          onGalleryPreview={ this.handleGalleryPreview }
          // @ts-ignore
          searchSettings={ searchSettings }
          chatState={ chatState }
          onShowRoomDetails={ this.showRoomDetails }
          getReservedHotelSmartagent={ getReservedHotelSmartagent() }
          qaAttrContainer={ QA_ATTRIBUTES.hotels.current.room.wrapper }
          appService={ appService }
        />
      );
    });
  }

  renderFilters = () => {
    const { filters, loadingRate } = this.state;
    const { onUpdateFilters, isRecommended } = this.props;

    if (isRecommended) return null;

    return (
      <Filters
        filters={ filters }
        updateFilters={ onUpdateFilters }
        disabled={ loadingRate }
      />
    );
  };

  renderCustomTimeSelect = () => {
    const { openDatePickerFrom, openDatePickerTo, buttonsRefs, isRecommended } = this.props;
    const { customCheckin, customCheckout } = this.state;

    if (isRecommended) return null;

    const textTo = customCheckin
      ? LABELS.EARLY_IN(customCheckin.format(DATEFORMATS.TIME))
      : LABELS.ADD_EARLY_IN;
    let textFrom;

    if (customCheckout) {
      textFrom = LABELS.LATE_OUT_WITH_DATE(customCheckout.format(DATEFORMATS.TIME));
    } else {
      textFrom = customCheckin
        ? LABELS.ADD_LATE_OUT
        : LABELS.LATE_OUT;
    }

    return (
      <div className={ styles['custom-time-wrapper'] }>
        <Icon type='plusRound' />
        <Button
          qaAttr={ QA_ATTRIBUTES.hotels.current.earlyIn }
          type='textual'
          className={ styles.item }
          onClick={ () => openDatePickerFrom(TIME) }
          ref={ buttonsRefs[0] }
        >
          { textTo }
        </Button>
        <div className={ `${styles.divider} ${styles.item}` } />
        <Button
          qaAttr={ QA_ATTRIBUTES.hotels.current.lateOut }
          type='textual'
          className={ styles.item }
          onClick={ () => openDatePickerTo(TIME) }
          ref={ buttonsRefs[1] }
        >
          { textFrom }
        </Button>
      </div>
    );
  };

  renderImportantInfo = () => {
    const { hotel: { TaxInfo }, isRecommended } = this.props; // TODO TaxInfo === undefined ??

    if (!TaxInfo || isRecommended) {
      return null;
    }

    // @ts-ignore
    const preparedTaxInfo = TaxInfo.split('   ').reduce((r, s) => {
      if (!s) {
        return r;
      }

      return [...r, s.trim()];
    }, []);

    // @ts-ignore
    const preparedTaxInfoHtml = preparedTaxInfo.map((s, i) => (<Text type='NORMAL_16_140' key={ i }>{s}</Text>));

    return (
      <StyledWrapper className={ styles['tax-info-wrapper'] }>
        <Text className={ styles.title } type='bold_24' >
          { LABELS.PRIMARY_INFO }
        </Text>
        { preparedTaxInfoHtml }
      </StyledWrapper>
    );
  };

  renderDefaultCheckinCheckoutInfo = () => {
    const { onRenderAmenities, isRecommended } = this.props;
    const { hotel } = this.state;

    if (isRecommended) return null;

    return (
      <HotelCheckinCheckoutPanel
        checkin={ hotel?.CheckInTime as string }
        checkout={ hotel?.CheckOutTime as string }
        onRender={ onRenderAmenities }
      />
    );
  };

  renderIsContractHtml = () => {
    const { hotel, checkin, checkout } = this.state;

    const findIsContract = hotel?.RoomGroups ?
      hotel?.RoomGroups.find(({ IsContract }) => IsContract)
      : null;

    let name = '';
    let secondName = '';

    if (findIsContract) {
      name = LABELS.CORPORATE;
      secondName = LABELS.WARNING;
    }

    if (hotel?.IsAvailableContract && !findIsContract && hotel?.RoomGroups.length) {
      name = `${LABELS.DO_NOT_WORK} ${formatDate(checkin, DATEFORMATS.DAY_MONTH)} - ${formatDate(checkout, DATEFORMATS.DAY_MONTH)}`;
      secondName = LABELS.STANDARD;
    }

    return name && (
      <div className={ styles.contract }>
        <Icon type='info' className={ styles.icon } color='green' />
        <Text type='NORMAL_16_140' >
          <Text type='NORMAL_16_130' className={ styles.text } >
            { name }
            <br />
            { secondName }
          </Text>
        </Text>
      </div>
    );
  };

  renderHeader = () => {
    const { hotel } = this.state;
    const { isRecommended } = this.props;

    const hotelIncludesRecommendRates = hotel && !!hotel.RoomGroups.find((rate) => (
      rate.Rates.find(({ IsOptimalRate }) => IsOptimalRate)));

    if (isRecommended && !hotelIncludesRecommendRates) return null;

    const label = isRecommended ? LABELS.OPTIMAL_CHOISE : LABELS.AVAILABLE_ROOMS;

    return (
      <div className={ styles.title }>
        <Text
          type='bold_32'
          qaAttr={ QA_ATTRIBUTES.hotels.current.availableRooms }
        >
          { label }
        </Text>
        { this.renderCustomTimeSelect() }
      </div>
    );
  };

  renderHotelServices = () => {
    const { hotel } = this.state;
    const { isRecommended } = this.props;

    if (!hotel || isRecommended) return null;

    return (
      <div className={ styles.amenities }>
        <HotelServices hotel={ hotel } />
      </div>
    );
  };

  render() {
    const { onRenderRooms } = this.props;

    return (
      <div className={ styles.wrapper } ref={ onRenderRooms } >
        { this.renderHeader() }
        <div className={ styles.filters }>
          { this.renderFilters() }
        </div>
        { this.renderIsContractHtml() }
        <div className={ styles.rates }>
          { this.renderRoomGroup() }
        </div>
        { this.renderDefaultCheckinCheckoutInfo() }
        { this.renderImportantInfo() }
        { this.renderHotelServices() }
      </div>
    );
  }
}

export { Hotel };
