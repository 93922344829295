import React, { ReactNode, useRef } from 'react';

import { Suggest, Input, IconButton } from 'new-ui';
import { SuggestItem } from 'new-ui/src/components/Suggest/types';
import { getText } from '../../../../../i18n';

import { ClearButton } from '../../../../components/ClearButton';
import { FieldLabel } from '../../../../components/FieldLabel';

import { SERVICETYPE } from '../../../../bi/constants/serviceType';
import { QA_ATTRIBUTES } from '../../../../bi/constants/attributesForTests';

import {
  BonusesType,
  IEmployee,
  TAirBonuses,
  TAirlines,
} from '../../../../bi/services/employee/types';

import styles from './index.module.css';

const LABELS = {
  BONUS_CART_OF_AIRLINE: getText('settings:employees.employee.steps.bonuses.bonusCartOfAirline'),
  AIRLINE: getText('settings:employees.employee.steps.bonuses.airline'),
  NUMBER_OF_BONUS: getText('settings:employees.employee.steps.bonuses.numberOfBonus'),
  FPK_BONUS_CART: getText('settings:employees.employee.steps.bonuses.fpkBonusCart'),
  FPK_BONUS_CART_UNIVERSAL: getText('settings:employees.employee.steps.bonuses.fpkBonusCartUniversal'),
  FPK_BONUS_CART_BUSINESS: getText('settings:employees.employee.steps.bonuses.fpkBonusCartBusiness'),
  ADD_CART: getText('settings:employees.employee.steps.bonuses.addCart'),
};

const { employee: { documents: { additionally } } } = QA_ATTRIBUTES;

interface IBonuses {
  employee: IEmployee,
  airlines: TAirlines[],
  errors: any,
  onAirlineSuggestSelected(item: SuggestItem, value: number): void,
  onGetAirlineSuggests(query: string, i: number): void,
  onBonusChange(v: string, i: number): void,
  onDeleteBonus(i: number): string,
  onAddBonus(): void,
}

const Bonuses = ({
  employee,
  airlines,
  errors,
  onAirlineSuggestSelected,
  onGetAirlineSuggests,
  onBonusChange,
  onDeleteBonus,
  onAddBonus,
}: IBonuses) => {
  const inputRef: React.MutableRefObject<any> = useRef(null);

  const getCurrentText = (type: string) => {
    switch (type) {
      case SERVICETYPE.TRAIN_UNIVERSAL:
        return LABELS.FPK_BONUS_CART_UNIVERSAL;
      case SERVICETYPE.TRAIN_BUSINESS:
        return LABELS.FPK_BONUS_CART_BUSINESS;
      default:
        return LABELS.FPK_BONUS_CART;
    }
  };

  const handleAirlineSuggestSelected = (suggest: SuggestItem, index: number) => {
    if (suggest !== null) {
      onAirlineSuggestSelected(suggest, index);
    }

    inputRef.current.focus();
  };

  const renderSuggestItem = (name: string, code: string): ReactNode => (
    <>
      { name }
      { ' — ' }
      { code }
    </>
  );

  const getHighlightedString = (text: string, index: number, query: string) => (
    <span>
      { text.substring(0, index) }
      <b>{ text.substring(index, index + query.length) }</b>
      { text.substring(index + query.length) }
    </span>
  );

  const highlightWordStart = (text: string, query: string) => {
    const lowString = text.toLowerCase();
    const lowQuery = query.toLowerCase();

    const findIndex = lowString.indexOf(lowQuery);

    return findIndex > -1
      ? getHighlightedString(text, findIndex, query)
      : text;
  };

  const renderAirlineSuggestions = ({ Name, Code }: { Name: string, Code: string }, query: string) => {
    if (!query) {
      return renderSuggestItem(Name, Code);
    }

    const name = highlightWordStart(Name, query);
    const code = highlightWordStart(Code, query);

    return renderSuggestItem(name as string, code as string);
  };

  const renderAirlineBonuses = ({ Code, airlineSuggestions, Number }: TAirBonuses, i: number) => {
    const nameAirlines = !!Code.length && !!airlines.length
      ? airlines.find(({ Code: airCode }) => airCode === Code)?.Name
      : null;

    const value = nameAirlines || airlineSuggestions.label;

    return (
      <>
        <FieldLabel
          text={ LABELS.BONUS_CART_OF_AIRLINE }
        />
        <div className={ styles.row }>
          <div className={ `${styles.item} ${styles.suggest}` }>
            <Suggest
              theme='border'
              withLabel={ false }
              placeholder={ LABELS.AIRLINE }
              value={ value }
              // @ts-ignore
              items={ airlineSuggestions?.suggestions || [] }
              onSelect={ suggest => handleAirlineSuggestSelected(suggest, i) }
              onChange={ query => onGetAirlineSuggests(query, i) }
              // @ts-ignore
              renderItem={ item => renderAirlineSuggestions(item, value) }
              inputClassName={ styles.input }
              contentClassName={ styles['input-content'] }
              shouldFocusItem={ () => false }
              qaAttr={ additionally.bonusCartAirline }
            />
          </div>
          <div className={ styles.item }>
            <Input
              ref={ inputRef }
              value={ Number }
              placeholder={ LABELS.NUMBER_OF_BONUS }
              onChange={ (v: string) => onBonusChange(v, i) }
              qaAttr={ additionally.numberOfBonus }
            />
          </div>
          <ClearButton qaAttr={ additionally.deleteBonus } onClick={ () => onDeleteBonus(i) } />
        </div>
      </>
    );
  };

  const renderFpkBonus = (bonus: BonusesType, i: number) => {
    const { Number, Type } = bonus;

    return (
      <div className={ styles.row }>
        <div className={ styles.item }>
          <FieldLabel
            text={ getCurrentText(Type) }
          />
          <Input
            value={ Number || '' }
            onChange={ v => onBonusChange(v, i) }
            error={ errors.CardsBonuses[i] }
            max={ 13 }
            qaAttr={ additionally.fpkBonusCart }
          />
        </div>
      </div>
    );
  };

  const html = employee.Bonuses.map((bonus, i) => {
    const bonusHtml = bonus.Code === 'FPK'
      ? renderFpkBonus(bonus, i)
      : renderAirlineBonuses(bonus, i);

    return (
      <div key={ i } className={ styles.bonus }>
        { bonusHtml }
      </div>
    );
  });

  const addButton = (
    <IconButton
      iconType='plusRound'
      onClick={ onAddBonus }
      className={ styles.button }
      theme='default-red'
      qaAttr={ additionally.addCart }
    >
      { LABELS.ADD_CART }
    </IconButton>
  );

  return (
    <div className={ styles.main }>
      { html }
      { addButton }
    </div>
  );
};

export { Bonuses };
