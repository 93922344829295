// @ts-nocheck
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import * as queryString from 'query-string';

import { Dialog, Button, Text, PROPS, PageLoader, BackLink } from 'new-ui';
import { getText } from '../../../i18n';

import { withStores } from '../../bi/context';
import { MOBX_STORES } from '../../bi/context/stores';

import NoteBookingWrap from './components/NoteWrap';
import { WarningBlock } from '../../components/WarningBlock';

import { MainAnalytic } from '../../bi/utils/analytics';
import getAccountId from '../../bi/utils/getAccountId';
import { isSmartAgent } from '../../bi/utils/env';
import MoneyFormat from '../../bi/utils/money';
import { getCountOfTravellers, s7BonusCardWarning } from '../../bi/utils/cart';

import ROUTES from '../../bi/constants/routes';
import { QA_ATTRIBUTES } from '../../bi/constants/attributesForTests';
import SERVICETYPE from '../../bi/constants/serviceType';

import styles from './styles/index.module.css';

const BOOKING_ERRORS_CODES = {
  PRICE_CHANGED: 'ReservedWithPriceChange',
};

const LABELS = {
  BOOKING_MESSAGES: {
    AVIA: {
      SOLD_OUT: (price) => getText('note:booking.bookingMessages.soldOut', { price }),
      SUCCESS: (trip) => getText('note:booking.bookingMessages.success', { trip }),
      ERROR: (trip) => getText('note:booking.bookingMessages.error', { trip }),
    },
    HOTEL: {
      SOLD_OUT: (price) => getText('note:booking.bookingMessagesHotel.soldOut', { price }),
      SUCCESS: (trip) => getText('note:booking.bookingMessagesHotel.success', { trip }),
      ERROR: getText('note:booking.bookingMessagesHotel.error'),
    },
  },
  TITLE: (name: string) => getText('note:booking.title', { name }),
  TITLE_HOTEL: (name: string) => getText('note:booking.titleHotel', { name }),
  DESCRIPTION: {
    FIRST: getText('note:booking.description.first'),
    SECOND: getText('note:booking.description.second'),
    DESCRIPTION_HOTEL: getText('note:booking.descriptionHotel'),
  },
  BACK_TO_NOTE: getText('note:booking.backToNote'),
  TO_NOTE: getText('note:booking.toNote'),
  YES: getText('note:booking.yes'),
  NO: getText('note:booking.no'),
  BONUS_CARD_S7_WARNING: getText('cart:s7BonusCardWarning'),
  ERROR_MESSAGE: getText('note:booking.errorMessage'),
  ERROR_MESSAGE_DESCR: getText('note:booking.errorMessageDescr'),
};

@withStores([MOBX_STORES.SETTINGS_STORE])
@observer
class NoteBooking extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    notepadService: PropTypes.object.isRequired,
    employeeService: PropTypes.object.isRequired,
    settingsService: PropTypes.object.isRequired,
    workspaceService: PropTypes.object.isRequired,
    userSessionService: PropTypes.object.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
    accountSettingsService: PropTypes.object.isRequired,
    tripTagsService: PropTypes.object.isRequired,
    hotelService: PropTypes.object.isRequired,
    stores: PropTypes.shape({
      settingsStore: PropTypes.object.isRequired,
    }),
  };

  getPersonalBonusCards = this.props.accountSettingsService.getPersonalBonusCards();

  state = {
    accountId: getAccountId(),
    loading: true,
    booking: false,
    item: null,
    companiesWithCostCenter: [],
    itemData: null,
    itemMeta: null,
    bookingDialog: false,
    bookingMsg: '',
    nameTrip: '',
    projects: [],
    documentType: this.props.userSessionService.get().enums.documents,
    mandatoryProject: false,
    buyingEmployee: null,
    optionalProject: this.props.featureFlagsService.getFeatureFlags().OptionalProject || false,
    isSuccessMsg: false,
    isError: false,
  };

  componentDidMount() {
    const { tripTagsService } = this.props;

    tripTagsService.loadAccountTripTags();
    this.loadData();
  }

  loadData = async () => {
    const { notepadService, settingsService, match } = this.props;
    const { id } = match.params;
    const { accountId } = this.state;

    this.unsubscribeFn = notepadService.subscribeBooking(this.updateState);
    await settingsService.getCompaniesWithCostCenter(accountId);

    return notepadService.loadBookingItem(id);
  };

  componentWillUnmount() {
    this.unsubscribeFn();
  }

  updateState = async (state) => {
    const {
      workspaceService,
      notepadService,
      settingsService,
      userSessionService,
      stores: {
        settingsStore: {
          employees,
          companiesWithCostCenter,
        },
      },
    } = this.props;
    const { buyingEmployee } = this.state;

    let employee = buyingEmployee;

    if (workspaceService.canBuyOnlySelf && !buyingEmployee) {
      await settingsService.getEmployeesList();
      employee = employees.find(({ Id }) => Id === workspaceService.rights.EmployeeId);
    }

    const data = JSON.parse(state.item.Data);
    const { enums: { documents }, projects } = userSessionService.get();
    const mandatoryProject = projects.length
      ? companiesWithCostCenter.some(company => company === state.item.CompanyId)
      : false;

    this.setState({
      buyingEmployee: employee,
      loading: state.loading,
      item: state.item,
      itemData: data,
      itemMeta: state.item.ServiceType === SERVICETYPE.AIR ? data.Metadata : null,
      companiesWithCostCenter,
      mandatoryProject: mandatoryProject || false,
      nameTrip: state.item.ServiceType === SERVICETYPE.AIR ? notepadService.getAirTicketName(state.item) : '',
      documentType: documents,
      projects,
      isError: state.isError,
    });
  };

  cancelBooking = async () => {
    const { match: { params: { id } }, notepadService } = this.props;

    await notepadService.cancelBooking(id);
    this.handleMoveToNotepad();
  };

  getSuccessBookMsg = () => {
    const { nameTrip, item: { ServiceType } } = this.state;

    return (
      ServiceType === SERVICETYPE.AIR
        ? LABELS.BOOKING_MESSAGES.AVIA.SUCCESS(nameTrip)
        : LABELS.BOOKING_MESSAGES.HOTEL.SUCCESS(nameTrip)
    );
  };

  handleConfirmBooking = () => {
    const { item, item: { ServiceType }, nameTrip } = this.state;

    const soldOutTitle = (value) => (
      ServiceType === SERVICETYPE.AIR
        ? LABELS.BOOKING_MESSAGES.AVIA.SOLD_OUT(value)
        : LABELS.BOOKING_MESSAGES.HOTEL.SOLD_OUT(value)
    );

    const errorTitle = (value) => (
      ServiceType === SERVICETYPE.AIR
        ? LABELS.BOOKING_MESSAGES.AVIA.ERROR(value)
        : LABELS.BOOKING_MESSAGES.HOTEL.ERROR
    );

    this.setState({
      booking: true,
    }, () => {
      this.props.notepadService.booking(item.Id)
        .then(async (status) => {
          if (status === BOOKING_ERRORS_CODES.PRICE_CHANGED) {
            const { Price } = await this.loadData();

            return this.setState({
              bookingDialog: true,
              bookingMsg: soldOutTitle(MoneyFormat.money(Price, true)),
              bookingErrCode: status,
            });
          }

          this.setState({
            bookingDialog: true,
            bookingMsg: this.getSuccessBookMsg(),
            isSuccessMsg: true,
          });

          MainAnalytic.send(MainAnalytic.CATEGORY.NOTE, MainAnalytic.ACTIONS.NOTE.BOOKING);

          return null;
        })
        .catch(() => {
          this.setState({
            bookingDialog: true,
            bookingMsg: errorTitle(nameTrip),
          });
        });
    });
  };

  handleMoveToNotepad = () => {
    const { isError } = this.state;
    const { notepadService } = this.props;

    if (isError) {
      notepadService.resetError();
    }

    this.props.history.push({
      pathname: ROUTES.NOTE.MAIN,
    });
  };

  handleApplyPriceChange = () => {
    this.setState({
      bookingMsg: this.getSuccessBookMsg(),
      bookingErrCode: null,
      isSuccessMsg: true,
    });
  };

  redirectToEmployee = (employee) => {
    const { item } = this.state;
    const { history } = this.props;

    if (isSmartAgent && !employee) {
      const travelers = true;

      return history.push({
        pathname: ROUTES.EMPLOYEE,
        search: queryString.stringify({ noteItemId: item.Id, travelers }),
      });
    }

    if (employee) {
      return history.push({ pathname: `${ROUTES.EMPLOYEE}${employee.Id}` });
    }

    return history.push({
      pathname: ROUTES.EMPLOYEE,
      search: queryString.stringify({ noteItemId: item.Id }),
    });
  };

  renderPage() {
    const {
      notepadService,
      employeeService,
      workspaceService,
      tripTagsService,
      featureFlagsService,
      accountSettingsService,
      history,
      settingsService,
      hotelService,
    } = this.props;
    const {
      item,
      item: {
        ServiceType,
      },
      documentType,
      itemData,
      itemMeta,
      booking,
      nameTrip,
      projects,
      mandatoryProject,
      buyingEmployee,
      optionalProject,
      companiesWithCostCenter,
    } = this.state;

    const countTravelers = ServiceType === SERVICETYPE.AIR
      ? itemMeta?.TravellersCount
      : getCountOfTravellers(ServiceType, itemData);

    const title = ServiceType === SERVICETYPE.AIR
      ? LABELS.TITLE(nameTrip)
      : LABELS.TITLE_HOTEL(itemData.hotel.Name);

    const warningBlockHtml = () => {
      if (!s7BonusCardWarning([item])) {
        return null;
      }

      return (
        <WarningBlock
          text={ LABELS.BONUS_CARD_S7_WARNING }
          type='SEMIBOLD_16'
          color='red'
        />
      );
    };

    const renderDescription = () => {
      if (ServiceType === SERVICETYPE.HOTEL) {
        return <Text type='NORMAL_16_130'> { LABELS.DESCRIPTION.DESCRIPTION_HOTEL } </Text>;
      }

      return (
        <>
          <Text type='NORMAL_16_130'>{ LABELS.DESCRIPTION.FIRST }</Text>
          <Text type='NORMAL_16_130'>{ LABELS.DESCRIPTION.SECOND }</Text>
        </>
      );
    };

    return (
      <div className={ styles.content }>
        <div className={ styles.header }>
          <Text
            type={ PROPS.TEXT.TYPES.BOLD_32 }
            qaAttr={ QA_ATTRIBUTES.note.booking.title }
          >
            { title }
          </Text>
          <div className={ styles.description }>
            { renderDescription() }
          </div>
        </div>
        { warningBlockHtml() }
        <NoteBookingWrap
          featureFlagsService={ featureFlagsService }
          accountSettingsService={ accountSettingsService }
          employees={ item.Employees }
          documentType={ documentType }
          employeesMax={ countTravelers }
          item={ item }
          mandatoryProject={ mandatoryProject }
          companiesWithCostCenter={ companiesWithCostCenter }
          optionalProject={ optionalProject }
          data={ itemData }
          meta={ itemMeta }
          projects={ projects }
          bookingProcess={ booking }
          isMulti={ workspaceService.isMultiCompany() }
          isPersonalBonusCards={ this.getPersonalBonusCards }
          notepadService={ notepadService }
          employeeService={ employeeService }
          buyingEmployee={ buyingEmployee }
          workspaceService={ workspaceService }
          tripTagsService={ tripTagsService }
          onConfirmBooking={ this.handleConfirmBooking }
          redirectToEmployee={ this.redirectToEmployee }
          history={ history }
          settingsService={ settingsService }
          hotelService={ hotelService }
        />
      </div>
    );
  }

  renderDialogActions = () => {
    const { bookingErrCode } = this.state;

    if (!bookingErrCode) {
      return (
        <Button
          type={ PROPS.BUTTON.TYPES.SECONDARY }
          onClick={ this.handleMoveToNotepad }
          qaAttr={ QA_ATTRIBUTES.note.booking.dialog.buttonToNote }
        >
          { LABELS.BACK_TO_NOTE }
        </Button>
      );
    }

    if (bookingErrCode === BOOKING_ERRORS_CODES.PRICE_CHANGED) {
      return (
        <div className={ styles.decisions }>
          <Button
            theme={ PROPS.BUTTON.TYPES.PRIMARY }
            onClick={ this.handleApplyPriceChange }
          >
            { LABELS.YES }
          </Button>
          <Button
            type={ PROPS.BUTTON.TYPES.TEXTUAL_MEDIUM }
            onClick={ this.cancelBooking }
            className={ styles.link }
          >
            { LABELS.NO }
          </Button>
        </div>
      );
    }

    return null;
  };

  renderDialog() {
    const { bookingMsg, bookingDialog, bookingErrCode, isSuccessMsg } = this.state;

    if (!bookingDialog) {
      return null;
    }

    const handleClose = () => (
      bookingErrCode === BOOKING_ERRORS_CODES.PRICE_CHANGED
        ? this.cancelBooking()
        : this.handleMoveToNotepad()
    );

    const qaAttrMsg = isSuccessMsg ? QA_ATTRIBUTES.note.booking.dialog.successMessage : '';

    return (
      <Dialog
        show
        onChange={ (value) => {
          if (!value) {
            handleClose();
          }
        } }
      >
        <div className={ styles.dialog }>
          <Text type={ PROPS.TEXT.TYPES.BOLD_20 } qaAttr={ qaAttrMsg }>{ bookingMsg }</Text>
          <div className={ styles.buttons }>
            { this.renderDialogActions() }
          </div>
        </div>
      </Dialog>
    );
  }

  renderErrorDialog = () => {
    const { isError } = this.state;

    if (!isError) {
      return null;
    }

    return (
      <Dialog
        show
        onChange={ this.handleMoveToNotepad }
      >
        <div className={ styles.dialog }>
          <Text type={ PROPS.TEXT.TYPES.BOLD_20 }>{ LABELS.ERROR_MESSAGE }</Text>
          <Text type={ PROPS.TEXT.TYPES.BOLD_20 }>{ LABELS.ERROR_MESSAGE_DESCR }</Text>
          <div className={ styles.buttons }>
            <Button
              type={ PROPS.BUTTON.TYPES.SECONDARY }
              onClick={ this.handleMoveToNotepad }
              qaAttr={ QA_ATTRIBUTES.note.booking.dialog.buttonToNote }
            >
              { LABELS.BACK_TO_NOTE }
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  render() {
    const { loading } = this.state;

    if (loading) {
      return <PageLoader />;
    }

    return (
      <div
        className={ styles.wrap }
      >
        <BackLink
          text={ LABELS.TO_NOTE }
          link={ ROUTES.NOTE.MAIN }
          className={ styles.back }
          alternativeDesign={ isSmartAgent }
        />
        { this.renderPage() }
        { this.renderDialog() }
        { this.renderErrorDialog() }
      </div>
    );
  }
}

export default NoteBooking;
