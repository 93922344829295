import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { UseStoresInterface, withStores } from '../../../../bi/context';
import { MOBX_STORES } from '../../../../bi/context/stores';

import { isSmartAgent } from '../../../../bi/utils/env';
import { MainAnalytic } from '../../../../bi/utils/analytics';
import { ANALYTICS_AGENT_MODE, DEFAULTMENUTYPE } from '../../../../bi/constants/app';

import MainHeader from '../../../../components/Header';
import { MainMenu } from '../../../../components/Menu/AppMenu';

import Airline from '../../../../bi/services/airline';
import WorkspaceService from '../../../../bi/services/workspace';
import AppService from '../../../../bi/services/app';
import FeatureFlagsService from '../../../../bi/services/featureFlags';
import ChatService from '../../../../bi/services/chat';
import SmartdeskService from '../../../../bi/services/smartdesk';
import PaymentService from '../../../../bi/services/payment';
import ReportService from '../../../../bi/services/report';
import BonusProgramService from '../../../../bi/services/bonusProgram';
import { UiSettingsProtocol } from '../../../../bi/protocols/uiSettings';
import { ProductAnalytics } from '../../../../bi/protocols/productAnalytics';

import { clearSentryContext } from '../../../../../utils/sentry';

import {
  IApprove,
  ICompanyFunds,
  IExtendedMenuItem,
  IHeaderShort,
  IMenuItems,
} from '../../../../bi/types/app';
import { Rights } from '../../../../bi/types/workspace';
import { ILocation } from '../../../../bi/types/transfer';

import styles from './styles/index.module.css';

const TRIPS = 'Поездки';

const SMARTDESK = 'smartdesc';

interface IStores extends Pick<UseStoresInterface, 'uiSettingsStore'> {}

interface IAppHeaderProps {
  appService: AppService;
  workspaceService: WorkspaceService;
  featureFlagsService: FeatureFlagsService;
  chatService: ChatService;
  reportService: ReportService;
  bonusProgramService: BonusProgramService;
  smartdeskService: SmartdeskService;
  paymentService: PaymentService;
  uiSettingsProtocol: UiSettingsProtocol;
  productAnalyticsProtocol: ProductAnalytics;
  stores: IStores;
  onLogout(): void;
  airlineService: Airline,
  history: RouteComponentProps['history'];
  location: ILocation;
}

interface AppHeaderState {
  menuItems: IMenuItems[];
  cartMenuItem: IExtendedMenuItem;
  approve: IApprove;
  requestItemsCount: number;
  analyticsBadge: boolean;
  account: {
    rights: Rights;
    ShortCompanyName: string;
  },
  header: {
    balances: ICompanyFunds[];
    noteItemsCount: number;
    favoriteItemsCount: number;
  },
  requestsCount?: number;
  agentMode: boolean;
}

@withStores([MOBX_STORES.UI_SETTINGS])
class AppHeader extends Component<IAppHeaderProps, AppHeaderState> {
  unsubscribeFn: () => void;

  constructor(props: IAppHeaderProps) {
    super(props);

    const {
      appService,
      workspaceService,
      uiSettingsProtocol: {
        isViewAnalytics,
        getAppMenuItemByRights,
      },
      stores,
    } = props;

    const {
      uiSettingsStore: {
        settings: {
          showReports,
          showChartsAnalytics,
          showTabReportsWithoutFinanceRights: showTabReports,
          showTaxi,
        },
      },
    } = stores;

    const {
      header: {
        CartItemsCount,
        Approve,
        CompanyFunds,
        NoteItemsCount,
        FavoriteCount,
        RequestItemsCount,
        AnalyticsBadge,
      },
      agentMode,
    } = appService.get();

    const workspace = workspaceService.get();

    const isShowTaxi = showTaxi && !isSmartAgent;

    this.state = {
      menuItems: getAppMenuItemByRights({
        ...workspaceService.rights,
        showReports,
        showTabReports,
        showTaxi: isShowTaxi,
        isViewAnalytics,
        showChartsAnalytics,
      }),
      cartMenuItem: {
        ...appService.cartMenuItem(),
        count: CartItemsCount,
      },
      approve: Approve,
      requestItemsCount: RequestItemsCount,
      analyticsBadge: AnalyticsBadge?.Show,
      account: {
        rights: workspace.Rights,
        ShortCompanyName: workspace.ShortCompanyName,
      },
      header: {
        balances: CompanyFunds,
        noteItemsCount: NoteItemsCount,
        favoriteItemsCount: FavoriteCount,
      },
      agentMode,
    };
  }

  async componentDidMount() {
    const {
      appService,
      bonusProgramService,
    } = this.props;

    bonusProgramService.loadBonuses();

    this.unsubscribeFn = appService.subscribe(this.updateState);
  }

  componentWillUnmount() {
    this.unsubscribeFn();
  }

  updateState = ({
    header: {
      CartItemsCount,
      Approve,
      CompanyFunds,
      NoteItemsCount,
      FavoriteCount,
      RequestItemsCount,
      AnalyticsBadge,
    },
    agentMode,
  }: IHeaderShort) => {
    const workspace = this.props.workspaceService.get();

    this.setState({
      ...this.state,
      cartMenuItem: {
        ...this.state.cartMenuItem,
        count: CartItemsCount,
      },
      approve: Approve,
      requestsCount: RequestItemsCount,
      analyticsBadge: AnalyticsBadge?.Show,
      account: {
        rights: workspace.Rights,
        ShortCompanyName: workspace.ShortCompanyName,
      },
      header: {
        balances: CompanyFunds,
        noteItemsCount: NoteItemsCount,
        favoriteItemsCount: FavoriteCount,
      },
      agentMode,
    });
  };

  handleMenuClick = (item: { title: string, count: number, type: string }) => {
    MainAnalytic.send(MainAnalytic.CATEGORY.MENU, MainAnalytic.ACTIONS.MENU.SELECTION, {
      label: item.title,
      value: item.count || 0,
    });

    if (item.title === TRIPS) {
      MainAnalytic.send(MainAnalytic.CATEGORY.TRIPS, MainAnalytic.ACTIONS.TRIPS.VIEWLIST);
    }

    if (item.type === SMARTDESK) {
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.MAIN.MAIN_SMARTDESK_OPENED);
    }
  };

  handleHeaderMenuClick = (name: string, count: number) => {
    MainAnalytic.send(
      MainAnalytic.CATEGORY.MENU,
      MainAnalytic.ACTIONS.MENU.SELECTION,
      { label: name, value: count || 0 },
    );
  };

  handleOpenTravelChat = () => {
    const { chatService, productAnalyticsProtocol } = this.props;

    chatService.show();
    productAnalyticsProtocol.clickIntercom();

    MainAnalytic.send(MainAnalytic.CATEGORY.MENU, MainAnalytic.ACTIONS.MENU.SELECTION, {
      label: MainAnalytic.LABELS.MENU.TRAVELASSISTANT,
      value: 0,
    });
  };

  handleLogout = () => {
    this.props.onLogout();

    clearSentryContext();

    MainAnalytic.send(MainAnalytic.CATEGORY.MENU, MainAnalytic.ACTIONS.MENU.SELECTION, {
      label: MainAnalytic.LABELS.MENU.LOGOUT,
      value: 0,
    });
  };

  handleChangeAgentMode = (value: boolean) => {
    const { location: { pathname }, appService } = this.props;

    const preparedString = pathname.split('/');
    const mode = value ? ANALYTICS_AGENT_MODE.AGENT : ANALYTICS_AGENT_MODE.CLIENT;

    appService.changeAgentMode(value);

    if (preparedString.find(item => item === ANALYTICS_AGENT_MODE.AIRLINE)) {
      MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.AGENT_MODE.AVIA_SEARCH, { mode });
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.AGENT_MODE.AVIA_SEARCH, { mode });
    }

    if (preparedString.find(item => item === ANALYTICS_AGENT_MODE.HOTELS)) {
      MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.AGENT_MODE.HOTEL_SEARCH, { mode });
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.AGENT_MODE.HOTEL_SEARCH, { mode });
    }

    if (preparedString.find(item => item === ANALYTICS_AGENT_MODE.TRAIN)) {
      MainAnalytic.sendFirebase(MainAnalytic.ACTIONS.AGENT_MODE.TRAIN_SEARCH, { mode });
      MainAnalytic.sendAmplitude(MainAnalytic.ACTIONS.AGENT_MODE.TRAIN_SEARCH, { mode });
    }
  };

  render() {
    const {
      reportService,
      appService,
      workspaceService,
      bonusProgramService,
      smartdeskService,
      featureFlagsService,
      uiSettingsProtocol,
      productAnalyticsProtocol,
      airlineService,
      paymentService,
      stores: {
        uiSettingsStore: {
          settings: {
            showPaymentButton,
          },
        },
      },
      history,
    } = this.props;
    const {
      account,
      header,
      menuItems,
      cartMenuItem,
      approve: { Show, Count, CountTrips, CountRequests, CountExpenseReports },
      requestsCount,
      analyticsBadge,
      header: {
        noteItemsCount,
      },
      agentMode,
    } = this.state;
    const getCompaniesForReporting = workspaceService.getCompanyData();

    const disableS7Balance = featureFlagsService.getDisableS7Balance();

    const menu = menuItems.reduce<IMenuItems[]>((result, item) => {
      const newResult = [...result];

      switch (item.type) {
        case DEFAULTMENUTYPE.WAITINGAPPROVE: {
          if (Show) {
            if (item.items.length) {
              const items = item.items;

              if (item.items.length === 3) {
                items[0].count = CountTrips;
                items[1].count = CountRequests;
                items[2].count = CountExpenseReports;
              }

              if (item.items.length === 2) {
                items[0].count = CountTrips;
                items[1].count = CountRequests;
              }

              if (item.items.length === 1) {
                items[0].count = CountExpenseReports;
              }

              newResult.push({
                ...item,
                items,
                count: Count,
              });
            } else {
              newResult.push(item);
            }
          }

          break;
        }
        case DEFAULTMENUTYPE.TRIPS: {
          if (item.items.length > 1) {
            const items = item.items;
            items[1].count = requestsCount;

            newResult.push({
              ...item,
              showBadge: !!requestsCount,
              items,
            });
          } else {
            newResult.push(item);
          }

          break;
        }
        case DEFAULTMENUTYPE.CHARTS_ANALYTICS: {
          if (analyticsBadge) {
            newResult.push({
              ...item,
              showBadge: true,
            });
          } else {
            newResult.push(item);
          }

          break;
        }
        default: {
          newResult.push(item);
        }
      }

      return newResult;
    }, []);

    return (
      <div className={ styles.wrapper }>
        <MainHeader
          smartdeskService={ smartdeskService }
          featureFlagsService={ featureFlagsService }
          account={ account }
          header={ header }
          logoData={ uiSettingsProtocol.getLogo() }
          sendIntercomAfterLoadPage={ productAnalyticsProtocol.sendIntercomAfterLoadPage }
          reportService={ reportService }
          bonusProgramService={ bonusProgramService }
          appService={ appService }
          onOpenTravelChat={ this.handleOpenTravelChat }
          handleLogout={ this.handleLogout }
          onClick={ this.handleHeaderMenuClick }
          disableS7Balance={ disableS7Balance }
          isDemo={ workspaceService.isDemo }
          company={ getCompaniesForReporting }
          paymentService={ paymentService }
          showPaymentButton={ showPaymentButton }
          airlineService={ airlineService }
          history={ history }
          agentMode={ agentMode }
          onChangeAgentMode={ this.handleChangeAgentMode }
        />
        <MainMenu
          featureFlagsService={ featureFlagsService }
          menuItems={ menu }
          additionalMenuItems={ [cartMenuItem] }
          isTripsDropdown={ uiSettingsProtocol.isTripsDropdown }
          onClick={ this.handleMenuClick }
          noteItemsCount={ noteItemsCount }
        />
      </div>
    );
  }
}

export default AppHeader;
